import React, { memo, useEffect, useState } from 'react';
import MoreInsightsGraph from '../../../Components/moreInsightsGraph';

import FullCard, { ContentWrapper } from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import CardInfoHeader from '../../../Components/cardInfoHeader';
import api from '../../../Util/api';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import CardInfoHeader from '../../../Components/cardInfoHeader';

const MoreInsightsCard = ({ location, globalFilters, toolTipText, setLoading }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [moreInsights, setMoreinsights] = useState(null);
  const [responseType, setResponseType] = useState(false);
  const [sortByMoreInsights, setSortByMoreInsights] = useState('age');
  const [count, setCount] = useState(null);

  const onSelect = (filters) => {
    setSortByMoreInsights(filters.filter_type);
  };

  const responseMapping = (t) => ({
    age: t("employeesWithinTheAgeRangeOf"),
    gender: '',
  });

  const onSelectResponseChange = (id) => {
    setResponseType(id);
  };

  useEffect(() => {
    const getSurveyResponseData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        const response = await api.survey.getSurvey(id, sortByMoreInsights, responseType, location);
        const data = response?.data?.data?.moreInsights;
        setMoreinsights(data);
        setCount(response?.data?.data?.more_insights_counts);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prevState) => prevState - 1);
      }
    };
    getSurveyResponseData();
  }, [responseType, sortByMoreInsights, location]);
  return (
    <FullCard>
      <CardHeader
        name={t("demographicInsights")}
        options={{ showGender: true }}
        globalFilters={globalFilters}
        onSelect={onSelect}
        toolTipText={toolTipText}
        customWidth="400px"
        showResponseType
        onSelectResponseChange={onSelectResponseChange}
      />
      <ContentWrapper>
        <CardInfoHeader
          data={Object.keys(moreInsights || []).map((key) => key)}
          count={count || {}}
          responses={moreInsights ? moreInsights : null}
          responseMessage={responseMapping(t)[sortByMoreInsights]}
        />
        <MoreInsightsGraph
          data={
            moreInsights
              ? Object.fromEntries(Object.entries(moreInsights).filter(([_, v]) => Object.keys(v)?.length))
              : null
          }
        />
      </ContentWrapper>
    </FullCard>
  );
};

export default memo(MoreInsightsCard);
