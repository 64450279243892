import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { AuthLayout } from '../../Layout';
import { useDispatch } from 'react-redux';
import { verifyEmail } from '../../Actions/auth.actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Verify = () => {
  const [verificationResponse, setVerificationResponse] = useState('');
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const query = new URLSearchParams(search);
  const verifyToken = query.get('id');

  useEffect(() => {
    if (verifyToken) {
      dispatch(verifyEmail(verifyToken)).then((res) => setVerificationResponse(res.data.message));
    }
  }, [verifyToken, history, dispatch]);

  return (
    <AuthLayout heading={t("verifyEmail")}>
      <h4 style={{ marginBottom: 20 }}>{verificationResponse}</h4>
      <Link to="/login">{t("returnToLogin")}</Link>
    </AuthLayout>
  );
};

export default Verify;
