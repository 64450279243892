import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const { Option } = Select;

const HeartbeatFilters = ({ onSelectResponseChange }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledSelect
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultValue={false}
        bordered={false}
        onChange={onSelectResponseChange}
        style={{ marginRight: 10 }}
      >
        <Option value="all">{t("allResponse")}</Option>
        <Option value={true}>{t("positiveResponse")}</Option>
        <Option value={false}>{t("negativeResponse")}</Option>
      </StyledSelect>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;

  .ant-select-borderless {
    border: none !important;
  }

  .ant-select-selector {
    width: 130px !important;
    height: 38px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    border: 1px solid #cdcdcd !important;
    color: #575d7a;
  }

  .ant-select-selection-item {
    padding-right: 24px !important;
  }
  .ant-select-arrow {
    margin-top: -18px;
  }
`;

export default HeartbeatFilters;
