import React from 'react';
import { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import CustomTooltip from '../../../Components/customTooltip';
import { CheckboxText } from '../CreateSurveyPage/createSurveyPage';

const ResponseAnswers = (props) => {
  const [currIndex, setCurrIndex] = useState(0);

  const handleNext = () => {
    setCurrIndex(currIndex + 8);
  };

  const handlePrev = () => {
    setCurrIndex(currIndex - 8);
  };

  return (
    <>
      <AnswersWrapper>
        <div>
          <NewTitle>Responses:</NewTitle>
          <Answers>
            {props.question.answers.slice(currIndex, currIndex + 8)?.map((qu) => (
              <AnswerCover key={qu.id}>
                <BulletPoint />

                <CustomTooltip title={qu.answer}>
                  <CheckboxText style={{ color: '#1a5549', wordBreak: 'break-word' }}>{qu.answer}</CheckboxText>
                </CustomTooltip>
              </AnswerCover>
            ))}
          </Answers>
        </div>

        {props.question?.answers?.length > 8 && (
          <ButtonWrapper>
            <CustomButton type="primary" disabled={currIndex === 0} onClick={handlePrev}>
              Prev
            </CustomButton>
            <CustomButton
              type="primary"
              disabled={currIndex + 8 >= props.question?.answers?.length}
              onClick={handleNext}
            >
              Next
            </CustomButton>
          </ButtonWrapper>
        )}
      </AnswersWrapper>
      {/* )})} */}
    </>
  );
};

export default ResponseAnswers;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const AnswersWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px solid #c0becc;
  .ant-col {
    white-space: nowrap;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NewTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #1a5549;
  margin-bottom: 10px;

  @media screen and (max-width: 879px) {
    text-align: center;
  }
`;

const BulletPoint = styled.span`
  margin-right: 10px;
  height: 5px;
  width: 5px;
  background: #1a5549;
  border-radius: 8px;
`;

const Answers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;
`;

const AnswerCover = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
`;

const CustomButton = styled(Button)`
  color: white;
  border: 1px solid #1a5549;
  border-radius: 20px;
  background-color: #1a5549;
  font-weight: 400;

  &:hover {
    color: white;
    border: 1px solid #1a5549;
    background-color: #1a5549;
  }
`;
