import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import { Popconfirm, Button, message, Upload, Select } from 'antd';
import { UserAddOutlined, UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Input as AntInput } from 'antd';

import Input from '../../Components/input';
import CustomTooltip from '../../Components/customTooltip';
import PhoneInput from 'react-phone-input-2';
import { StyledErrorMessage } from './profileSettings';
import SampleFile from '../../Assets/employee_upload_format.csv';
import UploadUsersConfirmation from './UploadUsersConfirmation';
import { StyledTable, TableColumn } from '../../SharedStyles';
import { getInvitedEmployeeList, resendinvite, revokeinvitee } from '../../Actions/company.actions';
import Uploader from './Uploader';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
const { Search } = AntInput;

const userSchema = (t) =>
  yup.object().shape({
    first_name: yup.string().min(2, t('tooShort')).max(30, t('tooLong')).required(t('firstNameIsRequired')),
    last_name: yup.string().min(2, t('tooShort')).max(30, t('tooLong')).required(t('lastNameIsRequired')),
    email: yup.string().email().required(),
    mobile: yup.string(),
    department: yup.string().required(),
  });

const initialUser = {
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
  department: null,
};

const EmployeeView = ({ isManager, getUserList, createSingleUser, createBulkUserEndpoint, dataKey, columns }) => {
  let uploadButtonRef = useRef(null);
  const { t } = useTranslation();
  const userList = useSelector((store) => store.company[dataKey]);
  const inviteList = useSelector((store) => store.company?.employeeInvitedList);
  const userDetails = useSelector((store) => store.company?.userSettings?.userDetails);
  const heartbeat = useSelector(
    (store) => store.company?.profile?.only_heartbeat && !store.company?.profile?.is_analytics,
  );
  const selectorOptions = useSelector((store) => store.company.departments);

  const [{ first_name, last_name, email, mobile, department, lang }, setNewUser] = useState(initialUser);
  const [errors, setErrors] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [isDepDropdownTouched, setisDepDropdownTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadCounter, setUploadCounter] = useState(0);
  const [selectResendInviteLanguage, setSelectResendInviteLanguage] = useState('english');

  const [usertableData, setUserTableData] = useState([]);
  const [invitetableData, setInviteTableData] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const profileData = useSelector((store) => store.company?.profile);
  const [data, setData] = useState('english');

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const token = params.get('SUPER_USER');

  const selectedLang = (item) => {
    setData(item);
  };

  const createUser = () => {
    setIsLoading(true);
    userSchema(t)
      .validate({
        first_name,
        last_name,
        email,
        department,
      })
      .then(() => {
        dispatch(
          createSingleUser({
            first_name,
            last_name,
            email,
            mobile: mobile ? mobile : undefined,
            heartbeat: heartbeat ? true : undefined,
            department_name: department,
            language: lang,
            source_company: profileData?.source_company,
          }),
        )
          .then((res) => {
            dispatch(getInvitedEmployeeList());
            setNewUser(initialUser);
            setisDepDropdownTouched(false);
            setErrors(null);
            togglePopConfirm();
            if (res?.data?.status === 'success') {
              message.success(res.data?.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            setErrors(Object.values(err.response?.data?.errors || {}));
            message.error(err.response?.data?.message);
            setIsLoading(false);
          });
      });
  };

  const onInviteSearch = (value) => {
    if (!value) {
      return setInviteTableData(inviteList?.filter(({ revoke_status }) => !revoke_status));
    }

    const filteredData = inviteList.filter((elem) => {
      return (
        elem?.user?.name?.toLowerCase().includes(value.toLowerCase()) ||
        (elem.email?.toLowerCase().includes(value.toLowerCase()) && !elem.revoke_status)
      );
    });
    setInviteTableData(filteredData);
  };

  const onEmployeeSearch = (value) => {
    if (!value) {
      return setUserTableData(userList);
    }
    const filteredData = userList.filter(
      (elem) =>
        ((elem.first_name?.toLowerCase() || '') + ' ' + (elem.last_name?.toLowerCase() || '')).includes(
          value?.toLowerCase(),
        ) || elem.email?.toLowerCase().includes(value.toLowerCase()),
    );
    setUserTableData(filteredData);
  };

  const inputOnChange = (e, setFieldValue) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    setFieldValue(targetName, targetValue);
    setNewUser((state) => ({ ...state, [targetName]: targetValue }));
  };

  const mobileOnChange = (mobile, setFieldValue) => {
    setFieldValue('mobile', mobile);
    setNewUser((state) => ({ ...state, mobile }));
  };

  const languageOnChange = (lang, setFieldValue) => {
    setFieldValue('lang', lang);
    setNewUser((state) => ({ ...state, lang }));
  };
  const selectLanguage = (lang) => {
    setSelectResendInviteLanguage(lang);
  };

  const togglePopConfirm = () => setPopConfirmVisible((state) => !state);
  const resendInvitee = (id, selectResendInviteLanguage) =>
    dispatch(resendinvite(id, selectResendInviteLanguage)).then(() => message.success(t('invitationSentSuccessfully')));
  const revokeInvitee = (email) =>
    dispatch(revokeinvitee(email)).then(() => message.success(t('invitationRevokedSuccessfully')));
  const inviteColumns = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t('name'),
      dataIndex: 'first_name',
      key: 'first_name',
      width: '20%',
      sorter: (a, b) => (a?.user?.name || '').localeCompare(b?.user?.name || ''),

      render: (_, data) => (data?.user?.name ? data?.user?.name : '-'),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: t('invitations'),
      dataIndex: 'invited_count',
      key: 'invited_count',
      width: '10%',
      sorter: (a, b) => a.invited_count - b.invited_count,

      render: (_, data) => <span style={{ textAlign: 'center', display: 'block' }}>{data?.invited_count || '-'}</span>,
    },
    // {
    //   title: 'Accepted',
    //   dataIndex: 'Invitation Accepted',
    //   key: 'Invitation Accepted',
    //   width: '10%',
    //   render: (_, data) => (data?.user?.name ? 'Yes' : 'No'),
    // },
    {
      title: t('resendInvitation'),
      dataIndex: 'department',
      key: 'department',
      width: '14%',
      render: (_, data) => {
        return (
          <>
            {data?.user?.name ? (
              <div>-</div>
            ) : (
              <Popconfirm
                placement="bottom"
                title={
                  <div style={{ width: 'auto' }}>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span>{t('areYouSureToResendInvite')}</span>
                      <div style={{ margin: '5px 0px' }}>{t('pleaseSelectLanguage')}</div>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentElement}
                        bordered={true}
                        defaultValue="english"
                        onChange={(e) => selectLanguage(e)}
                        placeholder={t('pleaseSelectLanguage')}
                        options={[
                          {
                            label: t('English'),
                            key: '1',
                            value: 'english',
                          },
                          {
                            label: t('Serbian'),
                            key: '2',
                            value: 'serbian',
                          },
                          {
                            label: t('Greek'),
                            key: '3',
                            value: 'greek',
                          },
                          {
                            label: t('Arabic'),
                            key: '4',
                            value: 'arabic',
                          },
                        ]}
                      ></Select>
                    </span>
                  </div>
                }
                onConfirm={() => resendInvitee(data.id, selectResendInviteLanguage)}
              >
                <TableColumn style={{ color: '#2874f0', textAlign: 'center' }}> {t('resendInvite')}</TableColumn>
              </Popconfirm>
            )}
          </>
        );
      },
    },
    {
      title: t('revokeAccess'),
      dataIndex: 'department',
      key: 'department',
      width: '14%',
      render: (_, data) => {
        return (
          <>
            <Popconfirm
              placement="bottom"
              title={
                <div style={{ width: 210 }}>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('sureToRevokeAccess')}
                  </span>
                  {/* <span style={{ fontWeight: 600 }}>
                  {data.first_name} {data.last_name}
                </span> */}
                </div>
              }
              onConfirm={() => revokeInvitee(data.email)}
            >
              <TableColumn style={{ color: '#2874f0', textAlign: 'center' }}> {t('revokeAccess')}</TableColumn>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setUserTableData(userList);
  }, [userList]);

  useEffect(() => {
    setInviteTableData(inviteList?.filter(({ revoke_status }) => !revoke_status));
  }, [inviteList]);

  useEffect(() => {
    dispatch(getUserList());
    dispatch(getInvitedEmployeeList());
  }, [dispatch, getUserList]);

  useEffect(() => {
    if (userDetails) {
      if (!userDetails.company_master_account) history.goBack();
    }
  }, [history, userDetails]);

  const uploadUsers = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    if (file?.name?.split('.')?.at(-1) !== 'csv') {
      message.error(t('wrongFileUploaded'));
      setUploadCounter((state) => state + 1);
      return;
    }
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('heartbeat', heartbeat ? true : false);
    formData.append('language', data);
    formData.append('source_company', profileData?.source_company);

    try {
      const res = await axios.post(createBulkUserEndpoint, formData, config);
      dispatch(getInvitedEmployeeList());

      if (res) message.success(t('usersAddedToList'));
      setUploadCounter((state) => state + 1);
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
      setUploadCounter((state) => state + 1);

      message.error(err.response?.data?.message);
    }
  };

  return (
    <div>
      {isManager && <p style={{ fontSize: 15, fontWeight: 600 }}>{t('uploadManagersToAnalyticsPlatform')}</p>}

      <Popconfirm
        placement="bottomLeft"
        title={
          <div>
            <CreateHeaderText>{`${t('create')}  ${isManager ? t('manager') : t('Employee')}  ${t(
              'form',
            )}`}</CreateHeaderText>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
              }}
              validationSchema={userSchema(t)}
              onSubmit={null}
            >
              {({ touched, errors, setFieldValue }) => (
                <Form>
                  <UserFormWrapper>
                    <Field id="first_name" name="first_name">
                      {({ field }) => (
                        <Input
                          id="first_name"
                          name="first_name"
                          placeholder={t('firstNameImp')}
                          {...field}
                          value={first_name}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.first_name && touched.first_name && (
                      <StyledErrorMessage name="first_name" component="div" />
                    )}
                    <Field id="last_name" name="last_name">
                      {({ field }) => (
                        <Input
                          id="last_name"
                          name="last_name"
                          placeholder={t('lastNameImp')}
                          {...field}
                          value={last_name}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.last_name && touched.last_name && <StyledErrorMessage name="last_name" component="div" />}
                    <Field id="email" name="email">
                      {({ field }) => (
                        <Input
                          id="email"
                          name="email"
                          placeholder={t('emailImp')}
                          {...field}
                          value={email}
                          onChange={(e) => inputOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.email && touched.email && <StyledErrorMessage name="email" component="div" />}
                    <Field id="department" name="department">
                      {({ field }) => {
                        return (
                          <StyledSelect
                            getPopupContainer={(trigger) => trigger.parentElement}
                            bordered={false}
                            id="department"
                            name="department"
                            placeholder={t('selectDepartmentImp')}
                            {...field}
                            onBlur={() => setisDepDropdownTouched(true)}
                            value={department}
                            onChange={(_, e) =>
                              inputOnChange({ target: { name: 'department', value: e.children } }, setFieldValue)
                            }
                          >
                            {selectorOptions?.map((option) => (
                              <Option key={option.id} value={option.id}>
                                {option.name}
                              </Option>
                            ))}
                          </StyledSelect>
                        );
                      }}
                    </Field>
                    {isDepDropdownTouched && !department && (
                      <span style={{ color: 'red' }}>{t('departmentIsRequired')}</span>
                    )}
                    <PhoneInput
                      enableSearch
                      placeholder={t('selectCountryAndNumber')}
                      autoFormat
                      value={mobile}
                      onChange={(e) => mobileOnChange(e, setFieldValue)}
                    />
                    <StyledSelect
                      getPopupContainer={(trigger) => trigger.parentElement}
                      bordered={true}
                      style={{ marginTop: '6px' }}
                      defaultValue="english"
                      onChange={(e) => languageOnChange(e, setFieldValue)}
                      placeholder="Please select language"
                      options={[
                        {
                          label: t('English'),
                          key: '1',
                          value: 'english',
                        },
                        {
                          label: t('Serbian'),
                          key: '2',
                          value: 'serbian',
                        },
                        {
                          label: t('Greek'),
                          key: '3',
                          value: 'greek',
                        },
                        {
                          label: t('Arabic'),
                          key: '4',
                          value: 'arabic',
                        },
                      ]}
                    ></StyledSelect>
                  </UserFormWrapper>
                </Form>
              )}
            </Formik>
            {!!errors?.length &&
              errors.map((err) => (
                <div style={{ color: 'red' }} key={err}>
                  {err}
                </div>
              ))}
          </div>
        }
        onConfirm={createUser}
        onCancel={togglePopConfirm}
        okText={t('create')}
        cancelText={t('cancel')}
        icon={<UserAddOutlined style={{ color: '#00c8a0' }} />}
        visible={popConfirmVisible}
        okButtonProps={{
          disabled: !first_name || !last_name || !email || !department || isLoading,
          loading: isLoading,
        }}
      >
        <CustomTooltip placement="top" title={<span>{t('createUser')}</span>}>
          <Button
            style={{ display: 'block', marginBottom: 10 }}
            type="primary"
            onClick={togglePopConfirm}

            // disabled={!first_name || !last_name || !email || !department}
          >
            {t('create')} {isManager ? t('manager') : t('Employee')}
          </Button>
        </CustomTooltip>
      </Popconfirm>
      <ActionContainer>
        <Button onClick={() => setConfirm(true)} icon={<UploadOutlined />}>
          {t('upload')} {isManager ? t('managers') : t('Employees')}
        </Button>

        <UploadUsersConfirmation
          isManager={isManager}
          confirm={confirm}
          onCancel={() => setConfirm(false)}
          onConfirm={() => {
            setConfirm(false);
          }}
          uploadButtonRef={uploadButtonRef}
          selectedLang={selectedLang}
        />
        <SampleCsv href={SampleFile} download="employee_upload_sample.csv">
          {t('downloadSampleCSV')}
        </SampleCsv>
      </ActionContainer>
      <Uploader uploadButtonRef={uploadButtonRef} uploadUsers={uploadUsers} key={uploadCounter} />

      {token || profileData.id === 8785 ? (
        <>
          <p style={{ fontSize: 15, fontWeight: 500, marginTop: 15 }}>
            {t('thefollowing')} {isManager ? t('managers') : t('employees')} {t('activeInYourNetwork')}
          </p>

          {profileData.id !== 8785 && (
            <div style={{ marginTop: 20 }}>
              <SearchContainer>
                <p style={{ fontSize: 15, fontWeight: 500 }}>{t('invitedEmployeesLeaders')}</p>
                <CustomTooltip title={t('searchByNameEmail')}>
                  <Search
                    placeholder={t('searchByNameEmail')}
                    allowClear
                    onSearch={onInviteSearch}
                    style={{ width: 200 }}
                  />
                </CustomTooltip>
              </SearchContainer>
              <StyledTable dataSource={invitetableData || []} columns={inviteColumns} scroll={{ y: 622 }} />
            </div>
          )}
          <div style={{ marginTop: 40 }}>
            <SearchContainer>
              <p style={{ fontSize: 15, fontWeight: 500 }}>{t('registeredEmployeesOnThePlatform')}</p>
              <CustomTooltip title={t('searchByNameEmail')}>
                <Search
                  placeholder={t('searchByNameEmail')}
                  allowClear
                  onSearch={onEmployeeSearch}
                  style={{ width: 200 }}
                />
              </CustomTooltip>
            </SearchContainer>
            <StyledTable dataSource={usertableData || []} columns={columns} scroll={{ y: 622 }} />
          </div>
        </>
      ) : (
        <p>No Access</p>
      )}
    </div>
  );
};

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  // font-size: 11px;
  // display: inline-block;
  margin-bottom: 6px;
  border: 1px solid #c4c4c4 !important;
  width: 100%;
  .ant-select-borderless {
    // border: 1px solid #c4c4c4 !important;
    // width: 100%;
  }

  // .ant-select-selector {
  //   padding: 0 !important;
  //   border: 1px solid #cdcdcd !important;
  //   color: #575d7a;
  // }

  // .ant-select-selection-item {
  //   padding-right: 24px !important;
  // }
`;

const CreateHeaderText = styled.div`
  font-size: 16px;
  color: #00c8a0;
  font-weight: 600;
`;

const SampleCsv = styled.a`
  color: #00c8a0;
  border: none;
  background: none;
  margin-left: 100px;
`;

const ActionContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  margin-top: 30px;
  align-items: center;
`;

const UserFormWrapper = styled.div`
  width: 250px;
  margin-top: 10px;
  input {
    margin-bottom: 5px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .react-tel-input {
    width: 100%;
    .search {
      display: flex;
      width: 90%;
      input {
        color: black;
        &::placeholder {
          color: #bfbfbf;
        }
      }
    }
    .form-control {
      width: 100%;
      height: 40px;
      border-radius: 2px !important;
      color: black;
      &::placeholder {
        color: #bfbfbf;
      }
    }
  }
`;

export default EmployeeView;
