import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { isEqual } from 'lodash';

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { useSelector } from 'react-redux';

const COLORS = ['#8A73D8', '#94DAFB', '#68D3CD', '#FF8042', '#CFF67C', '#F5BC41', '#F2AFB0', '#9D9D9D'];

const isEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const CustomTooltip = memo(({ payload, label }) => {
  const info = payload?.length && payload[0]?.payload;
  return (
    <TooltipWrapper>
      <div>{label}</div>
      <div style={{ background: payload?.length && payload[0]?.fill ? payload[0]?.fill : '' }}>
        Count: <span style={{ fontWeight: 'bold' }}>{info?.value ?? ''}</span>
      </div>
    </TooltipWrapper>
  );
}, isEqual);

const EmployeeCountChart = ({ data = [] }) => {
  const isMobileView = useSelector((store) => store.app.isMobileView);

  const parsedData = useMemo(() => {
    if (!data || data?.length === 0) return [];
    return Object.keys(data).map((key) => ({ department_name: key, value: data[key] }));
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={isMobileView ? '73%' : '85%'}>
      <BarChart
        data={parsedData}
        margin={{
          top: 20,
          right: 10,
          left: -20,
          bottom: 5,
        }}
      >
        <XAxis stroke="#9D9D9D" dataKey="department_name" tickLine={false} padding={{ left: 20, right: 20 }} />
        <YAxis axisLine={false} tickLine={false} type="number" allowDecimals={false} domain={[0, 'dataMax']} />
        <Tooltip
          formatter={(value) => [value, 'value']}
          cursor={{ fill: 'transparent' }}
          content={({ payload, label }) => <CustomTooltip payload={payload} label={label} />}
        />
        <Bar dataKey="value" maxBarSize={10}>
          {parsedData.map((key, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const TooltipWrapper = styled.div`
  width: 100px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  background: white;
  overflow: hidden;

  div {
    padding: 4px;
    color: #585858;

    &:first-child {
      height: 50%;
      background: #f1f1f1;
      display: flex;
      align-content: center;
    }

    &:nth-child(1) {
      height: 50%;
      display: flex;
      align-content: center;
    }
  }
`;

EmployeeCountChart.propTypes = {
  data: PropTypes.shape({}),
};

export default memo(EmployeeCountChart);
