import React, { memo } from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

// import Dashboard from '../Containers/Dashboard/Dashboard';
import NewDashboard from '../Containers/NewDashboard';
import Analytics from '../Containers/Analitycs';
import Survey from '../Containers/Survey';
import Questionnaire from '../Containers/Survey/questionnaire';
import SurveyInsights from '../Containers/Survey/InsightsPage/insightsPage';
import Settings from '../Containers/Settings';
import Contract from '../Containers/Contract/Contract';
import HelpSupport from '../Containers/HelpSupport/helpSupport';

import Login from '../Containers/Login';
import Logout from '../Containers/Logout';

import { CompanyLayout } from '../Layout';
import SuveyPreviewDashboard from '../Containers/Survey/SurveyPreview/SuveyPreviewDashboard';
import EmployeeBenefits from '../Containers/EmployeeBenefits/EmployeeBenefits';
import FreeTextAnalytics from '../Containers/Survey/InsightsPage/freetextAnalytics';
import AdditionalSupport from '../Containers/AdditionalSupport/AdditionalSupport';
import LeadersHub from '../Containers/LeadersHub/LeadersHub';

const Routes = () => {
  return (
    <CompanyLayout>
      <Switch>
        {/*<Route exact path="/dashboard" component={Dashboard} />*/}
        <Route exact path="/leadersHub" component={LeadersHub} />
        <Route exact path="/dashboard" component={NewDashboard} />
        <Route exact path="/analytics/:id" component={Analytics} />
        <Route exact path="/questionnaire/:id" component={Questionnaire} />
        <Route exact path="/survey/:id" component={Survey} />
        <Route exact path="/survey/insights/:id" component={SurveyInsights} />
        <Route exact path="/preview/:id" component={SuveyPreviewDashboard} />
        <Route exact path="/employee-benefits" component={EmployeeBenefits} />
        <Route exact path="/settings/:id" component={Settings} />
        <Route exact path="/contract" component={Contract} />
        <Route exact path="/help-and-support" component={HelpSupport} />
        <Route exact path="/logout" component={Logout} isPublic />
        <Route exact path="/survey/insights/freetext-analytics/:id/:country" component={FreeTextAnalytics} />
        <Route exact path="/additional-support" component={AdditionalSupport} />

        <Route component={Login} />
      </Switch>
    </CompanyLayout>
  );
};

export default memo(Routes);
