import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

const StyledInput = (props) => <InputStyle {...props} />;

const InputStyle = styled(Input)`
  border: 1px solid #c4c4c4;
  height: 40px;

  input {
    height: 30px;
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }

  &:focus {
    box-shadow: 0 0 0 2px rgb(0 200 160 / 20%);
  }

  &:-webkit-autofill {
    background-color: red !important;
  }
`;

export default StyledInput;
