import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';

import {
  getDepartments,
  getLocations,
  getInvitationDetails,
  getProfile,
  getUserDetails,
} from '../Actions/company.actions';
import { setMobileViewState, setTabletViewState } from '../Actions/app.actions';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../Hooks';

import Sidebar from '../Components/sidebar';
import HeaderBar from '../Components/headerBar';

import GlobalStyle from '../globalStyles';
import { Switch } from 'react-router-dom';
import Route from '../Routes/Route';
import Login from '../Containers/Login';
import Logout from '../Containers/Logout';
import ForgottenPassword from '../Containers/ForgottenPassword';
import Verify from '../Containers/Verify';
import ResetPassword from '../Containers/ResetPassword';
import LoginAdmin from '../Containers/LoginAdmin';
import ScrollToTop from '../Hooks/scrollToTop';
import SurveyQuestionnaire from '../Containers/Survey/questionnaire';

const CompanyLayout = (props) => {
  const { t } = useTranslation();
  const viewportWidth = useWindowSize().width;
  const isMobileView = useMemo(() => viewportWidth < 450, [viewportWidth]);
  const isTabletView = useMemo(() => viewportWidth < 750 && viewportWidth >= 450, [viewportWidth]);
  const isSideBarOpened = useSelector((store) => store.app.isSideBarOpened);
  const profile = useSelector((store) => store.company.profile);
  const { pathname } = useLocation();

  const company = useSelector((store) => store.company);
  const auth = useSelector((store) => store.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.loggedIn && profile?.company_unique_id) {
      dispatch(getLocations(profile?.company_unique_id));
    }
  }, [profile?.company_unique_id]);

  useEffect(() => {
    if (auth.loggedIn) {
      dispatch(getProfile());
      dispatch(getInvitationDetails());
      dispatch(getUserDetails());
      dispatch(getDepartments());
    }
  }, [auth, dispatch]);

  useEffect(() => {
    dispatch(setMobileViewState(isMobileView));
    dispatch(setTabletViewState(isTabletView));
  }, [isMobileView, isTabletView, dispatch]);

  if (!company.profile && auth.loggedIn)
    return (
      <SpinWrapper>
        <Spin size="large" />
      </SpinWrapper>
    );

  if (pathname.includes('/survey/submit')) {
    return (
      <Switch>
        <Route exact path="/survey/submit/:id/:user" component={SurveyQuestionnaire} isPublic />
      </Switch>
    );
  }

  if (!auth.loggedIn)
    return (
      <Switch>
        <Route exact path="/login" component={Login} isPublic />
        <Route exact path="/logout" component={Logout} isPublic />
        <Route exact path="/forgotten-password" component={ForgottenPassword} isPublic />
        <Route exact path="/reset-password" component={ResetPassword} isPublic />
        <Route exact path="/verify-email" component={Verify} isPublic />
        <Route exact path="/login-admin" component={LoginAdmin} isPublic />
        <Route component={Login} />
      </Switch>
    );

  return (
    <MainWrapper>
      <GlobalStyle />
      {!props.hideSidebar && viewportWidth > 1170 && <Sidebar />}
      <ContentWrapper
        isDesktop={viewportWidth > 1170}
        isSideBarOpened={isSideBarOpened}
        className={props.hideSidebar ? 'no-sidebar' : null}
      >
        <HeaderBar viewportWidth={viewportWidth} />
        <ScrollToTop>
          <Content id="ref-scroll" viewportWidth={viewportWidth} isMobileView={isMobileView}>
            {props.children}
            {isMobileView && (
              <Footer>
                <div>
                  {t('moreDetailsVisit')}
                  <span style={{ color: '#00C8A0', fontWeight: 700 }}>{t('desktopSite')}</span>
                </div>
                <div style={{ fontWeight: 700, opacity: 0.5, marginTop: 10 }}>{t('copyrightSupportRoom')}</div>
              </Footer>
            )}
          </Content>
        </ScrollToTop>
      </ContentWrapper>
    </MainWrapper>
  );
};

const SpinWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const ContentWrapper = styled.section`
  width: calc(100% - ${({ isSideBarOpened, isDesktop }) => (isDesktop ? (isSideBarOpened ? '264px' : '50px') : '0px')});
`;

const Content = styled.div`
  position: relative;
  height: calc(100vh - 80px);
  padding: ${({ theme, viewportWidth }) => (viewportWidth > 1170 ? theme.spacingLg : '25px')};
  overflow: scroll;
  background-color: #f4f7fc;

  ${({ isMobileView }) =>
    isMobileView &&
    css`
      padding: 25px 25px 0 25px;
    `}
`;

const Footer = styled.div`
  background: #1a5549;
  padding: 40px;
  text-align: center;

  position: relative;
  left: -25px;
  width: calc(100% + 50px);

  div {
    color: white;
    font-size: 16px;
  }
`;

export default CompanyLayout;
