import React, { memo } from 'react';
import MoreInsightsGraph from '../../../Components/moreInsightsGraph';

import FullCard, { ContentWrapper } from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import { useSelector } from 'react-redux';
import CardInfoHeader from '../../../Components/cardInfoHeader';
import { useTranslation } from 'react-i18next';

const MoreInsightsCard = ({ globalFilters, setSortByMoreInsights, sortByMoreInsights }) => {
  const data = useSelector((store) => store.analytics.companyMood?.data?.more_insights);
  const count = useSelector((store) => store.analytics?.companyMood?.data?.more_insights_counts);
  const { t } = useTranslation();
  const responseMapping = {
    age: 'employees within the age range of',
    gender: '',
  };

  const onSelect = (filters) => {
    setSortByMoreInsights(filters.filter_type);
  };
  return (
    <FullCard>
      <CardHeader
        name={t("demographicInsights")}
        options={{ showGender: true }}
        globalFilters={globalFilters}
        onSelect={onSelect}
        customWidth="400px"
        toolTipText={t("insightsMoodTooltip")}
      />
      <ContentWrapper>
        <CardInfoHeader
          data={Object.keys(data || []).map((key) => key)}
          count={count || {}}
          responses={data ? data : null}
          responseMessage={responseMapping[sortByMoreInsights]}
        />
        <MoreInsightsGraph
          data={data ? Object.fromEntries(Object.entries(data).filter(([_, v]) => Object.keys(v)?.length)) : null}
        />
      </ContentWrapper>
    </FullCard>
  );
};

export default memo(MoreInsightsCard);
