import { Select } from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;

  .ant-select-borderless {
    border: none !important;
  }

  .ant-select-selector {
    width: 130px !important;
    height: 38px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    border: 1px solid #cdcdcd !important;
    color: #575d7a;
  }

  .ant-select-selection-item {
    // padding-right: 24px !important;
  }
`;
