import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Select, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import CustomTooltip from './customTooltip';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const { Option } = Select;

const CardHeader = ({
  name,
  noFilters = false,
  noCompare = true,
  customWidth = '',
  options = { showPeriod: false, showDepartments: false, showGender: false },
  globalFilters,
  onSelect,
  toolTipText = '',
  showToolTip = true,
  showResponseType,
  onSelectResponseChange,
  surveyName,
  isCustomQuestions,
  surveyId,
  location,
}) => {
  const [filters, setFilters] = useState({
    filter_type: 'age',
    compare_previous: 0,
  });

  const history = useHistory();
  const { t } = useTranslation();
  const profileData = useSelector((store) => store.company?.profile);
  const [demographicGender, setDemographicGender] = useState(true);

  useEffect(() => {
    if (profileData?.demographics) {
      setDemographicGender(true);
    } else {
      setDemographicGender(false);
    }
  }, [profileData]);

  const onCompareChange = (checked) => onSelectAction(checked ? 1 : 0, 'compare_previous');
  const onTypeChange = (type) => onSelectAction(type, 'filter_type');
  const onSelectAction = (data, type) => {
    const newFilters = { ...filters, [type]: data };
    setFilters(newFilters);
    onSelect(newFilters);
  };

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, ...globalFilters }));
  }, [globalFilters]);

  return (
    <HeaderWrapper>
      <span>
        <CardName>{name}</CardName>
        {showToolTip ? (
          <CustomTooltip title={toolTipText} customWidth={customWidth}>
            <InfoCircleOutlined
              style={{
                color: '#7d7a7a',
              }}
            />
          </CustomTooltip>
        ) : null}
      </span>
      <div style={{ display: 'flex' }}>
        {showResponseType && (
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            defaultValue={false}
            bordered={false}
            onChange={onSelectResponseChange}
            style={{ marginRight: 10 }}
          >
            <Option value="all">{t('allResponse')}</Option>
            <Option value={true}>{t('positiveResponse')}</Option>
            <Option value={false}>{t('negativeResponse')}</Option>
          </StyledSelect>
        )}
        {!noFilters &&
          (demographicGender ? (
            <SelectorsWrapper>
              {options.showGender && (
                <StyledSelect
                  getPopupContainer={(trigger) => trigger.parentElement}
                  defaultValue="age"
                  bordered={false}
                  onChange={onTypeChange}
                  value={filters.filter_type}
                >
                  <Option value="age">{t('groupByAge')}</Option>
                  <Option value="gender">{t('groupByGender')}</Option>
                </StyledSelect>
              )}
            </SelectorsWrapper>
          ) : null)}

        {!noCompare && (
          <SelectorsWrapper
            style={{
              marginLeft: 20,
            }}
          >
            <CompareText>{t('compareWithPrevious')}</CompareText>
            <CompareSwitch checked={!!filters.compare_previous} onChange={onCompareChange} />
          </SelectorsWrapper>
        )}
        {isCustomQuestions && (
          <Button
            onClick={() => {
              history.push({
                pathname: `/survey/insights/freetext-analytics/${surveyId}/${location}`,
                state: surveyName,
              });
            }}
            type="primary"
            htmlType="submit"
          >
            {t('freeTextAnalytics')}
          </Button>
        )}
      </div>
    </HeaderWrapper>
  );
};

const CompareSwitch = styled(Switch)`
  min-width: 40px;
  &.ant-switch-checked {
    background: ${({ theme }) => theme.primary};
  }
`;

const CompareText = styled.span`
  color: #5a607f;
  font-size: 13px;
  white-space: nowrap;
  margin-right: 12px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 27px;
  margin-bottom: 30px;
`;
const CardName = styled.div`
  color: ${({ theme }) => theme.greyDark};
  font-size: 18px;
  font-weight: bold;
  display: flex;
  display: inline;
  margin-right: 5px;
`;

const StyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  .ant-select-borderless {
    border: none !important;
  }

  .ant-select-selector {
    width: 150px !important;
    height: 30px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    color: #575d7a;
  }
  .ant-select-selection-item {
    padding-right: 24px !important;
  }
`;

const SelectorsWrapper = styled.div`
  width: 100%;
  max-width: 186px;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export default CardHeader;
