import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import ReactWordCloud from 'react-wordcloud';
import { Empty } from 'antd';

import MoreInsights from './moreInsightsButton';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { MAIN_COLORS } from '../Styles/colorPallet';

const WordCloudChart = ({ data, hideMoreInsightsLink = false, fontSizes = [10, 60] }) => {
  const history = useHistory();
  const goTo = () => history.push('/analytics/text-analytics');

  const callbacks = {
    getWordTooltip: ({ text, value }) => {
      return `${value} mentions`;
    },
  };

  return (
    <WordCloudChartWrapper>
      {!data || data.length === 0 ? (
        <EmptyWrapper>
          <Empty />
        </EmptyWrapper>
      ) : (
        <ReactWordCloud
          callbacks={callbacks}
          options={{
            fontFamily: 'Poppins',
            enableTooltip: true,
            deterministic: false,
            fontSizes: fontSizes,
            fontStyle: 'normal',
            fontWeight: '600',
            padding: 1,
            rotations: 2,
            rotationAngles: [0, 90],
            scale: 'sqrt',
            spiral: 'archimedean',
            colors: MAIN_COLORS,
          }}
          words={data}
        />
      )}
      {!hideMoreInsightsLink && <MoreInsights onClick={goTo} />}
    </WordCloudChartWrapper>
  );
};

const EmptyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WordCloudChartWrapper = styled.div`
  height: calc(100% - 92px);
`;
const compareFn = (prevState, nextState) => {
  return JSON.stringify(prevState) === JSON.stringify(nextState);
};
export default memo(WordCloudChart, compareFn);
