import api from '../Util/api';
import {
  GET_TEXT_ANALYTICS,
  GET_COMPANY_MOOD,
  GET_LEADING_ISSUES,
  GET_PRIORITY_ISSUES,
  GET_CONCERNING_ISSUES,
  GET_ACTIVITY_LEVEL,
  GET_TEXT_ANALYTICS_INSIGHTS,
  GET_LEADING_ISSUES_INSIGHTS,
  GET_COMPANY_MOOD_INSIGHTS,
  GET_PRIORITY_ISSUES_INSIGHTS,
} from '../Constants/actionTypes';
import { hideLoading, showLoading } from './loading.actions';

export const getTextAnalytics = (data) => (dispatch) => {
  dispatch(showLoading());
  return new Promise((resolve, reject) =>
    api.analytics
      .getTextAnalytics(data)
      .then((res) => {
        dispatch({ type: GET_TEXT_ANALYTICS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getTextAnalyticsInsights = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.analytics
      .getTextAnalyticsInsights(data)
      .then((res) => {
        dispatch({ type: GET_TEXT_ANALYTICS_INSIGHTS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getCompanyMood = (data) => (dispatch) => {
  dispatch(showLoading());

  return new Promise((resolve, reject) =>
    api.analytics
      .getCompanyMood(data)
      .then((res) => {
        dispatch({ type: GET_COMPANY_MOOD, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getCompanyMoodInsights = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.analytics
      .getCompanyMoodInsights(data)
      .then((res) => {
        dispatch({ type: GET_COMPANY_MOOD_INSIGHTS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getLeadingIssues = (data) => (dispatch) => {
  dispatch(showLoading());

  return new Promise((resolve, reject) =>
    api.analytics
      .getLeadingIssues(data)
      .then((res) => {
        dispatch({ type: GET_LEADING_ISSUES, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};
export const getLeadingIssuesInsights = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.analytics
      .getLeadingIssuesInsights(data)
      .then((res) => {
        dispatch({ type: GET_LEADING_ISSUES_INSIGHTS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getPriorityIssues = (data) => (dispatch) => {
  dispatch(showLoading());

  return new Promise((resolve, reject) =>
    api.analytics
      .getPriorityIssues(data)
      .then((res) => {
        dispatch({ type: GET_PRIORITY_ISSUES, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getPriorityIssuesInsights = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.analytics
      .getPriorityIssuesInsights(data)
      .then((res) => {
        dispatch({ type: GET_PRIORITY_ISSUES_INSIGHTS, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getConcerningIssues = (data) => (dispatch) => {
  dispatch(showLoading());

  return new Promise((resolve, reject) =>
    api.analytics
      .getConcerningIssues(data)
      .then((res) => {
        dispatch({ type: GET_CONCERNING_ISSUES, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getActivityLevel = (data) => (dispatch) => {
  dispatch(showLoading());

  return new Promise((resolve, reject) =>
    api.analytics
      .getActivityLevel(data)
      .then((res) => {
        dispatch({ type: GET_ACTIVITY_LEVEL, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};
