import React from 'react';

import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip } from 'antd';

import FullCard from '../../../Components/fullCard';
import HeaderName from '../../../Components/headerName';
import {
  BrowsFiles,
  ButtonsWrapper,
  StyledSelect,
  TextAreaCounterWrapper,
  TextAreaErrorPosition,
  UploadContent,
  UploadDashedBorder,
} from './createSurveyPage';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SurveyForm = ({
  departments,
  getRootProps,
  getInputProps,
  isDragActive,
  isDragReject,
  thumbs,
  fileError,
  checkAllFieldFilled,
  goToDashboard,
  form,
  files,
  setStep,
  isEdit,
  uploadedLogo,
}) => {

const { t } = useTranslation();

  return (
    <FullCard>
      <HeaderName>{t("createHeartbeat")}</HeaderName>
      <Form.Item
        name="send_to"
        label={t("sendSurveyTo")}
        rules={[{ required: true, message: t("pleaseSelectDepartment") }]}
      >
        <StyledSelect
          getPopupContainer={(trigger) => trigger.parentElement}
          placeholder={t("pleaseSelectTo")}
          style={{ maxWidth: 500 }}
        >
          <Option value="0">{t("all")}</Option>
          {departments?.map(({ id, name }) => (
            <Option value={id}>{name}</Option>
          ))}
        </StyledSelect>
      </Form.Item>

      <Form.Item
        name="survey_name"
        label={t("surveyName")}
        rules={[{ required: true, message:  t("pleaseSelectYourSurveyName") }]}
      >
        <Input name="surveyName" placeholder={t("enterSurveyName")} style={{ maxWidth: 500 }} maxLength={100} showCount />
      </Form.Item>

      <TextAreaErrorPosition>
        <Form.Item
          name="description"
          label={t("description")}
          rules={[{ required: true, message:  t("pleaseWriteSomeDescription") }]}
        >
          <TextAreaCounterWrapper
            name="description"
            placeholder={t("enterADescription")}
            style={{ maxWidth: 500 }}
            maxLength={500}
            showCount
          />
        </Form.Item>
      </TextAreaErrorPosition>

      <Form.Item name="logo" label={t("uploadLogo")} style={{ maxWidth: 500 }}>
        <UploadDashedBorder {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
          <input {...getInputProps()} />
          <UploadContent>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CloudUploadOutlined style={{ fontSize: '40px', color: '#575D7A', marginRight: '7px' }} />
              <div style={{ color: '#575D7A', fontSize: '16px' }}>{t("dragDrop")}</div>
            </div>
            <div style={{ color: '#575D7A', fontSize: '16px' }}>{t("or")}</div>
            <BrowsFiles>{t("browseFiles")}</BrowsFiles>
          </UploadContent>
        </UploadDashedBorder>
        <span
          style={{
            marginTop: 5,
            color: '#CCCCCC',
            display: 'block',
          }}
        >
          {t("logoDescription")}
        </span>
        {isEdit && uploadedLogo ? uploadedLogo : thumbs}
        {fileError && <p style={{ color: 'red' }}>{t("pleaseUploadLogo")}</p>}
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <ButtonsWrapper>
          <Button style={{ marginRight: 10 }} onClick={goToDashboard}>
          {t("cancel")}
          </Button>
          <Tooltip
            title={
              checkAllFieldFilled(form.getFieldValue(), files?.length)
                ?  t("pleaseFillAllFieldsToGotoNextStep")
                : null
            }
          >
            <Button
              type="primary"
              disabled={checkAllFieldFilled(form.getFieldValue(), files?.length)}
              onClick={() => setStep(1)}
            >
              {t("next")}
            </Button>
          </Tooltip>
        </ButtonsWrapper>
      </div>
    </FullCard>
  );
};

export default SurveyForm;
