import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import MiniCard from '../../../Components/miniCard';
import TableCard from './tableCard';
import SurveyByCategory from './surveyByCategory';
import { getSurveyOverview } from '../../../Actions/survey.actions';
import Loading from '../../../Components/loading';
import LocationPath from '../../../Components/locationPath';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import { paths } from '..';
import { Select } from 'antd';
import copyToClipboard from '../../../Util/clipboard';
import { message } from 'antd';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const SurveyPage = () => {
  const { t } = useTranslation();
  const data = useSelector((store) => store.survey?.overview);
  const [departmentId, setDepartmentId] = useState('0');
  const [location, setLocation] = useState('0');

  const selectorOptions = useSelector((store) => store.company.departments);
  const locationOptions = useSelector((store) => store.company.locations);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    setLoading((prevState) => prevState + 1);
    dispatch(getSurveyOverview({ period: 'year', department_id: departmentId, positive: 'all', country_id: location })).finally(() =>
      setLoading((prevState) => prevState - 1),
    );
  }, [dispatch, departmentId, location]);

  const onSelectChange = (id) => {
    const departmentId = id === 'all' ? undefined : id;
    setDepartmentId(departmentId);
  };

  const onLocationChange = (id) => {
    const locationId = id === 'all' ? undefined : id;
    setLocation(locationId)
  };

  const { Option } = Select;

  const key = 'copyToClipboardLoading';
  const successKey = 'copyToClipboardSuccess';
  const newCopyToClipboard = async (value, setValue) => {
    message.destroy(key);
    message.destroy(successKey);
    const loadingTimer = setTimeout(() => {
      message.loading({ content:  t("copyingToClipboardPleaseWait"), key });
    }, 350);
    setIsLoading(true);

    const dontShowElement = document.getElementById('dontShowElement');
    const exportElement = document.getElementsByClassName(value);
    dontShowElement.style.display = 'none';
    console.log(exportElement, 'exportElement');
    const exportElementArr = [];
    for (let elem of exportElement) {
      exportElementArr.push(html2canvas(elem, { scale: 2 }));
    }
    const response = await axios.all(exportElementArr);
    console.log(response, 'response');

    for (let elem of response) {
      let imgData = elem.toDataURL('image/png', 1.0);
      try {
        await copyImageToClipboard(imgData);
        setIsLoading(false);

        dontShowElement.style.display = 'block';
        message.destroy(key);
        clearTimeout(loadingTimer);
        setTimeout(() => {
          message.success({ content:  t("imageCopiedToClipboard"), key: successKey });
          setValue();
        }, 350);
      } catch (e) {
        alert(e, 'error');
      }
    }
  };

  const handleChange = (value, setValue) => {
    if (value === 'copyFullImage') {
      newCopyToClipboard(value, setValue);
    } else {
      copyToClipboard(value, setValue);
    }
  };

  return (
    <div>
      {!!loading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      {isLoading && (
        <LoaderWrapper>
          <Loading />
        </LoaderWrapper>
      )}

      <BreadCrumbContainer>
        <LocationPath multi paths={paths} pathName="Heartbeat" />
        <StyledDiv>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder={t("copyToClipboard")}
            bordered={false}
            onChange={(val) => {
              handleChange(val, setValue);
              setValue(val);
            }}
            value={value}
          >
            <Option value="copyFullImage">{t("copyEntirePage")}</Option>
            <Option value="copyChartElement">{t("copyChartElement")}</Option>
            <Option value="copyDemographicInsights">{t("copyDemographicChart")}</Option>
          </StyledSelect>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            defaultValue="0"
            bordered={false}
            onChange={onSelectChange}
          >
            <Option value="0">{t("allDepartments")}</Option>
            {selectorOptions?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </StyledSelect>
          {/* <StyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            defaultValue="0"
            bordered={false}
            onChange={onLocationChange}
          >
            <Option value="0">{t("allLocations")}</Option>
            {locationOptions?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </StyledSelect> */}
        </StyledDiv>
      </BreadCrumbContainer>
      <MobileMargin />
      <div className="copyFullImage">
        <Section className="copyChartElement">
          <MiniCard
            data={{
              name:  t("totalSurveys"),
              data: {
                amount: data?.top_graph?.surveys?.value,
                graphData: data?.top_graph?.surveys?.previous_values,
                comparison: data?.top_graph?.surveys?.comparison,
              },
            }}
            width="31%"
            height={150}
            themeColor="green"
          />
          <MiniCard
            data={{
              name:  t("responses"),
              data: {
                amount: data?.top_graph?.responses?.value,
                graphData: data?.top_graph?.responses?.previous_values,
                comparison: data?.top_graph?.responses?.comparison,
              },
            }}
            width="31%"
            height={150}
            themeColor="blue"
          />
          <MiniCard
            data={{
              name:  t("averageSurveyCompletionRate"),
              data: {
                amount: data?.top_graph?.avg_completion_rate?.value,
                graphData: data?.top_graph?.avg_completion_rate?.previous_values,
                comparison: data?.top_graph?.avg_completion_rate?.comparison,
              },
            }}
            width="31%"
            height={150}
            themeColor="yellow"
          />
        </Section>
        <div id="dontShowElement">
          <TableCard />
        </div>
        {/* <div className="copyDemographicInsights">
          <SurveyByCategory setLoading={setLoading} departmentId={departmentId} location={location}/>
        </div> */}
      </div>
    </div>
  );
};

const StyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;
  .ant-select-borderless {
    border: none !important;
  }
  .ant-select-selector {
    width: 150px !important;
    height: 38px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    border: 1px solid #cdcdcd !important;
    color: #575d7a;
  }
  .ant-select-selection-item {
    padding-right: 24px !important;
  }
  .ant-select-arrow {
    margin-top: -18px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const LoaderWrapper = styled(HoverWrapper)`
  background: #ffffff;
`;

const MobileMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;

  @media screen and (max-width: 715px) {
    margin-bottom: 30px;
  }
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export default memo(SurveyPage);
