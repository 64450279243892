import React, { useMemo } from 'react';

import styled from 'styled-components';
import { Empty } from 'antd';

import { ContentWrapper, LeftWrapper, RightWrapper } from './fullCard';

import DoughnutChart from './doughnutChart';
import { useSelector } from 'react-redux';
import InfoContainer from './InfoContainer';
import { useTranslation } from 'react-i18next';

const SingleViewPieWithDescription = ({ data, noDataMessage, loader }) => {  
  const isMobileView = useSelector((store) => store.app.isMobileView);
  const { t } = useTranslation();

  const doughnutParsedData = useMemo(() => {
    return data ? data.map((item) => ({ title: item.title, percentage: item.percentage })) : [];
  }, [data]);

  if(!(!!loader)){
    if (doughnutParsedData.length === 0) return <Empty description={noDataMessage || t("noData")} />;
  }
  return (
    <ContentWrapper>
      <LeftWrapper>
        <DoughnutChart data={doughnutParsedData} width={300} height={300} />
      </LeftWrapper>
      <RightWrapper>
        {data?.map((item, index) =>
          data.length > 1 ? (
            <InfoContainer item={item} id={index} index={index} />
          ) : (
            <InfoWrapper>
              <InfoContainer item={item} id={index} index={index} />
            </InfoWrapper>
          ),
        )}
      </RightWrapper>
    </ContentWrapper>
  );
};

const InfoWrapper = styled.div`
  // margin-top: 100px;
  @media screen and (max-width: 820px) {
    margin-top: 0px;
  }
`;

export default SingleViewPieWithDescription;
