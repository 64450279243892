import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import SingleViewPieWithDescription from '../../../Components/singleViewPieWithDescription';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

const PriorityIssueCard = ({ period, setCompareprevious }) => {
  const data = useSelector((store) => store.analytics?.priorityIssues?.data?.current);
  const previous = useSelector((store) => store.analytics?.priorityIssues?.data?.previous);
  const { t } = useTranslation();
  const getPrev = (filters) => {
    setCompareprevious(filters.compare_previous);
  };

  return (
    <FullCard>
      <CardHeader
        name={t("priorityIssues")}
        noCompare={false}
        onSelect={getPrev}
        customWidth="400px"
        toolTipText={t("priorityIssuesTooltip")}
      />
      <SingleViewPieWithDescription data={data} loader={false}/>
      {previous && (
        <>
          <Divider />
          <CardHeader
            name={`${t("last")} ${period} ${t("priorityIssues")}`}
            noFilters
            customWidth="400px"
            toolTipText={t("priorityIssuesTooltip")}
          />
          <SingleViewPieWithDescription data={previous} loader={false}/>
        </>
      )}
    </FullCard>
  );
};

export default memo(PriorityIssueCard);
