import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CardHeader from '../../../Components/cardHeader';
import FullCard from '../../../Components/fullCard';
import MapChart from '../../../Components/mapChart';
import MapMiniTable from '../../../Components/mapMiniTable';
import { useTranslation } from 'react-i18next';

const MoreInsightsWorldMap = () => {
  const { t } = useTranslation();
  const data = useSelector((store) => store.analytics?.activityLevel?.data?.more_insights);

  return (
    <FullCard>
      <CardHeader
        name={t("demographicInsights")}
        customWidth="400px"
        toolTipText={t("insightsActivityTooltip")}
      />
      <Wrapper>
        <MapChart data={data} />
        <MapMiniTable data={data} />
      </Wrapper>
    </FullCard>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export default MoreInsightsWorldMap;
