import React, { useState } from 'react';

import { Avatar, Button, Checkbox, Col, Collapse, Form, Input, List, message, Select, Skeleton } from 'antd';

import HeaderName from '../../../Components/headerName';
import { ButtonsWrapper, CheckboxText, FormWrapper, StyledCollapse, TextName, TitleWrapper } from './createSurveyPage';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const SurveyNextStep = ({
  setStep,
  saveLoading,
  submitHandler,
  form,
  toggleModal,
  questions,
  draftLoading,
  checkAllFieldFilled,
  files,
  initialValues,
}) => {
  const { t } = useTranslation();
  const checkQuestionSelected = (fields) => {
    const questionsIds = Object.keys(fields)
      .filter((key) => key.indexOf('question') === 0)
      .reduce((allQuestionsIds, k) => {
        const newData = [...allQuestionsIds];
        newData.push(...fields[k]);
        return newData;
      }, []);

    const isCustomQuestion = customQuestions(form.getFieldValue())?.some((el) => el?.question);
    console.log('isCustomQuestion: ', isCustomQuestion);
    console.log('questionsIds?.length: ', questionsIds?.length);

    if (questionsIds?.length || isCustomQuestion) {
      return false;
    }
    message.warning(t('pleaseSelectAtLeastOneQuestion')).then();
    return true;
  };

  //   if (!questionsIds?.length) {
  //     message.warning('Please select atkkkk least one question!').then();
  //     return true;
  //   }
  //   return false;
  // };
  const customQuestions = (fields) => {
    return Object.keys(fields)
      .filter((key) => key.indexOf('freetext_questions') === 0)
      .reduce((obj, key) => {
        const newData = [...obj];
        newData.push(...fields[key]);
        return newData;
      }, []);
  };

  const { Option } = Select;
  const { TextArea } = Input;
  const [isDisplay, setIsDisplay] = useState('none');

  const custom = {
    items: [
      {
        ques: '',
        answerType: 'regular',
      },
    ],
  };
  const itemInputs = custom.items.map((item) => {
    return {
      question: item.ques,
      type: item.answerType,
    };
  });

  return (
    <div>
      <TitleWrapper>
        <HeaderName>{t('createHeartbeat')}</HeaderName>
        <Button style={{ height: 32 }} type="primary" size="small" onClick={toggleModal}>
          {t('previewSurvey')}
        </Button>
      </TitleWrapper>
      <FormWrapper>
        <TextName>{t('selectQuestions')}</TextName>

        {questions === null ? (
          <Skeleton loading={true} active>
            <List.Item.Meta avatar={<Avatar />} />
          </Skeleton>
        ) : (
          <div>
            <StyledCollapse defaultActiveKey={Object.keys(initialValues)}>
              {questions?.map((q) => (
                <Panel key={`question-${q.id}`} header={q.category_name}>
                  <Form.Item name={`question-${q.id}`}>
                    <Checkbox.Group>
                      {q.questions?.map((qu) => (
                        <Col key={qu.id}>
                          <Checkbox value={qu.id} style={{ lineHeight: '32px' }}>
                            <CheckboxText>{qu.question}</CheckboxText>
                          </Checkbox>
                        </Col>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Panel>
              ))}
            </StyledCollapse>
            <StyledCollapse>
              <Panel key="freetextQuestion" header={t('freeTextQuestion')}>
                <Form.List name="freetext_questions" initialValue={itemInputs}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <StyledSpace key={field.key}>
                            <Form.Item
                              style={{ width: '100%' }}
                              {...field}
                              name={[field.name, 'question']}
                              fieldKey={[field.fieldKey, 'question']}
                            >
                              <TextArea
                                placeholder={t('typeYourQuestionHere')}
                                size="large"
                                maxLength={200}
                                showCount
                                autoSize={{
                                  minRows: 2,
                                }}
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              initialValue="regular"
                              name={[field.name, 'type']}
                              fieldKey={[field.fieldKey, 'type']}
                              style={{ flex: 0 }}
                            >
                              <Select
                                disabled={!customQuestions(form.getFieldValue())?.[index]?.question}
                                getPopupContainer={(trigger) => trigger.parentElement}
                                placeholder="Answer Type"
                                style={{ width: '150px' }}
                              >
                                <Option key="regular" value="regular">
                                  {t('multipleChoice')}
                                </Option>
                                <Option key="freeText`" value="freeText">
                                  {t('freeTextChoice')}
                                </Option>
                              </Select>
                            </Form.Item>
                            <Button
                              style={{ display: isDisplay, color: 'red', marginBottom: '20px', flex: 0 }}
                              onClick={() => remove(field.name)}
                            >
                              <CloseOutlined />
                            </Button>
                          </StyledSpace>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{ float: 'right' }}
                          onClick={() => {
                            add();
                            setIsDisplay('block');
                          }}
                        >
                          {t('addNewQuestion')}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Panel>
            </StyledCollapse>
          </div>
        )}
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <ButtonsWrapper>
              <Button style={{ marginRight: 10 }} onClick={() => setStep(0)} htmlType="button">
                {t('previous')}
              </Button>
              {/* <Button
                loading={draftLoading}
                htmlType="button"
                onClick={() => submitHandler(form.getFieldsValue(), 'draft')}
              >
                Save as Draft
              </Button> */}
              <Button
                onClick={() => (checkQuestionSelected(form.getFieldValue()) ? null : setStep(2))}
                htmlType="button"
                style={{ height: 32 }}
                type="primary"
                size="small"
              >
                {t('next')}
              </Button>
            </ButtonsWrapper>
          </div>
        </Form.Item>
      </FormWrapper>
    </div>
  );
};

export default SurveyNextStep;

export const StyledSpace = styled(Space)`
  display: flex;
  gap: 20px;
  .ant-space-item {
    width: 100%;
    flex: 0;
    :first-child {
      flex: 1 !important;
    }
  }
`;