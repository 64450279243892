import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Empty } from 'antd';
import { Bar } from 'react-chartjs-2';

import colorPalette from '../Util/colorPalette';
import { customChartTooltip } from '../Util/customChartTooltip';
import { StyledTooltip } from './companyMoodGraph';
import Dot from './dot';
import { useTranslation } from 'react-i18next';

const MoreInsightsGraph = ({ data }) => {
  const [toolTip, setToolTip] = useState({
    top: 0,
    left: 0,
    data: null,
    showTooltip: false,
  });
  const ref = useRef(null);
  const { t } = useTranslation();
  const parsedData = useMemo(() => {
    if (!data) return [];
    const result = { labels: [], datasets: [] };
    Object.keys(data).forEach((mainKey, mainIndex) => {
      result.datasets.push({
        label: mainKey,
        data: [],
        moreData: data[mainKey],
        backgroundColor: colorPalette[mainIndex],
        borderColor: 'white',
        borderWidth: 1,
        barThickness: 7,
      });
      Object.keys(data[mainKey]).forEach((key, index) => {
        if (mainIndex === 0) result.labels.push(key);
        result.datasets[mainIndex].data.push(data[mainKey][key].count);
      });
    });

    return result;
  }, [data]);

  const setPositionAndData = (payload) => {
    setToolTip((prevState) => ({
      ...prevState,
      top: payload.top,
      left: payload.left,
      data: payload.data,
      showTooltip: true,
    }));
  };

  const hide = () => {
    setToolTip((prevState) => ({
      ...prevState,
      showTooltip: false,
    }));
  };

  const options = useMemo(
    () => ({
      bezierCurve: false,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: 'index',
        intersect: false,
        position: 'average',
        custom: (tooltipModel) => {
          customChartTooltip(tooltipModel, ref, hide, setPositionAndData);
        },
      },
      hover: {
        mode: 'null',
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
            stacked: true,
            ticks: {
              padding: 10,
              fontColor: '#8f9092',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
            stacked: true,
            ticks: {
              fontColor: '#8f9092',
              padding: 30,
              callback: function (value) {
                if (Math.floor(value) === value) {
                  return value;
                }
              },
            },
          },
        ],
      },
    }),
    [],
  );

  const parsedDataForTooltip = useMemo(() => {
    const data = {};
    if (!parsedData) return [];
    parsedData?.labels?.forEach((label, index) => {
      parsedData.datasets.forEach((d) => {
        data[label] = {
          ...data[label],
          [d.label]: d.data[index],
        };
      });
    });
    return data;
  }, [parsedData]);

  if (!data)
    return (
      <EmptyWrapper>
        <Empty />
      </EmptyWrapper>
    );

  const left = toolTip.left + 5;
  const top = toolTip.top - 15;

  return (
    <CompanyMoodGraphWrapper>
      <Bar ref={ref} data={parsedData} options={options} height={290} />
      {toolTip.showTooltip && toolTip.data?.key ? (
        <StyledTooltip
          style={{
            top,
            left: left + 20,
          }}
        >
          <p
            style={{
              background: ` #28BF8D`,
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {toolTip.data?.key}
          </p>
          <StyledTooltipContainer>
            {parsedDataForTooltip
              ? Object.keys(parsedDataForTooltip?.[toolTip?.data?.key] || [])?.map((key, index) => {
                  return (
                    <div>
                      <Dot color={colorPalette[index]} />
                      <span style={{ marginLeft: '10px' }}>
                        {parsedDataForTooltip?.[toolTip.data?.key][key] || 0} {t("response")}
                      </span>
                    </div>
                  );
                })
              : null}
          </StyledTooltipContainer>
          <StyledTooltipTotal>
            <span>{t("total")} :</span>
            <span>
              {Object.keys(parsedDataForTooltip?.[toolTip?.data?.key] || [])?.reduce((acc, curr) => {
                return (acc += parsedDataForTooltip?.[toolTip.data?.key][curr] || 0);
              }, 0)}
            </span>
          </StyledTooltipTotal>
        </StyledTooltip>
      ) : null}
    </CompanyMoodGraphWrapper>
  );
};

const StyledTooltipTotal = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
`;

const StyledTooltipContainer = styled.div`
  margin: 10px;
  padding: 5px 0;
  margin-top: 0;
  border-bottom: 1.5px solid #b6b2b2;
`;

const EmptyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompanyMoodGraphWrapper = styled.div`
  height: 290px;
  width: 100%;
  margin-top: 30px;
`;

export default MoreInsightsGraph;
