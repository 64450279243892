import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SupportRoomLogo } from '../Assets/supportroom-logo-dark.svg';
import DashboardNav from './dashboardNav';
import Avatar from './avatar';
import { CompanyMenuLinks, language } from '../Data/Menus';
import { ReactComponent as MinimiseLogo } from '../Assets/Icons/Sidebar/downArrow.svg';

import { Popover, Tooltip } from 'antd';
import LanguageSwitch from './LangSwitcher/LanguageSwitch';

const HeaderBar = ({ links, viewportWidth }) => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();
  const [openLanguage, setOpenLanguage] = useState(false);
  const { i18n } = useTranslation();

  const profileData = useSelector((store) => store.company?.profile);
  const userDetails = useSelector((store) => store.company?.userSettings?.userDetails);
  const isMobileView = useSelector((store) => store.app.isMobileView);

  const handleClickChange = (visible) => {
    setClicked(visible);
    setOpenLanguage(false)
  };

  const hide = () => {
    setClicked(false);
  };

  const languageChange = (value) => {
    if (value) {
      localStorage.setItem('lang', value);
      i18n.changeLanguage(value);
    } else {
      localStorage.setItem('lang', '');
    }
  };

  const isDesktop = viewportWidth > 1170;
  return (
    <>
      <DashboardToolbar isDesktop={isDesktop} isMobileView={isMobileView}>
        {!isDesktop && <DashboardNav links={links} />}
        <DashboardNavHeader>
          <LogoLink to="/t/dashboard">
            <Logo as={SupportRoomLogo} />
          </LogoLink>
        </DashboardNavHeader>
        {/* <LanguageSwitch /> */}
        {profileData && (
          <ToolbarItem className="profile">
            <DropdownPopover
              overlayClassName="dropdown-popover"
              overlayInnerStyle={{
                width: '281px',
              }}
              visible={clicked}
              onVisibleChange={handleClickChange}
              content={
                <div>
                  <DropdownContainer onClick={() => console.log('te43adsst')}>
                    {CompanyMenuLinks(t).map((item) => (
                      <li key={item.id} onClick={() => console.log('test')}>
                        {item.open ? (
                          <div
                            style={{ color: '#06c9a2', cursor: 'pointer' }}
                            onClick={() => setOpenLanguage(!openLanguage)}
                          >
                            {item.title}
                            <ToggleLogo as={MinimiseLogo} />
                          </div>
                        ) : (
                          <Link to={item.url} onClick={hide}>
                            {item.title}
                          </Link>
                        )}
                      </li>
                    ))}

                    {openLanguage &&
                      language(t).map((item) => (
                        <li key={item.id} onClick={(value) => languageChange(item.value)}>
                          <Link to={item.url} onClick={hide}>
                            {item.title}
                          </Link>
                        </li>
                      ))}
                  </DropdownContainer>
                </div>
              }
              trigger="click"
            >
              <ProfileWrapper>
                <Avatar src={userDetails?.profile_image ? userDetails?.profile_image : undefined} width={50} />
                <NameWrapper>
                  <Tooltip
                    placement="left"
                    color="black"
                    title={`${userDetails?.first_name} (${profileData?.company_name})`}
                  >
                    <Name>{handleName(userDetails, profileData)}</Name>
                  </Tooltip>
                </NameWrapper>
              </ProfileWrapper>
            </DropdownPopover>
          </ToolbarItem>
        )}
      </DashboardToolbar>
    </>
  );
};

const handleName = (userDetails, profileData) => {
  let name = userDetails?.first_name + ' (' + profileData?.company_name + ')';
  return name.slice(0, 16) + '...';
};

const Logo = styled.svg``;

const ToggleLogo = styled.svg`
  position: absolute;
  right: 26px;
  top: 140px;
  cursor: pointer;
`;

const DropdownPopover = styled(Popover)``;

const DropdownContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    height: 40px;
    border-bottom: 1px solid rgb(196, 196, 196);
    padding: 0px 24px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
`;

const DashboardToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  min-height: 80px;
  border-bottom: 1px solid #ebebeb;
  padding-left: ${({ isDesktop }) => (isDesktop ? '32px' : '25px')};

  ${({ isMobileView }) =>
    isMobileView &&
    css`
      background: #f9f9f9;
    `}

  ${({ isDesktop }) =>
    isDesktop
      ? css``
      : css`
          ${Logo} {
            width: 216px;
          }
          ${ActionsPanel} {
            width: 200px;
          }
        `};

  @media screen and (max-width: 400px) {
    ${Logo} {
      width: 160px;
    }
  }
`;

const DashboardNavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoLink = styled(Link)``;

const ProfileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 11px;
  font-size: 16px;
`;

const ToolbarItem = styled.div`
  position: relative;
  width: 281px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing} 24px;
  border-left: 1px solid #ebebeb;

  &.profile {
    cursor: pointer;
  }

  &::after {
    width: 12px;
    height: 12px;
    right: 20px;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #9d9d9d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-100%);
  }

  @media screen and (max-width: 620px) {
    padding: 0;
    width: 60px;
    border: none;

    ${NameWrapper} {
      display: none;
    }
    ${ProfileWrapper} {
      margin-right: 25px;
    }

    &:after {
      content: none;
    }
  }
`;

const Name = styled.div`
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  width: 150px;
  text-overflow: ellipsis;
`;

const ActionsPanel = styled.div`
  width: 281px;
  float: right;

  border: 1px solid #ebebeb;
  box-shadow: 0 4px 27px rgba(73, 31, 105, 0.14);
  z-index: 1000;

  li {
    height: 40px;
    border-bottom: 1px solid #c4c4c4;
    padding: 0 24px;
    display: flex;
    align-items: center;

    &:last-child {
      border: none;
    }

    &:hover {
      a {
        color: ${({ theme }) => theme.primary}!important;
      }
    }
  }

  a {
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;

    text-decoration: none;

    color: #737373 !important;
  }
`;

export default HeaderBar;
