import React, { memo, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import PriortityIssueCard from './priorityIssueCard';
import MoreInsightsCard from './moreInsightsCard';
import { getPriorityIssues } from '../../../Actions/analytics.actions';
import GlobalFilters from '../../../Components/globalFilters';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import LocationPath from '../../../Components/locationPath';
import { paths } from '..';
import NewStyledSelect from '../../../SharedStyles/newStyledSelect';
import copyToClipboard from '../../../Util/clipboard';
import { useTranslation } from 'react-i18next';

const PriorityIssuesPage = ({isRequired=false}) => {
  const [period, setPeriod] = useState('year');
  const [departmentId, setDepartmentId] = useState(0);
  const [location, setLocation] = useState(0);

  const [compareprevious, setCompareprevious] = useState(0);
  const [sortByMoreInsights, setSortByMoreInsights] = useState('age');
  const data = useSelector((store) => store.analytics?.priorityIssues?.data);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onPeriodChange = (p) => {
    setPeriod(p);
    // setLoadingDashboard(true);
    // dispatch(getPriorityIssues({ period: p, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };

  const handleChange = (value, setValue) => {
    copyToClipboard(value, setValue);
  };
  
  const onSelectChange = (id) => {
    const departmentId = id;
    setDepartmentId(departmentId);
    // setLoadingDashboard(true);
    // dispatch(getPriorityIssues({ period, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };

  const onLocationChange = (id) => {
    const locationId = id;
    setLocation(locationId)
    // setLoadingDashboard(true);
    // dispatch(getConcerningIssues({ period, department_id: departmentId })).finally(() => setLoadingDashboard(false));
  };

  useEffect(() => {
    dispatch(
      getPriorityIssues({
        period,
        department_id: departmentId,
        compare_previous: compareprevious,
        insight_type: sortByMoreInsights,
        country_id: location,
      }),
    );
  }, [dispatch, period, departmentId, compareprevious, sortByMoreInsights, location]);
  return (
    <>
      {/* {data === undefined ? (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      ) : null} */}
      {/* <FiltersWrapper>
        <GlobalFilters period={period} onPeriodChange={onPeriodChange} onSelectChange={onSelectChange} />
      </FiltersWrapper> */}
      <BreadCrumbContainer>
        <LocationPath styles={{ margin: 0 }} multi paths={paths} pathName={t("analytics")} />
        <StyledDiv>
          <NewStyledSelect onChange = {handleChange} isRequired/>
          <GlobalFilters period={period} onPeriodChange={onPeriodChange} onSelectChange={onSelectChange} onLocationChange={onLocationChange} />
        </StyledDiv>
      </BreadCrumbContainer>

      <MobileMargin />
      <div className="copyFullImage">
        <div className="copyChartElement">
          <PriortityIssueCard period={period} setCompareprevious={setCompareprevious} />
        </div>
        <div className="copyDemographicInsights">
          <MoreInsightsCard
            globalFilters={{ department_id: departmentId, period }}
            setSortByMoreInsights={setSortByMoreInsights}
            sortByMoreInsights={sortByMoreInsights}
            toolTipText={t("insightsPageTooltip")}
          />
        </div>
      </div>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const MobileMargin = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0;

  @media screen and (max-width: 715px) {
    margin-bottom: 30px;
  }
`;

const FiltersWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 18px;

  @media screen and (max-width: 715px) {
    top: 60px;
    left: 25px;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
`;

export default memo(PriorityIssuesPage);
