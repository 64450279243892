import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Line } from 'react-chartjs-2';
import { sum } from 'lodash';
import { Empty } from 'antd';

import MoreInsights from './moreInsightsButton';
import Dot from './dot';
import tooltipLine from './tooltipLine';
import Loading from './loading';
import moment from 'moment';

import { ReactComponent as AlrightIcon } from '../Assets/Icons/alrightIcon.svg';
import { ReactComponent as MehIcon } from '../Assets/Icons/mehIcon.svg';
import { ReactComponent as BadIcon } from '../Assets/Icons/badIcon.svg';
import { customChartTooltip } from '../Util/customChartTooltip';
import { useTranslation } from 'react-i18next';

const COLORS = {
  green: {
    border: 'rgb(40,191,141)',
    gradient: ['rgba(66,232,160,0)', 'rgba(66,232,160,0.44)', 'rgba(66,232,160,0.9)'],
  },
  blue: {
    border: 'rgb(39,189,240)',
    gradient: ['rgb(255,255,255)', 'rgb(219,238,255)', 'rgb(182,219,255)'],
  },
};

const CompanyMoodGraph = ({ originalData, period, isSinglePage = false, previous }) => {
  const [toolTip, setToolTip] = useState({
    top: 0,
    left: 0,
    data: null,
    showTooltip: false,
  });
  const ref = useRef(null);
  const history = useHistory();
  const goTo = () => history.push('/analytics/company-mood');
  const { t } = useTranslation();
  const gradient = useCallback((color) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 300, 0, 0);
    gradient.addColorStop(0.35, COLORS[color].gradient[0]);
    gradient.addColorStop(0.6, COLORS[color].gradient[1]);
    gradient.addColorStop(0.9, COLORS[color].gradient[2]);

    return gradient;
  }, []);

  const setPositionAndData = (payload) => {
    setToolTip((prevState) => ({
      ...prevState,
      top: payload.top,
      left: payload.left,
      data: payload.data,
      showTooltip: true,
    }));
  };

  const hide = () => {
    setToolTip((prevState) => ({
      ...prevState,
      showTooltip: false,
    }));
  };

  const data = {};
  const dateStart = moment();
  const dateEnd = moment().subtract(11, 'month');

  while (dateEnd.isBefore(dateStart, 'day')) {
    data[dateEnd.format('MMM')] = '';
    dateEnd.add(1, 'month');
  }
  data[dateEnd.format('MMM')] = '';

  Object.assign(data, originalData);
  let graphData;
  {
    period == 'year' ? (graphData = data) : (graphData = originalData);
  }

  const parsedData = useMemo(() => {
    const newData = {
      labels: [],
      datasets: [
        {
          label: 'Current',
          data: [],
          moreData: graphData,
          fill: true,
          backgroundColor: gradient('green'),
          borderColor: COLORS.green.border,
          borderWidth: 3,
        },
        {
          label: 'Previous',
          data: [],
          moreData: previous,
          fill: true,
          backgroundColor: gradient('blue'),
          borderColor: COLORS.blue.border,
          borderWidth: 3,
        },
      ],
    };
    graphData &&
      Object.keys(graphData).forEach((key) => {
        newData.labels.push(key);

        const values = [];
        Object.values(graphData[key]).forEach((v, index) => values.push(v * (index + 1)));
        const sumOfValues = sum(values);
        newData.datasets[0].data.push(sumOfValues ? sumOfValues / 100 : 0);
      });

    previous &&
      Object.keys(previous).forEach((key) => {
        // newData.labels.push(key);

        const values = [];
        Object.values(previous[key]).forEach((v, index) => values.push(v * (index + 1)));
        const sumOfValues = sum(values);
        newData.datasets[1].data.push(sumOfValues ? sumOfValues / 100 : 0);
      });

    return newData;
  }, [data, gradient, previous]);

  // const xArray = useMemo(() => (data ? Object.keys(data) : []), [data]);
  let xArray;
  {
    period == 'year'
      ? (xArray = data ? Object.keys(data) : [])
      : (xArray = originalData ? Object.keys(originalData) : []);
  }

  const currentYear = new Date().getFullYear();
  const currentDay = new Date().getDate();

  let addYear = function (element, index, array) {
    let year;
    if (array.indexOf(element) < array.indexOf('Jan')) {
      year = currentYear - 1;
      array[index] = element + ',' + year;
    } else {
      year = currentYear;
      array[index] = element + ',' + year;
    }
  };
  xArray.forEach(addYear);

  const left = toolTip.left + 5;
  const top = toolTip.top - 15;

  // const customTooltip = (tooltipModel) => {
  //   console.log('tooltipModel.opacity: ', tooltipModel.opacity);
  //   const result = {}
  //   result.visible = tooltipModel.opacity !== 0

  //   // Get content for the tooltip
  //   if (tooltipModel.dataPoints) {
  //     result.xLabel = tooltipModel.dataPoints[0].xLabel
  //     result.yLabel = ref.current?.chartInstance?.config?.data?.datasets?.[0]?.moreData?.[tooltipModel?.title?.[0]];
  //     let finalResult ={ key: result.xLabel, value: result.yLabel}
  //     setToolTip((prevState) => ({
  //       ...prevState,
  //       top: tooltipModel.caretY,
  //       left: tooltipModel.caretX,
  //       data: finalResult,
  //       showTooltip: true,
  //     }));
  //   }
  //   result.xPos = tooltipModel.caretX
  //   result.yPos = tooltipModel.caretY
  //   setTimeout(hide, 8000, result)
  // }
  
  const options = useMemo(
    () => ({
      bezierCurve: false,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: 'index',
        intersect: false,
        position: 'average',
        custom: (tooltipModel) => {
          setTimeout(hide, 3000, customChartTooltip(tooltipModel, ref, hide, setPositionAndData))
        },
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              zeroLineColor: '#e3e3e3',
            },
            ticks: {
              padding: 10,
              fontColor: '#8f9092',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              zeroLineColor: 'transparent',
            },
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              fontColor: '#8f9092',
              min: 0,
              max: 5,
              padding: 30,
              callback: function (value) {
                const x = ['', 'Awful', 'Bad', 'Ok', 'Alright', 'Great'];
                return x[value | 0];
              },
            },
          },
        ],
      },
    }),
    [],
  );

  if (!data) return <Loading />;

  return (
    <CompanyMoodGraphWrapper isSinglePage={isSinglePage}>
      {parsedData.labels?.length ? (
        <GraphHistoryWrapper isSinglePage={isSinglePage}>
          {xArray.length && (
            <History>
              <Dot />{' '}
              <HistoryText>
                {period === 'year' ? '1' : null} {xArray[0]} - {period === 'year' ? currentDay : null}{' '}
                {xArray[xArray.length - 1]}
              </HistoryText>
            </History>
          )}
          {previous && (
            <History>
              <Dot color="#26BDF0" /> <HistoryText>{t('previous')}</HistoryText>
            </History>
          )}
        </GraphHistoryWrapper>
      ) : null}
      {parsedData.labels?.length ? (
        <>
          <Line
            id="chartjs-tooltip"
            data={parsedData}
            options={options}
            height={isSinglePage ? 100 : 290}
            plugins={[tooltipLine]}
            ref={ref}
          />
          {toolTip.showTooltip ? (
            <StyledTooltip
              style={{
                top,
                left: left + 20,
              }}
            >
              <h5>{toolTip.data?.key}</h5>
              <TooltipResults>
                <Result>
                  <Icon as={AlrightIcon} style={{ marginRight: '5px' }} />
                  <span style={{ marginRight: '5px' }}>
                    {toolTip.data?.moreData['Great'] ? toolTip.data?.moreData['Great'] : 0}%
                  </span>
                  <span style={{ color: '#7E84A3' }}>{t('great')}</span>
                </Result>
                <Result>
                  <Icon as={AlrightIcon} style={{ marginRight: '5px' }} />
                  <span style={{ marginRight: '5px' }}>
                    {toolTip.data?.moreData['Alright'] ? toolTip.data?.moreData['Alright'] : 0}%
                  </span>
                  <span style={{ color: '#7E84A3' }}>{t('alright')}</span>
                </Result>
                <Result>
                  <Icon as={MehIcon} style={{ marginRight: '5px' }} />
                  <span style={{ marginRight: '5px' }}>
                    {toolTip.data?.moreData['Meh'] ? toolTip.data?.moreData['Meh'] : 0}%
                  </span>
                  <span style={{ color: '#7E84A3' }}>{t('Ok')}</span>
                </Result>
                <Result>
                  <Icon as={BadIcon} style={{ marginRight: '5px' }} />
                  <span style={{ marginRight: '5px' }}>
                    {toolTip.data?.moreData['Bad'] ? toolTip.data?.moreData['Bad'] : 0}%
                  </span>
                  <span style={{ color: '#7E84A3' }}>{t('bad')}</span>
                </Result>
                <Result>
                  <Icon as={BadIcon} style={{ marginRight: '5px' }} />
                  <span style={{ marginRight: '5px' }}>
                    {toolTip.data?.moreData['Awful'] ? toolTip.data?.moreData['Bad'] : 0}%
                  </span>
                  <span style={{ color: '#7E84A3' }}>{t('awful')}</span>
                </Result>
              </TooltipResults>
            </StyledTooltip>
          ) : null}
        </>
      ) : (
        <Empty style={{ marginTop: 80 }} />
      )}
      {!isSinglePage && <MoreInsights style={{ marginTop: 20 }} onClick={goTo} />}
    </CompanyMoodGraphWrapper>
  );
};

const CompanyMoodGraphWrapper = styled.div`
  height: 290px;
  width: 100%;
  margin-bottom: 50px;
`;

const GraphHistoryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: ${({ isSinglePage }) => (isSinglePage ? 'center' : 'start')};
`;

const Icon = styled.svg``;

const History = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const HistoryText = styled.div`
  margin-left: 5px;
`;

export const StyledTooltip = styled.div`
  width: 140px;
  position: fixed;
  color: white;

  // padding: 10px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 5px 15px #44444f1a;
  border-radius: 10px;
`;

const TooltipResults = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const Result = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export default CompanyMoodGraph;
