import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Collapse, Input } from 'antd';
import 'antd/dist/antd.css';

import { ReactComponent as SearchOutlined } from '../../Assets/Icons/search-icon.svg';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Hooks';
import { Select, Form } from 'antd';
import api from '../../Util/api';
import Loading from '../../Components/loading';
import CustomTooltip from '../../Components/customTooltip';
import Modal from '../../Components/Modal';
import { Tabs } from 'react-tabs';
const { Option } = Select;

const AdditionalSupport = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(null);
  // const [isLoading, setIsLoading] = useState(0);
  const [open, setOpen] = useState(false);

  const [benefits, setbenefits] = useState([]);
  // const [faq, setFaq] = useState([
  //   { question: 'How are you?', answer: 'I am good' },
  //   { question: 'How are you doing?', answer: 'I am doing good.' },
  //   { question: "What's your age?", answer: '0' },
  //   { question: "What's your age?", answer: '0' },
  //   { question: "What's your age?", answer: '0' },
  //   { question: "What's your age?", answer: '0' },
  //   { question: 'test question 1', answer: 'test  1' },
  //   { question: 'test question 2', answer: 'test 2' },
  //   { question: 'test question 3', answer: 'test  3' },
  //   { question: 'test question 4', answer: 'test  4' },
  //   { question: 'test question 6', answer: 'test 6' },
  //   { question: 'test question 7', answer: 'test 7' },
  // ]);
  const [faq, setFaq] = useState([]);
  const [initialFaq, setInitialFaq] = useState([]);
  const [currIndex, setCurrIndex] = useState(0);

  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 768, [width]);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const onFinish = async (values) => {
    form.resetFields();
    console.log('values: ', values);
    const benefit_message = await api.survey.benefitMessage(currentTab, values.message);
    console.log('benefit_message: ', benefit_message);
  };

  useEffect(() => {
    const getFaq = async (id) => {
      // setIsLoading((prevState) => prevState + 1);

      const response_faq = await api.survey.categoryFaq(id);
      setFaq((prevState) => ({ ...prevState, [id]: response_faq?.data?.data }));
      setInitialFaq((prevState) => ({ ...prevState, [id]: response_faq?.data?.data }));

      // setIsLoading((prevState) => prevState - 1);
    };

    const getBenefits = async () => {
      // setIsLoading((prevState) => prevState + 1);
      const response_benefit = await api.survey.benefitCategories();
      setbenefits(
        response_benefit?.data?.data.map((el) => ({ ...el, subject: 'test', description: 'testDescription' })),
      );
      setCurrentTab(response_benefit?.data?.data?.[0]?.id);
      response_benefit?.data?.data.map(async (el) => await getFaq(el.id));
      // setIsLoading((prevState) => prevState - 1);
    };

    getBenefits();
  }, []);

  const onSearch = (e) => {
    let searchText = e.target.value;
    setCurrIndex(0);

    if (!searchText) return setFaq(initialFaq);
    const updatedData = { ...initialFaq };
    const filteredData = {};
    for (let categoryId in updatedData) {
      const filteredCategoryData = updatedData[categoryId].filter((elem) => {
        return (
          elem.question?.toLowerCase().includes(searchText.toLowerCase()) ||
          elem.answer?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      filteredData[categoryId] = filteredCategoryData;
    }
    setCurrIndex(0);
    setFaq(filteredData);
  };

  const handleNext = () => {
    setCurrIndex(currIndex + 8);
  };

  const handlePrev = () => {
    setCurrIndex(currIndex - 8);
  };

  // if (isLoading) {
  //   return (
  //     <>
  //       {/* <Header /> */}
  //       <Loading styles={{ position: 'fixed', backgroundColor: 'rgb(250, 243, 255)' }} />
  //     </>
  //   );
  // }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#f4f7fc',
        }}
      >
        <main style={{ overflow: 'auto' }}>
          <>
            <ButtonWrapper isMobile={isMobile}>
              {!isMobile ? (
                <>
                  <div>
                    <StyledInput placeholder="Search..." allowClear onChange={onSearch} />
                    <SearchOutlined style={{ height: '20px', position: 'relative', marginLeft: '-80px', top: '8px' }} />
                  </div>
                  <Button
                    style={{ fontSize: '12px', height: '35px' }}
                    type="primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {t("bookAnAppointment")}
                  </Button>
                </>
              ) : (
                <>
                  <StyledSelect
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={currentTab}
                    bordered={false}
                    onChange={(val) => {
                      setCurrentTab(val);
                      setCurrIndex(0);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    {benefits.map((tab, i) => (
                      <Option
                        key={i}
                        // onClick={() => {
                        //   setCurrentTab(tab.id);
                        // }}
                        value={tab.id}
                        // active={tab.id === currentTab}
                      >
                        {tab.name}
                      </Option>
                    ))}
                  </StyledSelect>
                  <div style={{ display: 'flex' }}>
                    <StyledInput placeholder={t("search")} allowClear onChange={onSearch} />

                    <SearchOutlined
                      style={{
                        height: '20px',
                        position: 'relative',
                        marginLeft: '-135px',
                        overflow: 'none',
                        top: '8px',
                        left: '55px',
                      }}
                    />
                  </div>
                </>
              )}
            </ButtonWrapper>
            <TabFormGroup isMobile={isMobile}>
              <TabWrapper>
                {!isMobile && (
                  <Tabs defaultActiveKey="1" tabPosition="top">
                    {benefits.map((tab, i) => (
                      <Tab
                        key={i}
                        onClick={() => {
                          setCurrentTab(tab.id);
                        }}
                        active={tab.id === currentTab}
                      >
                        {tab.name}
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </TabWrapper>
            </TabFormGroup>
            <MainWrapper>
              {isMobile && (
                <Button
                  style={{ fontSize: '12px', alignSelf: 'self-end', margin: '0 10px 20px', height: '35px' }}
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("bookAnAppointment")}
                </Button>
              )}

              <Wrapper isMobile={isMobile}>
                <MainContentContainer isMobile={isMobile}>
                  {benefits.map((tab, i) => {
                    return tab.id === currentTab ? (
                      <div key={tab.id}>
                        {
                          <StyledCollapse>
                            {faq[tab.id]?.length ? (
                              faq[tab.id].slice(currIndex, currIndex + 8)?.map((element, index) => (
                                <Panel key={element.question + index} header={element.question}>
                                  {element.answer}
                                </Panel>
                              ))
                            ) : (
                              <div style={{ fontSize: 16, marginTop: 40 }}>{t("Noresultsfound")}</div>
                            )}
                          </StyledCollapse>
                        }
                      </div>
                    ) : null;
                  })}
                  {faq[currentTab]?.length > 8 && (
                    <Pagination isMobile={isMobile}>
                      <Button variant="medium primary" disabled={currIndex === 0} onClick={handlePrev}>
                      {t("previous")}
                      </Button>
                      <Button
                        variant="medium primary"
                        disabled={currIndex + 8 >= faq[currentTab]?.length}
                        onClick={handleNext}
                      >
                        {t("next")}
                      </Button>
                    </Pagination>
                  )}
                </MainContentContainer>
              </Wrapper>
            </MainWrapper>
          </>
        </main>
      </div>
      <Modal open={open} closeModal={() => null} showCloseButton={true}>
        <Form
          name="userForm"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          style={{ margin: '30px' }}
          initialValues={{
            subject: '',
            message: '',
          }}
        >
          <Form.Item
            name="subject"
            label={t("Subject")}
            rules={[{ required: true, message: t("pleaseEnterSubjectLine") }]}
          >
            <Input name="subject" />
          </Form.Item>

          <Form.Item
            name="message"
            label={t("message")}
            rules={[{ required: true, message: t("pleaseEnterBriefSummaryOfTheIssue") }]}
          >
            <TextArea
              name="message"
              placeholder={t("briefSummaryOfIssue")}
              size="large"
              autoSize={{
                minRows: 4,
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
          {t("send")}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default AdditionalSupport;

const StyledCollapse = styled(Collapse)`
  border: none;
  background-color: transparent;
  background-color: #f4f7fc;
  .ant-collapse-item {
    background-color: #fff;
    margin: 14px 0;
    border-radius: 15px !important;
    padding: 5px 0;

    .ant-collapse-header {
      .ant-collapse-header-text {
        color: #00c8a0;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  label {
    font-size: 16px !important;
    font-weight: normal !important;
    padding: 0 !important;
  }
`;

const Tab = styled.button`
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
  border: 0;
  margin: 10px 0px;
  font-weight: 600;
  color: #0000008f;
  cursor: pointer;
  opacity: 1;
  background-color: transparent;
  border-bottom: 2px solid #28bf8d;
  outline: 0;
  padding: 10px;

  ${({ active }) =>
    active &&
    `
    color: #28bf8d;
    opacity: 1;
  `}
  &:hover {
    color: ${({ theme }) => theme.primaryLight};

    opacity: 1;
  }

  ${({ theme }) => theme.md`
  font-size: 14px;
  padding: 10px 40px;
  `}

  ${({ theme }) => theme.lg`
  font-size: 14px;
  padding: 10px 40px;
  `}
`;

const TabWrapper = styled.div`
  display: flex;
  border: none;
  background-color: #f4f7fc;
  flex-direction: column;
  align-items: flex-start;
  width: 300%;
  margin-top: 12px;
  ${({ theme }) => theme.md`
    width: 100%;
  `}
`;

const StyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;

  .ant-select-borderless {
    border: none !important;
  }

  .ant-select-selector {
    width: 140px !important;
    height: 38px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    border: 1px solid #cdcdcd !important;
    color: #575d7a;
  }

  .ant-select-selection-item {
    margin-top: 3px;
    padding-right: 24px !important;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 30px;
`;

// const WrapperMobile = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   ${'' /* gap: 10px; */}
//   ${'' /* margin-bottom: 30px; */}
// `;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${'' /* overflow: auto; */}

  padding-right: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const StyledInput = styled(Input)`
  height: 40px;
  width: 150px;
  border-radius: 4px;
  .ant-input {
    height: 30px;
    padding-right: 6px;
  }
  .ant-input-suffix {
    margin-left: -5px;
    z-index: 2;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '6px' : '12px')};
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #f4f7fc;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  ${'' /* overflow: auto; */}

  ${({ theme }) => theme.md`
 flex-direction: row;
  `}
`;

const TabFormGroup = styled.div`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};

  flex: 0.3;
  ${'' /* margin-top: 70px; */}
  flex-direction: column;

  gap: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 0.7;
  flex-direction: column;
  background-color: #f4f7fc;
  width: 100%;

  padding-left: ${({ isMobile }) => (isMobile ? '15px' : '0px')};
`;

const TextareaWrapper = styled.div`
  textarea {
    ::placeholder {
      color: #999999;
      font-size: 16px;
    }
  }
`;

const StyledTextarea = styled.textarea`
  resize: none;
`;
StyledTextarea.displayName = 'StyledTextarea';
