import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Line } from 'react-chartjs-2';

import MoreInsights from './moreInsightsButton';
import tooltipLine from './tooltipLine';
import { StyledTooltip } from './companyMoodGraph';
import Dot from './dot';
import { customChartTooltip } from '../Util/customChartTooltip';
import { useTranslation } from 'react-i18next';

const COLORS = {
  green: {
    border: 'rgb(40,191,141)',
    gradient: ['rgba(66,232,160,0)', 'rgba(66,232,160,0.44)', 'rgba(66,232,160,0.9)'],
  },
};

const ActivityLevel = ({ data }) => {
  const [toolTip, setToolTip] = useState({
    top: 0,
    left: 0,
    data: null,
    showTooltip: false,
  });
  const ref = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();

  const gradient = useMemo(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 300, 0, 0);
    gradient.addColorStop(0.35, COLORS.green.gradient[0]);
    gradient.addColorStop(0.6, COLORS.green.gradient[1]);
    gradient.addColorStop(0.9, COLORS.green.gradient[2]);

    return gradient;
  }, []);

  const options = useMemo(
    () => ({
      bezierCurve: false,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: 'index',
        intersect: false,
        position: 'average',
        custom: (tooltipModel) => {
          customChartTooltip(tooltipModel, ref, hide, setPositionAndData);
        },
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 10,
              fontColor: '#8f9092',
              zIndex: 2,
            },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              min: 0,
              max: 65,
            },
          },
        ],
      },
    }),
    [],
  );

  const goTo = () => history.push('/analytics/activity-level');

  const setPositionAndData = (payload) => {
    setToolTip((prevState) => ({
      ...prevState,
      top: payload.top,
      left: payload.left,
      data: payload.data,
      showTooltip: true,
    }));
  };

  const hide = () => {
    setToolTip((prevState) => ({
      ...prevState,
      showTooltip: false,
    }));
  };

  const parsedData = useMemo(() => {
    const newData = {
      labels: [],
      datasets: [
        {
          label: 'Activity overtime',
          data: [],
          fill: true,
          moreData: data,
          backgroundColor: gradient,
          borderColor: COLORS.green.border,
          borderWidth: 3,
          zIndex: 1,
        },
      ],
    };

    Object.keys(data).forEach((key) => {
      newData.labels.push(key);
      newData.datasets[0].data.push(data[key]);
    });
    return newData;
  }, [data, gradient]);

  const left = toolTip.left + 5;
  const top = toolTip.top - 15;
  return (
    <CompanyMoodGraphWrapper>
      {/*<FloatingPercentage>*/}
      {/*  <PercentageStatus isIncrease percentage={23} />*/}
      {/*</FloatingPercentage>*/}
      <>
        <Line ref={ref} data={parsedData} options={options} height={210} plugins={[tooltipLine]} />
        {toolTip.showTooltip ? (
          <StyledTooltip
            style={{
              top,
              left: left + 20,
            }}
          >
            <h5>{t("uniqueLogins")}</h5>
            <div>
              <Dot />
              <span style={{ marginLeft: '10px' }}>{toolTip.data.moreData}</span>
            </div>
          </StyledTooltip>
        ) : null}
      </>
      <MoreInsights style={{ marginTop: 30 }} onClick={goTo} />
    </CompanyMoodGraphWrapper>
  );
};

// const FloatingPercentage = styled.div`
//   position: absolute;
//   top: -30px;
// `;

const CompanyMoodGraphWrapper = styled.div`
  position: relative;
  height: 240px;
  width: 100%;
  margin-top: 30px;
`;

export default ActivityLevel;
