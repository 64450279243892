import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../Routes/Route';

import LeadingIssuesPage from './LeadingIssuePage/leadingIssuesPage';
import PriorityIssuesPage from './PriorityIssuePage/priorityIssuesPage';
import TherapistFeedback from './TherapistFeedbackPage/therapistFeedbackPage';
import TextAnalyticsPage from './TextAnalyticsPage/textAnalyticsPage';
import CompanyMoodPage from './CompanyMoodPage/companyMoodPage';
import ActivityLevelPage from './ActivityLevelPage/activityLevelPage';

// import ActivityLevel from './activityLevel';
import EmployeeCount from './employeeCount';

export const paths = (t) => ({
  'leading-issues': t("leadingIssues"),
  'priority-issues': t('priorityIssues'),
  'therapist-feedback': t('clinicalInsights'),
  'text-analytics': t('conversationalAnalytics'),
  'activity-level': t('activityLevel'),
  'employee-count': t("employeeCount"),
  'company-mood': t('companyMood'),
});

const Analytics = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/analytics/leading-issues" component={LeadingIssuesPage} />
        <Route exact path="/analytics/priority-issues" component={PriorityIssuesPage} />
        <Route exact path="/analytics/therapist-feedback" component={TherapistFeedback} />
        <Route exact path="/analytics/text-analytics" component={TextAnalyticsPage} />
        <Route exact path="/analytics/company-mood" component={CompanyMoodPage} />
        <Route exact path="/analytics/activity-level" component={ActivityLevelPage} />

        {/*<Route exact path="/analytics/activity-level" component={ActivityLevel} />*/}
        <Route exact path="/analytics/employee-count" component={EmployeeCount} />
      </Switch>
    </div>
  );
};

export default Analytics;
