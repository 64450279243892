import React from 'react';
import { useHistory } from 'react-router';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import MoreInsights from './moreInsightsButton';
import HeartbeatMiniCard from './heartbeatMiniCard';
import { useSelector } from 'react-redux';

const SurveysMiniTable = ({ data: heartBeatData }) => {
  const history = useHistory();
  const profileData = useSelector((store) => store.company?.profile);
  const { t } = useTranslation();
  const goToOverview = () => history.push('/survey/overview');
  const goToCreate = () => history.push('/survey/create');

  return (
    <>
      <div>
        <div>
          <HeartbeatMiniCard
            data={{
              name: t("totalHeartbeat"),
              data: {
                amount: heartBeatData?.top_graph?.surveys?.value,
                graphData: heartBeatData?.top_graph?.surveys?.previous_values,
                comparison: heartBeatData?.top_graph?.surveys?.comparison,
              },
            }}
            width="100%"
            themeColor="green"
            headingStyles={{ boxShadow: 'none', padding: 'unset', paddingLeft: 40, paddingTop: 10 }}
          />
          <HeartbeatMiniCard
            data={{
              name: t("heartbeatInteraction"),
              data: {
                amount: heartBeatData?.top_graph?.responses?.value,
                graphData: heartBeatData?.top_graph?.responses?.previous_values,
                comparison: heartBeatData?.top_graph?.responses?.comparison,
              },
            }}
            width="100%"
            headingStyles={{ boxShadow: 'none', padding: 'unset', paddingLeft: 40, paddingTop: 10 }}
            themeColor="blue"
          />
          <HeartbeatMiniCard
            data={{
              name: t("averageResponseRate"),
              data: {
                amount: heartBeatData?.top_graph?.avg_completion_rate?.value,
                graphData: heartBeatData?.top_graph?.avg_completion_rate?.previous_values,
                comparison: heartBeatData?.top_graph?.avg_completion_rate?.comparison,
              },
            }}
            headingStyles={{ boxShadow: 'none', padding: 'unset', paddingLeft: 40, paddingTop: 10 }}
            width="100%"
            themeColor="yellow"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 22 }}></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <MoreInsights text={t("viewAllHeartbeat")} onClick={goToOverview} />
          {profileData?.survey_limit ? (
            <Button type="primary" onClick={goToCreate}>
            {t("createHeartbeat")}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SurveysMiniTable;
