import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.primary};
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => theme.primary};
  }

  ::placeholder {
    color: ${({ theme }) => theme.primary};
  }

  body {
    color: ${({ theme }) => theme.primary};
  }

  a {
    color: ${({ theme }) => theme.primary};
  }

  hr {
    border: 0;
    height: 1px;
    background: ${({ theme }) => theme.primaryMedium};
    margin: ${({ theme }) => theme.spacingLg} auto;
  }

  input,
  select,
  textarea {
    border: 2px solid ${({ theme }) => theme.primaryMedium};
    color: ${({ theme }) => theme.primary};
  }

  input,
  select,
  textarea {
    font-size: 17px;
    ${({ theme }) => theme.md`
      font-size: 20px;
    `}
  }
`;

export default GlobalStyle;
