import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { adminLogin } from '../../Actions/auth.actions';
import { getProfile } from '../../Actions/company.actions';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../Components/formGroup';
import Alert from '../../Components/alert';
import Button from '../../Components/button';
import Input from '../../Components/input';

import password from '../../Assets/Icons/Login/password.svg';
import { useLocation } from 'react-router';

const LoginForm = (props) => {
  const [state, setState] = useState({
    showError: false,
    errorMessage: '',
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const loginCode = query.get('login_code');

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      await dispatch(adminLogin('admin', values, history, props.redirect, props.timeout));
      dispatch(getProfile());
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setState({
        showError: true,
        errorMessage: err.response.data.message,
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    login_code: Yup.string().min(6,  t("passwordLeast6Characters")).required( t("loginCodeIsRequired")),
    admin_password: Yup.string()
      .min(6,  t("passwordLeast6Characters"))
      .required( t("adminPasswordIsRequired")),
  });

  return (
    <Wrapper>
      <Formik
        initialValues={{
          admin_password: '',
          login_code: loginCode,
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <FormGroup>
              <Field id="admin_password" name="admin_password">
                {({ field }) => (
                  <Input
                    id="admin_password"
                    name="admin_password"
                    placeholder={t("adminPassword")}
                    type="password"
                    prefix={<img src={password} alt="password" />}
                    className={'form-control' + (errors.admin_password && touched.admin_password ? ' is-invalid' : '')}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage name="admin_password" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <Field id="login_code" name="login_code">
                {({ field }) => (
                  <Input
                    id="login_code"
                    name="login_code"
                    placeholder={t("loginCode")}
                    type="text"
                    prefix={<img src={password} alt="password" />}
                    className={'form-control' + (errors.login_code && touched.login_code ? ' is-invalid' : '')}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage name="password" component="div" className="invalid-feedback" />
            </FormGroup>

            <Button loading={loading} type="primary" htmlType="submit">
            {t("login")}
            </Button>
          </Form>
        )}
      </Formik>

      {state.showError && (
        <Alert className="danger" addMargin>
          {state.errorMessage}
        </Alert>
      )}
    </Wrapper>
  );
};

LoginForm.propTypes = {
  userType: PropTypes.string,
  heading: PropTypes.string,
};

LoginForm.defaultProps = {
  userType: 'patient',
  heading: 'Login to your account',
};

const Wrapper = styled.div`
  //max-width: 500px;
  //margin-left: auto;
  //margin-right: auto;
`;

export default LoginForm;
