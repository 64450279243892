import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import Input from '../../Components/input';
import Button from '../../Components/button';
import FormGroup from '../../Components/formGroup';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../../Layout';

import email from '../../Assets/Icons/Login/email.svg';
import password from '../../Assets/Icons/Login/password.svg';
import { resetPassword } from '../../Actions/auth.actions';

const validationSchema = (t) => Yup.object().shape({
  email: Yup.string().email( t("invalidEmail")).required(t("emailIsRequired")),
  reset_code: Yup.number().required( t("resetcodeIsRequired")),
  new_password: Yup.string().matches(
    /^.*(?=.{7,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    t("matchPassword"),
  ),
  confirm_password: Yup.string().when('new_password', {
    is: (password) => password && password.length > 0,
    then: Yup.string()
      .required( t("pleaseConfirmPassword"))
      .oneOf([Yup.ref('new_password')],  t("passwordDoesnotMatch")),
  }),
});
const ResetPassword = () => {
  const { t } = useTranslation();
  const [showResponse, setShowResponse] = useState(false);
  const dispatch = useDispatch();

 
  const handleOnSubmit = async (values, { resetForm, setSubmitting, setFieldError }) => {
    dispatch(resetPassword(values))
      .then((res) => {
        if (res.data.status === 'success') {
          setShowResponse(res.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        setFieldError('email', err.response.data.message);
      });
    setSubmitting(false);
  };

  return (
    <AuthLayout heading={t("forgotPassword")}>
      <Wrapper>
        <Formik
          initialValues={{
            email: '',
            reset_code: '',
            new_password: '',
            confirm_password: '',
          }}
          validationSchema={validationSchema(t)}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Field
                  id="email"
                  name="email"
                  render={({ field }) => (
                    <Input
                      id="email"
                      name="email"
                      placeholder={t("enterEmailAddress")}
                      type="email"
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                      prefix={<img src={email} alt="email" />}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="reset_code"
                  name="reset_code"
                  render={({ field }) => (
                    <Input
                      id="reset_code"
                      name="reset_code"
                      placeholder={t("resetCode")}
                      type="reset_code"
                      className={'form-control' + (errors.reset_code && touched.reset_code ? ' is-invalid' : '')}
                      prefix={<ResetCodePrefix>{t("123")}</ResetCodePrefix>}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage name="reset_code" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="new_password"
                  name="new_password"
                  render={({ field }) => (
                    <Input
                      id="new_password"
                      name="new_password"
                      placeholder={t("newPassword")}
                      type="new_password"
                      className={'form-control' + (errors.new_password && touched.new_password ? ' is-invalid' : '')}
                      prefix={<img src={password} alt="new_password" />}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
              </FormGroup>
              <FormGroup>
                <Field
                  id="confirm_password"
                  name="confirm_password"
                  render={({ field }) => (
                    <Input
                      id="confirm_password"
                      name="confirm_password"
                      placeholder={t("enterEmailAddress")}
                      type="confirm_password"
                      className={
                        'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')
                      }
                      prefix={<img src={password} alt="confirm_password" />}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
              </FormGroup>
              {showResponse && <div style={{ marginBottom: 20 }}>{showResponse}</div>}
              <Button loading={false} type="primary" htmlType="submit">
              {t("resetPassword")}
              </Button>
            </Form>
          )}
        </Formik>
        <div style={{ marginTop: 40 }}>
          <div>{t("knowYourPassword")}</div>
          <Link to="/">
            <b style={{ color: '#00c8a0' }}>{t("loginHere")}</b>
          </Link>
        </div>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledForm = styled(Form)`
  display: grid;
  column-gap: 4px;
  row-gap: ${({ theme }) => theme.spacing};

  > * {
    margin: 0;
  }

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    
    button {
      height: 44px;
    }
  `}
`;
StyledForm.displayName = 'StyledForm';

const ResetCodePrefix = styled.span`
  font-size: 12px;
  color: #33c8a0;
  font-weight: 600;
`;

export default ResetPassword;
