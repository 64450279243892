import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import SurveyPreview from '../SurveyPreview/SurveyPreview';

// const getCustomQuestions = (freeTextQuestions, filterType = 'regular') => {
//   return freeTextQuestions?.filter((question) => question?.type === filterType);
// };

const PreviewSurvey = ({ data, questions, isModalVisible, toggleModal, uploadedLogo, setStep, previewFreeText }) => {
  const { t } = useTranslation();
  const questionsIds = useMemo(
    () =>
      data
        ? Object.keys(data)
            .filter((key) => key.indexOf('question') === 0)
            .reduce((allQuestionsIds, k) => {
              const newData = [...allQuestionsIds];
              newData.push(...data[k]);
              return newData;
            }, [])
        : [],
    [data],
  );

  const selectedQuestionsList = useMemo(() => {
    const list = [];
    questions?.forEach((question) => {
      question?.questions?.forEach((q) => {
        if (questionsIds?.includes(q.id)) {
          list.push({ question: q.question, type: "regular" });
        }
      });
    });
    return list;
  }, [questions, questionsIds]);

  if (!data) return null;

  return (
    <Modal
      bodyStyle={{ padding: 0, height: '80vh' }}
      width="70%"
      title= {t("surveyPreview")}
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={[
        <Button
          onClick={() => {
            toggleModal();
            setStep(0);
          }}
        >
          {t("edit")}
        </Button>,
      ]}
    >
      <SurveyPreview
        key={Math.random()}
        questionnaire={{
          id: 21,
          name: data.survey_name,
          description: data.description,
          logo_url: data?.logo?.file?.preview || uploadedLogo,
          gender: [t("male"), t("female"), t("others")],
          ethnicity: [t('black'), t('asian'), t('white'), t('mixed'), t('other')],
          age: ['18 - 24', '25 - 40', '41 - 55', '55+'],
          questions: selectedQuestionsList.concat(previewFreeText),
          // freetext_questions: getCustomQuestions(previewFreeText, 'freeText'),
        }}
      />
    </Modal>
  );
};

export default PreviewSurvey;
