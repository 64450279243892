const tooltipLine = {
  id: 'tooltipLine',
  beforeDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      ctx.save();
      const activePoint = chart.tooltip._active[0];

      ctx.beginPath();
      ctx.setLineDash([5, 7]);
      ctx.moveTo(activePoint._view.x, chart.chartArea.top);
      ctx.lineTo(activePoint._view.x, activePoint._view.y);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(40,191,141)';
      ctx.stroke();
      ctx.restore();

      ctx.beginPath();
      ctx.moveTo(activePoint._view.x, activePoint._view.y);
      ctx.lineTo(activePoint._view.x, chart.chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgb(40,191,141)';
      ctx.stroke();
      ctx.restore();
    }
  },
};

export default tooltipLine;
