import { HIDE_LOADING, SHOW_LOADING } from '../Constants/actionTypes';

const initialState = {
  loader: false,
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        loader: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
}
