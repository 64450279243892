import React from 'react';
import { Tooltip } from 'antd';
// import { useSelector } from 'react-redux';

const CustomTooltip = ({ children, placement, title, customWidth }) => {
  // const isMobileView = useSelector((store) => store.app.isMobileView);
  // const isTabletView = useSelector((store) => store.app.isTabletView);

  // if (isMobileView || isTabletView) return children;
  return (
    <Tooltip
      color="rgba(0,0,0,.85)"
      placement={placement}
      overlayInnerStyle={{ whiteSpace: 'pre-wrap', width: customWidth ? customWidth : 'auto' }}
      title={<span>{title}</span>}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
