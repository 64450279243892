import api from '../Util/api';
import { LOGIN, LOGOUT, ERRORS, MAP_STORAGE_TO_REDUX } from '../Constants/actionTypes';
import axios from 'axios';

export const login = (userType, data, history, url, timeout = false) => (dispatch) => {
  return api.auth
    .login(userType, data)
    .then((res) => {
      if (res.data.status === 'success') {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            loggedIn: true,
            access_token: res.data.access_token,
            expires_at: res.data.expires_at,
            role: res.data.role,
          }),
        );

        axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

        const setupLogin = () => {
          dispatch({
            type: LOGIN,
            payload: { ...res.data, loggedIn: true },
          });

          history.push(url);
        };

        if (!timeout) setupLogin();
        else setTimeout(() => setupLogin(), 3000);
      }
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const adminLogin = (userType, data, history, url, timeout = false) => (dispatch) => {
  return api.auth
    .adminLogin(userType, data)
    .then((res) => {
      if (res.data.status === 'success') {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            loggedIn: true,
            access_token: res.data.access_token,
            expires_at: res.data.expires_at,
            role: res.data.role,
          }),
        );

        axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

        const setupLogin = () => {
          dispatch({
            type: LOGIN,
            payload: { ...res.data, loggedIn: true },
          });

          history.push(url);
        };

        if (!timeout) setupLogin();
        else setTimeout(() => setupLogin(), 3000);
      }
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch({
        type: ERRORS,
        payload: err,
      });
      return Promise.reject(err);
    });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  axios.defaults.headers.common.Authorization = 'Bearer ';

  localStorage.removeItem('auth');
};

export const unauthorizedLogout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  axios.defaults.headers.common.Authorization = 'Bearer ';

  window.location.reload();

  localStorage.removeItem('auth');
};

export const mapStorageToRedux = () => (dispatch) => {
  const loginInfo = JSON.parse(localStorage.getItem('auth'));
  dispatch({
    type: MAP_STORAGE_TO_REDUX,
    payload: { ...loginInfo },
  });
};

export const verifyEmail = (token) => () =>
  new Promise((resolve, reject) =>
    api.auth
      .verifyEmail(token)
      .then((res) => resolve(res))
      .catch((err) => reject(err)),
  );

export const resetPassword = (data) => () =>
  new Promise((resolve, reject) =>
    api.auth
      .resetPassword(data)
      .then((res) => resolve(res))
      .catch((err) => reject(err)),
  );
