import React from 'react';
import styled from 'styled-components';
import Logo from '../Assets/supportroom-logo.svg';

import image from '../Assets/Signup/Image.png';

const AuthLayout = (props) => {
  return (
    <AuthWrapper>
      <BackgroundImage src={image} />
      <StyledWrapper id="siteWrapper" {...props}>
        <StyledHeader role="banner">
          <LogoContainer>
            <img src={Logo} alt="" />
          </LogoContainer>
        </StyledHeader>
        <StyledMain id="mainContent">
          <div style={{ width: '100%' }}>
            <HeadingText>{props.heading}</HeadingText>
            <ChildrenContent>{props.children}</ChildrenContent>
          </div>
        </StyledMain>
      </StyledWrapper>
    </AuthWrapper>
  );
};

const ChildrenContent = styled.div`
  width: 100%;
  max-width: 350px;
`;

const BackgroundImage = styled.img`
  width: 50%;
  object-fit: cover;
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 50%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.white};
`;

const StyledMain = styled.main`
  width: 100%;
  margin-top: 200px;
  padding: 0 150px;
`;

const StyledHeader = styled.header`
  padding: 80px 150px;
`;

const LogoContainer = styled.div`
  width: 250px;
  ${({ theme }) => theme.md`
    width: 350px;
  `}
`;

const AuthWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 1100px) {
    ${StyledMain} {
      padding: 0 80px;
    }
    ${StyledHeader} {
      padding: 80px;
    }
  }

  @media screen and (max-width: 900px) {
    ${StyledMain} {
      padding: 0 40px;
    }
    ${StyledHeader} {
      padding: 40px;
    }
  }

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    ${BackgroundImage} {
      width: 100%;
      height: 200px;
      margin-top: 75px;
    }
    ${StyledHeader} {
      padding: 0;
    }
    ${StyledWrapper} {
      width: 100%;
      min-height: auto;
    }
    ${LogoContainer} {
      margin: 0 auto;
      position: absolute;
      top: -270px;
      left: 50%;
      transform: translateX(-50%);
    }
    ${StyledMain} {
      margin: 40px 0;
    }
  }
`;

const HeadingText = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;

  color: ${({ theme }) => theme.secondary};
  margin-bottom: 40px;
`;

export default AuthLayout;
