import React, { useEffect } from 'react';
import { Button, Form, Input, message, Upload } from 'antd';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { CloseCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import api from '../../Util/api';
import { useTranslation } from 'react-i18next';
import HeaderName from '../../Components/headerName';
import { BrowsFiles, UploadContent, UploadDashedBorder } from '../Survey/CreateSurveyPage/createSurveyPage';

const BenefitsForm = (props) => {
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [formData, setFormData] = useState();
  const [initialValues, setInitialValues] = useState(props.editData);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const removeFile = () => {
    setFiles([]);
    setFormData((prevState) => ({ ...prevState, logo: null }));
  };

  const thumbs = files.map((file) => (
    <Thumb key={file.name}>
      <RemoveThumb>
        <CloseCircleOutlined onClick={removeFile} />
      </RemoveThumb>
      <ThumbInner>
        <Img alt="image" src={file.preview} />
      </ThumbInner>
    </Thumb>
  ));

  const onDrop = useCallback((acceptedFiles) => {
    setFormData((prevState) => ({
      ...prevState,
      logo: acceptedFiles.length ? { file: acceptedFiles[0], preview: acceptedFiles[0].preview } : null,
    }));
    setFileError(false);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    onDropRejected: (err) => {
      if (err?.[0].errors?.[0].code === 'file-too-large') {
        message.error('File is too large than 200kb');
      }
    },
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    maxSize: 200000,
  });

  const onFinish = async (values) => {
    const data = new FormData();
    data.append('benefit_title', values.benefit_title);
    data.append('benefit_description', values.benefit_description);
    data.append('company_name', values.company_name);
    data.append('benefit_link', values.benefit_link);

    if (files.length === 0) {
      setFileError(true);
      return;
    }

    if (props.editData?.benefit_id) {
      if (!values.benefit_logo) {
        data.append('benefit_logo', formData?.logo?.file);
      }
      data.append('benefit_id', props.editData.benefit_id);

      await api.survey.updateBenefits(data);
    } else {
      data.append('benefit_logo', formData?.logo?.file);
      await api.survey.addBenefits(data);
    }
    props.getData();
    form.resetFields();
    setInitialValues({
      benefit_title: '',
      companyName: '',
      benefit_description: '',
      benefit_link: '',
    });
    removeFile();
  };

  return (
    <div>
      <Title>{t("addNewEmployeeBenefit")}</Title>
      <Card>
        <Form
          name="userForm"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          initialValues={initialValues}
        >
          <Form.Item
            name="benefit_title"
            label={t("benefitTitle")}
            rules={[{ required: true, message:  t("pleaseEnterTheBenefitTitle") }]}
          >
            <Input
              name="benefitTitle"
              placeholder={t("enterTheTitleOfEmployeeBenefit")}
              style={{ maxWidth: 500 }}
              maxLength={100}
              showCount
            />
          </Form.Item>

          <Form.Item name="company_name" label={t("companyNameOptional")}>
            <Input
              name="companyName"
              placeholder={t("enterTheCompanyForBenefitRedemption")}
              style={{ maxWidth: 500 }}
              maxLength={100}
              showCount
            />
          </Form.Item>

          <Form.Item name="benefit_logo" label={t("benefitLogo")} style={{ maxWidth: 500 }} required>
            <UploadDashedBorder {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
              <input {...getInputProps()} />
              <UploadContent>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CloudUploadOutlined style={{ fontSize: '40px', color: '#575D7A', marginRight: '7px' }} />
                  <div style={{ color: '#575D7A', fontSize: '16px' }}>{t("dragDrop")}</div>
                </div>
                <div style={{ color: '#575D7A', fontSize: '16px' }}>{t("or")}</div>
                <BrowsFiles>{t("browseFiles")}</BrowsFiles>
              </UploadContent>
            </UploadDashedBorder>
            <span
              style={{
                marginTop: 5,
                color: '#CCCCCC',
                display: 'block',
              }}
            >
              {t("logoSize")}
            </span>
            {props.editData?.benefit_logo ? <Img alt="image" src={props.editData?.benefit_logo} /> : thumbs}
            {fileError && <p style={{ color: 'red' }}>{t("pleaseUploadLogo")}</p>}
          </Form.Item>

          <Form.Item
            name="benefit_description"
            label={t("benefitDescription")}
            rules={[{ required: true, message: t("pleaseEnterTheBenefitDescription") }]}
          >
            <TextAreaCounterWrapper
              name="benefitDescription"
              placeholder={t("describeEmployeeBenefit")}
              style={{ maxWidth: 500 }}
            />
          </Form.Item>

          <Form.Item
            name="benefit_link"
            label={t("linkToBenefit")}
            rules={[{ required: true, message:  t("pleaseEnterLinkToBenefit") }]}
          >
            <Input
              name="benefitLink"
              placeholder={t("enterLinkForBenefitRedemption")}
              style={{ maxWidth: 500 }}
            />
          </Form.Item>
          {!props.editData?.benefit_id ? (
            <Button htmlType="submit" type="primary">
            {t("addNewBenefit")}
            </Button>
          ) : (
            <Button htmlType="submit" type="primary">
            {t("updateBenefit")}
            </Button>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default BenefitsForm;

const TextAreaCounterWrapper = styled(Input.TextArea)`
  ::after {
    width: auto !important;
  }
`;

const Card = styled.div`
  background: white;
  min-width: 450px;

  border-radius: 5px;
  padding: 22px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 4px;

  margin-bottom: 35px;
  ${'' /* position: fixed; */}

  @media screen and (max-width: 768px) {
    min-width: auto;
    margin-bottom: 15px;
  }
`;

const Title = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;
  padding: 5px 0 10px 0;
`;

const Thumb = styled.div`
  position: relative;
  margin-top: 10px;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

const Img = styled.img`
  display: block;
  width: 100px;
  height: 100px;
`;

const RemoveThumb = styled.div`
  position: absolute;
  top: -7px;
  left: -7px;
  cursor: pointer;

  svg {
    font-size: 18px;
    path {
      fill: red;
    }
  }
`;
