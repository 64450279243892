import React, { memo } from 'react';

import styled from 'styled-components';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';

import CompanyMoodGraph from '../../../Components/companyMoodGraph';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CompanyMoodCard = ({ period, setCompareprevious }) => {
  const { t } = useTranslation();
  const data = useSelector((store) => store.analytics.companyMood?.data?.current);
  const previous = useSelector((store) => store.analytics?.companyMood?.data?.previous);

  const getPrev = (filters) => {
    setCompareprevious(filters.compare_previous);
  };

  return (
    <FullCard>
      <CardHeader
        name={t("companyMood")}
        noFilters
        noCompare={false}
        onSelect={getPrev}
        customWidth="400px"
        toolTipText={t("companyMoodTooltip")}
      />
      <Wrapper>
        <CompanyMoodGraph originalData={data} previous={previous} period={period} isSinglePage />
      </Wrapper>
    </FullCard>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 180px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export default memo(CompanyMoodCard);
