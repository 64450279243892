import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from './loading';

const Loader = () => {
  const loader = useSelector((store) => store.loading.loader);

  return (
    loader && (
      <HoverWrapper>
        <Loading />
      </HoverWrapper>
    )
  );
};

export default Loader;

const HoverWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
