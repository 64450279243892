import { GET_DASHBOARD, LOGOUT } from '../Constants/actionTypes';

const initialState = {
  data: null,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        data: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
