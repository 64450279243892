import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const CollapseComponent = ({ data, defaultActiveKey = null }) => {
  if (!data) return null;
  return (
    <StyledCollapse defaultActiveKey={defaultActiveKey} ghost expandIconPosition="right">
      {data.map(({ header, text }, index) => (
        <Panel header={header} key={`panel-${index}`} style={{ fontWeight: 600 }}>
          <p style={{ fontWeight: 400, marginLeft: 20 }}>{text}</p>
        </Panel>
      ))}
    </StyledCollapse>
  );
};

export default CollapseComponent;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-top: 1px solid #d7d7d7;

    &:last-child {
      border-bottom: 1px solid #d7d7d7;
    }
  }
`;
