import { EyeFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colorPalette from '../Util/colorPalette';
import CustomTooltip from './customTooltip';
import Dot from './dot';
import RecommendationModal from './recommendationModal';
import Square from './square';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const InfoContainer = ({ item, index, isClinicalInsight = false }) => {
  const [modal, setModal] = useState({});
  const { t } = useTranslation();
  const profileData = useSelector((store) => store.company?.profile);

  const [demographicGender, setDemographicGender] = useState(true);

  useEffect(() => {
    if (profileData?.demographics) {
      setDemographicGender(true);
    } else {
      setDemographicGender(false);
    }
  }, [profileData]);

  return (
    <>
      <Info key={item.id}>
        <InfoHeader>
          <Square size={19} color={colorPalette[index]} />
          {isClinicalInsight ? (
            <Tooltip
              placement="bottom"
              getPopupContainer={(triggerNode) => triggerNode}
              overlayInnerStyle={{
                width: '180px',
                position: 'fixed',
                color: 'white',
                background: '#ffffff 0 0 no-repeat padding-box',
                boxShadow: '0 5px 15px #44444f1a',
                borderRadius: '10px',
              }}
              title={
                <span>
                  <p
                    style={{
                      background: ` #28BF8D`,
                      textAlign: 'center',
                      fontWeight: 500,
                      marginBottom: 3,
                    }}
                  >
                    {item.title} {t('dets')}
                  </p>
                  <StyledTooltipContainer>
                    {item?.sub_issues?.length ? (
                      item?.sub_issues?.map((el) => (
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              fontSize: 12,
                            }}
                          >
                            <div>
                              <Dot color="grey" />
                              <span style={{ marginLeft: '10px' }}>{el.title}</span>
                            </div>
                            <span style={{ marginLeft: '10px' }}>{el.count}</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>{t('noDetailsFound')}</p>
                    )}
                  </StyledTooltipContainer>
                </span>
              }
            >
              <Title>{item.title}</Title>
            </Tooltip>
          ) : (
            <Title>{item.title}</Title>
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CustomTooltip title={item.percentage}>
              <Percentage>{Math.floor(item.percentage) || 0}%</Percentage>
            </CustomTooltip>
            {item.recommendation && (
              <CustomTooltip title={`${t('recommendationToImprove')} ${item.title}`}>
                <EyeFilled
                  style={{
                    marginLeft: '10px',
                    color: '#575d7a',
                    fontSize: ' 16px',
                  }}
                  onClick={() =>
                    setModal({ isModalVisible: true, recommendation: item.recommendation, title: item.title })
                  }
                />
              </CustomTooltip>
            )}
          </div>
        </InfoHeader>
        <InfoDescription>
          {demographicGender ? (
            <DetailWrapper>
              {item?.gender &&
                Object.keys(item?.gender).map((key, i) => (
                  <div style={{ marginRight: '3px', whiteSpace: 'nowrap' }}>
                    <span style={{ fontSize: '13px', fontWeight: 500, color: '#575d7a' }}>{key} </span>
                    <span>{`${item?.gender[key]}%${Object.keys(item?.gender)?.length === i + 1 ? ' ' : ', '}  `}</span>
                  </div>
                ))}
            </DetailWrapper>
          ) : null}
          <DetailWrapper>
            {item?.age &&
              Object.keys(item?.age).map((key, i) => (
                <div style={{ marginRight: '3px', whiteSpace: 'nowrap' }}>
                  <span style={{ fontSize: '13px', fontWeight: 500, color: '#575d7a' }}>{key} </span>
                  <span>{`${item?.age[key]}%${Object.keys(item?.age)?.length === i + 1 ? ' ' : ', '}  `}</span>
                </div>
              ))}
          </DetailWrapper>
          <DetailWrapper>
            {item?.ethinicity &&
              Object.keys(item?.ethinicity).map((key, i) => (
                <div style={{ marginRight: '3px', whiteSpace: 'nowrap' }}>
                  <span style={{ fontSize: '13px', fontWeight: 500, color: '#575d7a' }}>{key} </span>
                  <span>{`${item?.ethinicity[key]}%${
                    Object.keys(item?.ethinicity)?.length === i + 1 ? ' ' : ', '
                  }  `}</span>
                </div>
              ))}
          </DetailWrapper>
        </InfoDescription>
      </Info>
      <RecommendationModal
        {...modal}
        toggleModal={() => setModal({ isModalVisible: false, recommendation: '', title: '' })}
      />
    </>
  );
};

export default InfoContainer;

const StyledTooltipContainer = styled.div`
  margin: 10px;
  padding: 5px 0;
  margin-top: 0;
`;

const StyledTooltipTotal = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
`;

const Info = styled.div`
  max-width: 380px;
  min-width: 300px;
  margin-right: 20px;
  margin-bottom: 40px;
  flex: 1 1 100px;

  @media screen and (max-width: 400px) {
    margin-right: 0;
  }
`;

const InfoHeader = styled.div`
  display: flex;
  align-items: center;
`;

const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: #575d7a;
`;

const InfoDescription = styled.div`
  margin-left: 32px;
  color: #999999;
  font-size: 14px;
`;

const Title = styled.h3`
  color: #575d7a;
  font-weight: 300;
  font-size: 24px;
  margin: 0 0 0 13px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Percentage = styled.h3`
  color: #575d7a;
  font-weight: 600;
  font-size: 24px;
  margin: 0 0 0 13px;
`;
