import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table {
    margin-top: 10px;
    thead {
      tr {
        th {
          white-space: nowrap;
          font-size: 12px;
          letter-spacing: 0;
          font-weight: 300;
          // background: #00c8a0;
          color: #44464e;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    tbody {
      tr {
        td {
          color: #575d7a;
          font-size: 14px;
        }
      }
    }
  }
`;

export const TableColumn = styled.td`
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:last-child {
    cursor: pointer;
  }
`;
