import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const MoreInsightsButton = ({ showIcon = true, ...props }) => {
  return (
    <MoreWrapper {...props}>
      <span style={{ width: 'inherit' }}>{props.text ?? 'More Insights'}</span>
      {showIcon && <ChevronRight />}
    </MoreWrapper>
  );
};

const ChevronRight = styled.i`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 18px;
  height: 18px;
  border: 1.5px solid transparent;
  border-radius: 100px;

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    right: 6px;
    top: 4px;
    color: ${({ theme }) => theme.primary};
  }
`;

const MoreWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.primary};
  font-size: 14px;
  cursor: pointer;

  span {
    margin-right: 5px;
  }

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.primary)};

    ${ChevronRight} {
      &:after {
        color: ${({ theme }) => darken(0.1, theme.primary)};
      }
    }
  }
`;

export default MoreInsightsButton;
