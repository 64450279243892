import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const InviteToJoin = ({ text, isSidebar }) => {
  const invitationDetails = useSelector((store) => store.company.invitationDetails);
  const companyUrl = useSelector((store) => store.company?.profile?.company_url);
  const { t } = useTranslation();
  const url = `${companyUrl}/welcome/e/signup?company_unique_id=${invitationDetails?.company_unique_id}&invitation_password=${invitationDetails?.invitation_password}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <InviteCopyWrapper isSidebar={isSidebar}>
      <div>
        <span style={{ fontWeight: 600 }}>{text}</span> <Url>{url}</Url>
        <CopyButton onClick={copyToClipboard}>{t("copyURL")}</CopyButton>
      </div>
    </InviteCopyWrapper>
  );
};

export default memo(InviteToJoin);

const InviteCopyWrapper = styled.div`
  width: 100%;
  margin: 30px 0;

  span {
    display: block;
  }

  ${({ isSidebar }) =>
    isSidebar &&
    css`
      padding: 0 30px;
      span {
        opacity: 0.7;
        color: white;
      }
    `}
`;

const Url = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CopyButton = styled.div`
  opacity: 1 !important;
  margin-top: 8px;
  display: inline-block;
  padding: 2px 8px !important;
  border: 1px solid #00c8a0;
  border-radius: 5px;
  color: #00c8a0 !important;
  cursor: pointer;

  &:hover {
    opacity: 0.8 !important;
  }
`;
