import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { login } from '../../Actions/auth.actions';
import { getProfile } from '../../Actions/company.actions';

import FormGroup from '../../Components/formGroup';
import Alert from '../../Components/alert';
import Button from '../../Components/button';
import Input from '../../Components/input';
import { useTranslation } from 'react-i18next';
import email from '../../Assets/Icons/Login/email.svg';
import password from '../../Assets/Icons/Login/password.svg';
const LoginForm = (props) => {
  const [state, setState] = useState({
    showError: false,
    errorMessage: '',
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleOnSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      await dispatch(login(props.userType, values, history, props.redirect, props.timeout));
      dispatch(getProfile());
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setState({
        showError: true,
        errorMessage: err.response.data.message,
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email( t("emailIsInvalid")).required( t("emailIsRequired")),
    password: Yup.string().min(6, t("passwordLeast6Characters")).required(t("passwordIsRequired")),
  });

  return (
    <Wrapper>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <FormGroup>
              <Field id="email" name="email">
                {({ field }) => (
                  <Input
                    id="email"
                    name="email"
                    placeholder={t("emailAddress")}
                    type="email"
                    prefix={<img src={email} alt="email" />}
                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage name="email" component="div" className="invalid-feedback" />
            </FormGroup>

            <FormGroup>
              <Field id="password" name="password">
                {({ field }) => (
                  <Input
                    id="password"
                    name="password"
                    placeholder={t("password")}
                    type="password"
                    prefix={<img src={password} alt="password" />}
                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                    {...field}
                  />
                )}
              </Field>
              <ErrorMessage name="password" component="div" className="invalid-feedback" />
            </FormGroup>

            <Button loading={loading} type="primary" htmlType="submit">
            {t("login")}
            </Button>
          </Form>
        )}
      </Formik>
      <div style={{ marginTop: 40 }}>
        <div>{t("forgottenYourPassword")}</div>
        <Link to="/forgotten-password">
          <b style={{ color: '#00c8a0' }}>{t("resetPassword")}</b>
        </Link>
      </div>
      {state.showError && (
        <Alert className="danger" addMargin>
        {t("emailAddressPasswordNotRecognised")} <Link to="/forgotten-password">{t("forgotYourPassword")}</Link>
        </Alert>
      )}
    </Wrapper>
  );
};

LoginForm.propTypes = {
  userType: PropTypes.string,
};

LoginForm.defaultProps = {
  userType: 'patient'
};

const Wrapper = styled.div`
  //max-width: 500px;
  //margin-left: auto;
  //margin-right: auto;
`;

export default LoginForm;
