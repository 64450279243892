import api from '../Util/api';
import { GET_SURVEY_QUESTIONS, GET_SURVEYS, GET_SURVEY_OVERVIEW } from '../Constants/actionTypes';

export const getSurveyQuestions = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .getQuestions()
      .then((res) => {
        dispatch({ type: GET_SURVEY_QUESTIONS, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getSurveys = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .getSurveys()
      .then((res) => {
        dispatch({ type: GET_SURVEYS, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const saveSurvey = (data, surveyId, checked) => (dispatch) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('survey_name', data['survey_name']);
    formData.append('description', data['description']);
    formData.append('send_to', data['send_to']);
    formData.append('subject', data['subject']);
    formData.append('email_body', data['email_body']);
    if (checked) {
      formData.append('schedule_date', data['schedule_date']);
    }
    if (!surveyId && data['logo']) {
      formData.append('logo', data['logo']);
    }
    formData.append('type', data['type']);
    data['question_ids'].forEach((id) => formData.append('question_ids[]', id));

    data['freetext_questions'].forEach((item, i) => {
      formData.append(`custom_questions[${i}][question]`, item.question);
      formData.append(`custom_questions[${i}][type]`, item.type);
    });

    if (surveyId) {
      formData.append('survey_id', Number(window.atob(surveyId)));
      return api.survey
        .edit(formData)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => reject(err));
    }
    return api.survey
      .save(formData)
      .then((res) => {
        // dispatch({ type: SAVE_SURVEY, payload: res.data });
        return resolve(res);
      })
      .catch((err) => reject(err));
  });

export const sendSurvey = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .send(data)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getSurveyOverview = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .getSurveyOverview(data)
      .then((res) => {
        dispatch({ type: GET_SURVEY_OVERVIEW, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getQuestionnaire = (id) => () =>
  new Promise((resolve, reject) =>
    api.survey
      .getQuestionnaire(id)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const submitQuestionnaire = (data) => () =>
  new Promise((resolve, reject) =>
    api.survey
      .submitQuestionnaire(data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const isSurveySubmitted = (data) => () =>
  new Promise((resolve, reject) =>
    api.survey
      .isSurveySubmitted(data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
