import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Line } from 'react-chartjs-2';

import MoreInsights from './moreInsightsButton';
import Dot from './dot';
import tooltipLine from './tooltipLine';
import Loading from './loading';
import { StyledTooltip } from './companyMoodGraph';
import { customChartTooltip } from '../Util/customChartTooltip';
import { useTranslation } from 'react-i18next';

const COLORS = {
  green: {
    border: 'rgb(40,191,141)',
    gradient: ['rgba(66,232,160,0)', 'rgba(66,232,160,0.44)', 'rgba(66,232,160,0.9)'],
  },
  blue: {
    border: 'rgb(39,189,240)',
    gradient: ['rgb(255,255,255)', 'rgb(219,238,255)', 'rgb(182,219,255)'],
  },
};

const ActivityLevelGraph = ({ data, period, isSinglePage = false, previous }) => {
  const [toolTip, setToolTip] = useState({
    top: 0,
    left: 0,
    data: null,
    showTooltip: false,
  });
  const ref = useRef(null);
  const history = useHistory();
  const goTo = () => history.push('/analytics/company-mood');
  const { t } = useTranslation();
  const gradient = useCallback((color) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 300, 0, 0);
    gradient.addColorStop(0.35, COLORS[color].gradient[0]);
    gradient.addColorStop(0.6, COLORS[color].gradient[1]);
    gradient.addColorStop(0.9, COLORS[color].gradient[2]);

    return gradient;
  }, []);

  const setPositionAndData = (payload) => {
    setToolTip((prevState) => ({
      ...prevState,
      top: payload.top,
      left: payload.left,
      data: payload.data,
      showTooltip: true,
    }));
  };

  const hide = () => {
    setToolTip((prevState) => ({
      ...prevState,
      showTooltip: false,
    }));
  };

  const parsedData = useMemo(() => {
    const newData = {
      labels: [],
      datasets: [
        {
          label: 'Current',
          data: [],
          moreData: data,
          fill: true,
          backgroundColor: gradient('green'),
          borderColor: COLORS.green.border,
          borderWidth: 3,
        },
        {
          label: 'Previous',
          data: [],
          moreData: previous,
          fill: true,
          backgroundColor: gradient('blue'),
          borderColor: COLORS.blue.border,
          borderWidth: 3,
        },
      ],
    };
    data &&
      Object.keys(data).forEach((key) => {
        newData.labels.push(key);
        newData.datasets[0].data.push(data[key]);
      });

    previous &&
      Object.keys(previous).forEach((key) => {
        newData.datasets[1].data.push(data[key]);
      });

    return newData;
  }, [data, previous, gradient]);

  const left = toolTip.left + 5;
  const top = toolTip.top - 15;

  const options = useMemo(
    () => ({
      bezierCurve: false,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: 'index',
        intersect: false,
        position: 'average',
        custom: (tooltipModel) => {
          customChartTooltip(tooltipModel, ref, hide, setPositionAndData);
        },
      },
      hover: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              zeroLineColor: '#e3e3e3',
            },
            ticks: {
              padding: 10,
              fontColor: '#8f9092',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              zeroLineColor: 'transparent',
            },
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                if (Math.floor(value) === value) {
                  return value;
                }
              },
              fontColor: '#8f9092',
              padding: 30,
            },
          },
        ],
      },
    }),
    [],
  );

  const xArray = useMemo(() => (data ? Object.keys(data) : []), [data]);
  const currentYear = new Date().getFullYear();

  if (!data) return <Loading />;

  return (
    <ActivityLevelGraphWrapper isSinglePage={isSinglePage}>
      <GraphHistoryWrapper
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}
      >
        {xArray.length && (
          <History>
            <Dot />{' '}
            <HistoryText>
              {xArray[0]}, {currentYear - (period === 'year' ? 1 : 0)} - {xArray[xArray.length - 1]}, {currentYear}
            </HistoryText>
          </History>
        )}
        {previous && (
          <History>
            <Dot color="#26BDF0" /> <HistoryText>{t("previous")}</HistoryText>
          </History>
        )}
      </GraphHistoryWrapper>
      <>
        <Line ref={ref} data={parsedData} options={options} height={isSinglePage ? 100 : 290} plugins={[tooltipLine]} />
        {toolTip.showTooltip ? (
          <StyledTooltip
            style={{
              top,
              left: left + 20,
            }}
          >
            <h5>{t("uniqueLogins")}</h5>
            <div>
              <Dot />
              <span style={{ marginLeft: '10px' }}>{toolTip.data.moreData}</span>
            </div>
          </StyledTooltip>
        ) : null}
      </>
      {!isSinglePage && <MoreInsights style={{ marginTop: 20 }} onClick={goTo} />}
    </ActivityLevelGraphWrapper>
  );
};

const ActivityLevelGraphWrapper = styled.div`
  height: 290px;
  width: 100%;
  margin-bottom: 50px;
`;

const GraphHistoryWrapper = styled.div``;

const History = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const HistoryText = styled.div`
  margin-left: 5px;
`;

export default ActivityLevelGraph;
