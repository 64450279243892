import { Button, Checkbox, DatePicker, Form, Input, Tooltip } from 'antd';
import React from 'react';
import HeaderName from '../../../Components/headerName';
import { ButtonsWrapper, CheckboxText, FormWrapper, TextName, TitleWrapper } from './createSurveyPage';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { convertToRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';

const SurveyFinalStep = ({
  setStep,
  saveLoading,
  draftLoading,
  submitHandler,
  form,
  toggleModal,
  editorState,
  setEditorState,
  date,
  setDate,
  checked,
  setChecked,
}) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  function onOk(value) {
    setDate(value);
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current?.valueOf() < Date.now() - 1 * 24 * 3600 * 1000;
  }

  return (
    <div>
      <TitleWrapper>
        <HeaderName>{t("createHeartbeat")}</HeaderName>
        <Button style={{ height: 32 }} type="primary" size="small" onClick={toggleModal}>
        {t("previewSurvey")}
        </Button>
      </TitleWrapper>
      <FormWrapper>
        <TextName style={{ marginBottom: 10 }}>{t("emailFormat")}</TextName>
        <Form.Item
          name="subject"
          label={t("Subject")}
          rules={[
            { required: true, message:  t("pleaseEnterSubject") },
            { whitespace: true, message: t("subjectCannotBeEmpty") },
          ]}
        >
          <Input name="subject" placeholder={t("enterSubject")} style={{ maxWidth: 700 }} />
        </Form.Item>
        <Checkbox value={checked} style={{ lineHeight: '32px' }} onChange={onChange}>
          <CheckboxText>{t("scheduleSurvey")}</CheckboxText>
        </Checkbox>
        {checked && (
          <Form.Item name="schedule" label={null}>
            <DatePicker
              showTime={{
                format: 'HH:mm',
              }}
              disabledDate={disabledDate}
              onOk={onOk}
              onChange={(e) => {
                if (!e) {
                  setDate(null);
                }
              }}
            />
          </Form.Item>
        )}
        <Form.Item name="email" label={t("email")}>
          <StyledEditorWrapper>
            <Editor
              name="email"
              // toolbar={{ image: { uploadCallback: uploadCallback } }}
              placeholder={t("enterEmail")}
              style={{ maxWidth: 700 }}
              editorState={editorState}
              toolbarClassName="toolbar"
              wrapperClassName="wrapper"
              editorClassName="editor"
              onEditorStateChange={onEditorStateChange}
            />
          </StyledEditorWrapper>
          <span
            style={{
              color: '#28bf8d',
              fontSize: 13,
            }}
          >
            {t("desc")}  
          </span>
          {!convertToRaw(editorState.getCurrentContent()).blocks.some(({ text }) => text) && (
            <div role="alert" style={{ color: '#ff4d4f' }}>
            {t("pleaseEnterEmail")}
            </div>
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <ButtonsWrapper>
              <Button onClick={() => setStep(1)} htmlType="button">
              {t("previous")}
              </Button>
              <Tooltip title={checked && !date ? t("scheduleSurveyChecked") : null}>
                <Button
                  disabled={checked && !date}
                  loading={draftLoading}
                  htmlType="button"
                  onClick={() => submitHandler(form.getFieldsValue(), 'draft')}
                >
                 {t("saveasDraft")}
                </Button>
              </Tooltip>
              <Tooltip title={checked && !date ? t("scheduleSurveyChecked") : null}>
                <Button
                  disabled={checked && !date}
                  loading={saveLoading}
                  htmlType="submit"
                  style={{ height: 32 }}
                  type="primary"
                  size="small"
                >
                  {t("saveSend")}
                </Button>
              </Tooltip>
            </ButtonsWrapper>
          </div>
        </Form.Item>
      </FormWrapper>
    </div>
  );
};

export default SurveyFinalStep;

export const StyledEditorWrapper = styled.div`
  div {
    box-sizing: unset;
  }
  .wrapper {
    background: white;
    margin-bottom: 20px;
    overflow: hidden;
    width: 900px;
    .rdw-link-modal-target-option {
      display: none;
    }
    .rdw-image-modal-upload-option-label {
      font-size: 14px;
      height: 50px;
    }
  }

  .editor {
    min-height: 400px;
  }
  .public-DraftEditorPlaceholder-inner {
    opacity: 0.5;
  }
`;
