import { SIDE_BAR_TOGGLE, SET_MOBILE_VIEW_STATE, SET_TABLET_VIEW_STATE } from '../Constants/actionTypes';

const initialState = {
  isSideBarOpened: true,
  isMobileView: false,
  isTabletView: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SIDE_BAR_TOGGLE:
      return {
        ...state,
        isSideBarOpened: !state.isSideBarOpened,
      };
    case SET_MOBILE_VIEW_STATE:
      return {
        ...state,
        isMobileView: action.payload,
      };
    case SET_TABLET_VIEW_STATE:
      return {
        ...state,
        isTabletView: action.payload,
      };
    default:
      return state;
  }
}
