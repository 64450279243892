import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';

import { ReactComponent as IncreaseIcon } from '../../Assets/Icons/inavtivityIncreaseIcon.svg';

const MiniBoxComponent = ({ value, headerText, percentage = null, children }) => {
  const { t } = useTranslation();
  return (
    <MiniBox width="22.5%" height="200px" minWidth={'210px'}>
      <HeaderName>{headerText}</HeaderName>
      {value !== undefined && value !== null ? (
        <MiniBoxValueWrapper>
          {!children ? <MiniBoxValue>{value}</MiniBoxValue> : children}
          {percentage && (
            <MiniBoxPercentage>
              <InactivityIncreaseIcon as={IncreaseIcon} />
              <span>+25% {t("increase")}</span>
            </MiniBoxPercentage>
          )}
        </MiniBoxValueWrapper>
      ) : (
        <Skeleton active size="small" paragraph={{ rows: 2 }} />
      )}
    </MiniBox>
  );
};

export const Box = styled.div`
  width: ${({ width }) => width || 'initial'};
  height: ${({ height }) => height || 'initial'};

  min-width: ${({ minWidth }) => minWidth || 'initial'};
  max-width: ${({ maxWidth }) => maxWidth || 'initial'};

  background: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  padding: 40px;

  @media screen and (max-width: 700px) {
    padding: 25px;
  }
`;

const MiniBox = styled(Box)`
  position: relative;
  padding: 32px;
  @media screen and (max-width: 890px) {
    width: 48.5%;
    max-width: none;
    min-width: unset;

    &:first-child {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 530px) {
    width: 100%;
    max-width: none;
    min-width: unset;

    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const HeaderName = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const MiniBoxValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  position: absolute;
  left: 50%;
  top: 65%;
  transform: translateX(-50%) translateY(-65%);
`;

const MiniBoxValue = styled.div`
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: #1a5549;
`;

const MiniBoxPercentage = styled.div`
  width: 120px;
  font-weight: 400;
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  top: 125%;
  transform: translateX(-50%) translateY(-125%);
`;

const InactivityIncreaseIcon = styled.svg`
  margin-right: 5px;
`;

MiniBoxComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(MiniBoxComponent);
