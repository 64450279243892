import React, { memo, useRef, useState } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Table, notification, Typography, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import moment from 'moment';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import MoreInsightsButton from '../../../Components/moreInsightsButton';
import { useDispatch, useSelector } from 'react-redux';
import StatusChangePopup from './StatusChangePopup';
import api from '../../../Util/api';
import Loading from '../../../Components/loading';
import { getSurveyOverview } from '../../../Actions/survey.actions';
import CustomTooltip from '../../../Components/customTooltip';
import { useTranslation } from 'react-i18next';
import csvToXls from '../../../Util/csvToXls';
import { CSVLink } from 'react-csv';

const { Text } = Typography;

const TableCard = () => {
  const data = useSelector((store) => store?.survey?.overview);
  const surveys = data?.surveys;
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState({ status: false, value: '' });

  const [exportData, setExportData] = useState({ data: [], name: "" });
  const csvLink = useRef();

  const onChange = (id) => {
    setConfirm({ status: true, value: id });
  };
  const onCancel = () => {
    setConfirm({ ...confirm, status: false });
  };

  const onConfirm = () => {
    const changeStatus = async () => {
      try {
        setLoading(true);
        await api.survey.changeSurveyStatus({ survey_id: confirm.value, status: 'Pending' }).then(() => {
          dispatch(getSurveyOverview({ period: 'year' })).finally(() => {
            notification.open({
              message: t('surveySentSuccessfully'),
              icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
            setLoading(false);
          });
        });
      } catch {
        setLoading(false);
      }
    };
    changeStatus();
    setConfirm({ ...confirm, status: false });
  };

  const exportHeartbeartData = async (data) => {
    try {
      setLoading(true);

      await api.survey
        .exportSurvey(data?.id)
        .then((r) => setExportData({ data: r.data, name: data?.name }))
        .catch((e) => console.log(e));
      csvLink.current.link.click();
    } catch (error) {
      notification.open({
        message: 'Something went wrong',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      width: '19%',
      render: (_, data) => (
        <div>
          {data.status === 'Draft' ? (
            <CustomTooltip
              placement="top"
              title={
                <span>
                  {t('editSurvey')} - {data.name}
                </span>
              }
            >
              <MoreInsightsButton
                style={{ width: '100%' }}
                showIcon={false}
                text={
                  <Text style={{ width: '90%', color: '#575d7a' }} ellipsis={{ tooltip: false }}>
                    {data.name}
                  </Text>
                }
                onClick={() => history.push(`/survey/edit/?id=${data.hash}`)}
              />
            </CustomTooltip>
          ) : (
            <span
              style={{
                whiteSpace: 'nowrap',
                color: '#575d7a',
                fontSize: '14px',
              }}
            >
              <Text style={{ width: '90%', color: '#575d7a' }} ellipsis={{ tooltip: data.name }}>
                {data.name}
              </Text>
            </span>
          )}
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('dateSent'),
      dataIndex: 'sent_at',
      key: 'sent_at',
      width: '14%',
      sorter: (a, b) => moment(a.sent_at).unix() - moment(b.sent_at).unix(),
    },
    {
      title: t('department'),
      dataIndex: 'department',
      key: 'department',
      width: '15%',
      sorter: (a, b) => a.department.localeCompare(b.department),
      render: (_, data) => (
        <Text style={{ width: '90%', color: '#575d7a' }} ellipsis={{ tooltip: data.department }}>
          {data.department}
        </Text>
      ),
    },
    {
      title: t('responses'),
      dataIndex: 'total_responses',
      key: 'total_responses',
      width: '10%',
      render: (_, data) => (
        <div style={{ color: '#575d7a' }}>
          <CustomTooltip
            placement="top"
            title={
              <span>
                {t('outOf')} {data.sent_to}, {data.total_responses}{' '}
                {data.total_responses > 1 ? t('employees') : t('employee')}{' '}
                {data.total_responses > 1 ? t('have') : t('has')} {t('respondedToThisSurvey')}
              </span>
            }
          >
            {`${data.total_responses}/${data.sent_to}`}
          </CustomTooltip>
        </div>
      ),
      sorter: (a, b) => a.total_responses - b.total_responses,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (_, data) => data.status,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: t('preview'),
      dataIndex: 'details',
      key: 'details',
      width: '9%',
      render: (_, data) => (
        <div>
          <CustomTooltip placement="top" title={<span>{t('previewSurvey')}</span>}>
            <MoreInsightsButton
              showIcon={false}
              text="Preview"
              onClick={() => history.push(`/preview/${window.btoa(data.id)}`)}
            />
          </CustomTooltip>
        </div>
      ),
    },
    {
      title: t('details'),
      dataIndex: 'details',
      key: 'details',
      width: '11%',
      render: (_, data) => (
        <div style={{ opacity: !data.total_responses ? 0.5 : 1 }}>
          <CustomTooltip
            placement="top"
            title={<span>{data.total_responses ? t('moreInsightForThisSurvey') : t('cannotAccessThisPage')}</span>}
          >
            <MoreInsightsButton
              showIcon={false}
              onClick={() => data.total_responses && history.push(`/survey/insights/${data.id}`)}
            />
          </CustomTooltip>
        </div>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '14%',
      render: (_, data) =>
        data.status === 'Draft' ? (
          <Button
            onClick={() => {
              onChange(data.id);
            }}
            type="primary"
          >
            {t('sendSurvey')}
          </Button>
        ) : data.status === 'Pending' ? (
          <div>
            <Button
              onClick={() => {
                exportHeartbeartData(data);
              }}
              type="primary"
            >
              {t('export')}
            </Button>
            <CSVLink data={exportData?.data} filename={exportData?.name} className="hidden" ref={csvLink} target="_blank" />
          </div>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <FullCard>
      <CardHeader noFilters name={t('heartbeatOverview')} toolTipText={t('heartbeatOverviewTooltip')} />
      <Wrapper>
        {loading && (
          <HoverWrapper>
            <Loading />
          </HoverWrapper>
        )}
        <StyledTable
          loading={surveys === null}
          dataSource={surveys || []}
          columns={columns}
          scroll={{ y: 410, x: 900 }}
          pagination={false}
          rowKey="id"
        />
        <StatusChangePopup confirm={confirm.status} value={confirm.value} onCancel={onCancel} onConfirm={onConfirm} />
      </Wrapper>
    </FullCard>
  );
};

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTable = styled(Table)`
  .ant-table {
    .ant-table-body {
      min-height: 210px;
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          padding: 5px 16px;
          text-align: left;
          letter-spacing: 0;
          // background: #00c8a0;
          color: #44464e;
          font-size: 14px;
          font-weight: 500;
          white-space: break-spaces;

          .ant-table-column-sorters {
            span {
              white-space: break-spaces;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          white-space: nowrap;
          padding: 14px 16px;
          color: #575d7a;
          font-size: 14px;
        }
      }
    }
  }
`;

export default memo(TableCard);
