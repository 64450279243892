import React, { memo, useMemo } from 'react';
import { Line } from 'react-chartjs-2';

const COLORS = {
  green: {
    border: 'rgb(40,191,141)',
    gradient: ['rgb(255,255,255)', 'rgb(217,248,235)', 'rgb(152,235,199)'],
  },
  blue: {
    border: 'rgb(39,189,240)',
    gradient: ['rgb(255,255,255)', 'rgb(219,238,255)', 'rgb(182,219,255)'],
  },
  yellow: {
    border: 'rgb(246,184,110)',
    gradient: ['rgb(255,255,255)', 'rgb(255,239,209)', 'rgb(255,228,131)'],
  },
};

const options = {
  bezierCurve: false,
  legend: {
    display: false,
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0,
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
};

const SmallLineChart = ({ data, themeColor }) => {
  const gradient = useMemo(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 100, 0, 0);
    gradient.addColorStop(0.55, COLORS[themeColor].gradient[0]);
    gradient.addColorStop(0.8, COLORS[themeColor].gradient[1]);
    gradient.addColorStop(1, COLORS[themeColor].gradient[2]);

    return gradient;
  }, [themeColor]);

  const dataSet = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: '# of Votes',
        data: data,
        fill: true,
        backgroundColor: gradient,
        borderColor: COLORS[themeColor].border,
        borderWidth: 1,
      },
    ],
  };

  return <Line type="line" data={dataSet} options={options} width={150} height={40} />;
};

export default memo(SmallLineChart);
