import React, { useEffect } from 'react';

import EmployeeCountComponent from '../Dashboard/employeeCountComponent';

import { Box } from '../Dashboard/miniBox';
import { useDispatch } from 'react-redux';
import { getOnlyEmployeeCount } from '../../Actions/company.actions';

const EmployeeCount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOnlyEmployeeCount());
  }, [dispatch]);

  return (
    <Box style={{ width: '100%', height: 400, marginBottom: 20 }}>
      <EmployeeCountComponent singleView />
    </Box>
  );
};

export default EmployeeCount;
