import { message } from 'antd';
import axios from 'axios';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from '../../../Components/loading';
import NewStyledSelect from '../../../SharedStyles/newStyledSelect';
import copyToClipboard from '../../../Util/clipboard';
import { useTranslation } from 'react-i18next';

const ExportTextAnalytics = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const key = 'copyToClipboardLoading';
  const successKey = 'copyToClipboardSuccess';
  const newCopyToClipboard = async (value, setValue) => {
    message.destroy(key);
    message.destroy(successKey);
    const loadingTimer = setTimeout(() => {
      message.loading({ content: t("copyingToClipboardPleaseWait"), key });
    }, 350);
    setIsLoading(true);
    const chartElement = document.getElementById('text-analytics-keywords');
    chartElement.style.display = 'none';
    const previousChartElement = document.getElementById('previous-text-analytics-keywords');
    if (previousChartElement) {
      previousChartElement.style.display = 'none';
    }
    const exportChartElement = document.getElementById('exportTextAnalyticsCard');
    exportChartElement.style.display = 'block';
    const previousExportChartElement = document.getElementById('previousExportTextAnalyticsCard');
    if (previousExportChartElement) {
      previousExportChartElement.style.display = 'block';
    }

    const exportElement = document.getElementsByClassName(value);

    console.log(exportElement, 'exportElement');
    const exportElementArr = [];
    for (let elem of exportElement) {
      exportElementArr.push(html2canvas(elem, { scale: 2 }));
    }
    const response = await axios.all(exportElementArr);
    console.log(response, 'response');

    for (let elem of response) {
      let imgData = elem.toDataURL('image/png', 1.0);
      try {
        await copyImageToClipboard(imgData);
        setIsLoading(false);

        chartElement.style.display = 'block';
        exportChartElement.style.display = 'none';
        if (previousChartElement && previousExportChartElement) {
          previousChartElement.style.display = 'block';
          previousExportChartElement.style.display = 'none';
        }
        message.destroy(key);
        clearTimeout(loadingTimer);
        setTimeout(() => {
          message.success({ content: t("imageCopiedToClipboard"), key: successKey });
          setValue()
        }, 350);
      } catch (e) {
        alert(e, 'error');
      }
    }
  };

  const handleChange = (value, setValue) => {
    if (value === 'copyFullImage') {
      newCopyToClipboard(value, setValue);
    } else if (value === 'copyChartElement') {
      const chartElement = document.getElementById('text-analytics-keywords');
      chartElement.style.display = 'none';
      const previousChartElement = document.getElementById('previous-text-analytics-keywords');
      if (previousChartElement) {
        previousChartElement.style.display = 'none';
      }
      const exportChartElement = document.getElementById('exportTextAnalyticsCard');
      exportChartElement.style.display = 'block';
      const previousExportChartElement = document.getElementById('previousExportTextAnalyticsCard');
      if (previousExportChartElement) {
        previousExportChartElement.style.display = 'block';
      }
      copyToClipboard(value, setValue);
      exportChartElement.style.display = 'none';
      if (previousExportChartElement && previousChartElement) {
        previousExportChartElement.style.display = 'none';
        previousChartElement.style.display = 'block';
      }
      chartElement.style.display = 'block';
    } else {
      copyToClipboard(value, setValue);
    }
  };

  return (
    <>
      {isLoading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      <NewStyledSelect onChange={handleChange} isRequired />
    </>
  );
};

export default ExportTextAnalytics;

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
