import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RecommendationModal = ({ isModalVisible, toggleModal, recommendation, title = '' }) => {
  const { t } = useTranslation();

  return (
    <Modal
      bodyStyle={{ padding: 20, minHeight: '30vh' }}
      width="50%"
      title={`${t("recommendationToImprove")} ${title}`}
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={null}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: recommendation,
        }}
      />
    </Modal>
  );
};

export default RecommendationModal;
