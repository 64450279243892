import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useWindowSize } from '../Hooks';

const handleBreadCrumb = (paths, id, urlLocation,t) => {
  if (paths[id]) {
    return paths[id];
  }
  return paths[urlLocation?.split('/')[2]] || '';
};

const LocationPath = ({
  paths,
  multi = false,
  pathName = '',
  toolTip = null,
  isInsights = false,
  isFreeTextAnalytics = false,
  surveyName = '',
  styles = {},
  customPath = [],
}) => {
  const urlData = useParams();
  const urlLocation = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const viewportWidth = useWindowSize()?.width;

  const goBack = () => history.goBack();

  const isDesktop = useMemo(() => viewportWidth > 450, [viewportWidth]);

  if (!isDesktop) {
    return (
      <LocationPathWrapper style={styles}>
        <ArrowLeftOutlined style={{ color: '#75D9B6', fontSize: '22px', marginRight: 10 }} onClick={goBack} />
        <span>
          <span style={{ fontWeight: 600 }}>
            {!isInsights && !isFreeTextAnalytics && paths(t)[urlData.id]}
            {console.log('urlData: ', urlData)}
            {isInsights && `${t("heartbeatInsights")} - ${surveyName}`}
            {isFreeTextAnalytics && `${t("freetextAnalytics")}`}
          </span>
        </span>
      </LocationPathWrapper>
    );
  }

  if (multi) {
    return (
      <LocationPathWrapper style={styles}>
        <ArrowLeftOutlined style={{ color: '#75D9B6', fontSize: '22px', marginRight: 10 }} onClick={goBack} />
        <span>
          <span onClick={goBack}>{t("supportRoom")}</span> /{' '}
          {customPath.map(({ pathName, pathLink }) => {
            return (
              <React.Fragment key={pathName}>
                {pathLink ? <StyledLink to={pathLink}>{pathName}</StyledLink> : pathName}
                {' / '}
              </React.Fragment>
            );
          })}
          <span style={{ fontWeight: 600 }}>
            {!isInsights && !isFreeTextAnalytics && handleBreadCrumb(paths(t), urlData.id, urlLocation?.pathname)}
            {isInsights && `${t("heartbeatInsights")} - ${surveyName}`}
            {isFreeTextAnalytics && `${t("freetextAnalytics")}`}
          </span>
        </span>
      </LocationPathWrapper>
    );
  }

  return (
    <LocationPathWrapper style={styles}>
      <div style={{ marginBottom: 20 }}>{t("supportRoom")} - {pathName}</div>
      <StyledH2>
        {pathName}
        {toolTip}
      </StyledH2>
    </LocationPathWrapper>
  );
};

const LocationPathWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const StyledH2 = styled.h2`
  color: #491f69;
  font-size: 24px;
`;

const StyledLink = styled(Link)`
  color: #000000 !important;
  text-decoration: none;
`;

export default LocationPath;
