import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as ArrowUpIcon } from '../Assets/Icons/ic-arrow-narrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../Assets/Icons/ic-arrow-narrow-down.svg';

const PercentageStatus = ({ percentage, isIncrease, period }) => {
  const { t } = useTranslation();
  if (percentage === null) return <PercentageWrapper style={{ height: 22.4 }} />;
  return (
    <PercentageWrapper>
      <Percentage>
        <span style={{ color: isIncrease ? '#28BF8D' : '#EB7D86' }}>
          {percentage ? Number(percentage).toFixed(1) : 0}%
        </span>
        {isIncrease ? <Icon as={ArrowUpIcon} /> : <Icon as={ArrowDownIcon} />}
      </Percentage>
      <DateText>{period == "week" ? t("thanLastWeek") : period =="month" ?t("thanLastMonth") : t("thanLastYear")} </DateText>
    </PercentageWrapper>
  );
};

const Icon = styled.svg`
  width: 15px;
`;

const PercentageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Percentage = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const DateText = styled.div`
  color: ${({ theme }) => theme.greyMedium};
  font-size: 12px;
`;

export default PercentageStatus;
