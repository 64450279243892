import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ZoomableGroup, ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import countries from 'i18n-iso-countries';
import "./styles.css";
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

// const geoUrl = 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';
// const geoUrl = ' https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';

const rounded = (num) => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + 'Bn';
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + 'M';
  } else {
    return Math.round(num / 100) / 10 + 'K';
  }
};

const defaultStylesForControls = { fontSize: '24px', color: 'grey', cursor: 'pointer' };

const colorScale = scaleLinear().domain([1, 100]).range(['#b5cfff', '#0048ff']);

const MapChart = ({ setTooltipContent, data: dataToShow }) => {
  const [data, setData] = useState([]);
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  };

  const handleMoveEnd = (position) => {
    setPosition(position);
  };

  useEffect(() => {
    const parsedData = dataToShow?.map((country) => ({
      ISO3: countries.getAlpha3Code(country.country_name, 'en'),
      percentage: country.percentage,
    }));
    setData(parsedData);
  }, [dataToShow]);

  return (
    <Wrapper>
      <ComposableMap data-tip="" projectionConfig={{ scale: 180 }}>
        <ZoomableGroup zoom={position.zoom} center={position.coordinates} onMoveEnd={handleMoveEnd}>
          <Geographies geography="/features.json">
            {({ geographies }) =>
              geographies.map((geo) => {
                const d = data?.find((s) => s.ISO3 === geo.properties.ISO_A3);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={d ? colorScale(d.percentage) : '#D6D6DA'}
                    onMouseEnter={() => {
                      const { NAME, POP_EST } = geo.properties;
                      setTooltipContent && setTooltipContent(`${NAME} — ${rounded(POP_EST)}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent && setTooltipContent('');
                    }}
                    style={{
                      default: {
                        outline: 'none',
                      },
                      hover: {
                        fill: '#28BF8D',
                        outline: 'none',
                      },
                      pressed: {
                        fill: '#28BF8D',
                        outline: 'none',
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ControlsWrapper>
        <PlusSquareOutlined style={defaultStylesForControls} onClick={handleZoomIn} />
        <MinusSquareOutlined style={defaultStylesForControls} onClick={handleZoomOut} />
      </ControlsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 40%;
  min-width: 300px;

  @media screen and (max-width: 820px) {
    width: 100%;
    min-width: 200px;
  }
`;
const ControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  bottom: 40px;
  left: 20px;
`;

export default MapChart;
