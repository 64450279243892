import React from 'react';

import { InfoHeader, InfoHeaderWrapper, Title } from './fullCard';
import Square from './square';
import colorPalette from '../Util/colorPalette';
import CustomTooltip from './customTooltip';
import { useTranslation } from 'react-i18next';

const CardInfoHeader = ({ data, count, responses, responseMessage }) => {
  const { t } = useTranslation();

  const sumOfResponses = (arr) => {
    const sum = arr.reduce((item, object) => {
      return item + object.count;
    }, 0);
    return sum;
  };

  return (
    <InfoHeaderWrapper>
      {data.map((title, index) => {
        return (
          <CustomTooltip
            title={`${t("received")} ${
              Object.values(responses?.[title]) ? sumOfResponses(Object.values(responses?.[title])) : t('no')
            } ${t("responseFromATotalOf")} ${count?.[title]} ${responseMessage} ${title}, ${t("tillCurrentDate")}`}
          >
            <InfoHeader key={title}>
              <Square size={19} color={colorPalette[index]} />
              <Title>{title}</Title>
            </InfoHeader>
          </CustomTooltip>
        );
      })}
    </InfoHeaderWrapper>
  );
};

export default CardInfoHeader;
