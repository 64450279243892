import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { mapStorageToRedux } from './Actions/auth.actions';
import Loader from './Components/loader';

import Routes from './Routes';

import { CommonTheme } from './Styles/theme';

const App = () => {
  const loggedIn = useSelector((store) => store.auth.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const authLocalStorageInfo = localStorage.getItem('auth');
    if (authLocalStorageInfo && !loggedIn) {
      dispatch(mapStorageToRedux());
    }
  }, [loggedIn, dispatch]);

  return (
    <ThemeProvider theme={CommonTheme}>
      <BrowserRouter>
        <Routes />
        <Loader />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
