import React, { useEffect, useState } from 'react';
import HeaderName from '../../Components/headerName';
import styled from 'styled-components';
import Loading from '../../Components/loading';
import BenefitsForm from './BenefitsForm';
import BenefitsPreview from './BenefitsPreview';
import api from '../../Util/api';
import { useTranslation } from 'react-i18next';

const EmployeeBenefits = () => {
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [list, setList] = useState([]);
  const { t } = useTranslation();

  const getData = async () => {
    setLoading(true);
    const response = await api.survey.viewBenefits();
    setLoading(false);
    setList(response?.data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      <HeaderName>{t("dashboard")}</HeaderName>
      <ContainerWrapper>
        <BenefitsForm editData={editData} key={editData?.benefit_id} getData={getData}/> 
        <BenefitsPreview updateCard={setEditData} list={list} getData={getData} />
      </ContainerWrapper>
    </>
  );
};

export default EmployeeBenefits;

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
