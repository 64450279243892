import React from 'react';
import styled from 'styled-components';

const Dot = ({ size = 9, color }) => {
  return <DotStyled size={size} color={color} />;
};

const DotStyled = styled.div`
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background: ${({ theme, color }) => (color ? color : theme.primary)} 0 0 no-repeat padding-box;
  border-radius: 50%;
`;

export default Dot;
