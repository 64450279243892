import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HeaderName from '../../Components/headerName';
import Loading from '../../Components/loading';
import LeaderCard from '../../Components/leaderCard';
import { updateStatus } from './LeaderData';

const LeadersHub = ({ isNeeded = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileData = useSelector((store) => store.company?.profile);

  const [loading, setLoading] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [addonsList, setAddonsList] = useState([]);


  useEffect(() => {
    const updatedArr = updateStatus(profileData);
    setAddonsList(updatedArr);
  }, [profileData]);

  // useEffect(() => {
  //   setLoading(true);
  // }, []);

  if (loading) return <Loading />;

  return (
    <div>
      {loadingDashboard && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      <HeaderWrapper>
        <HeaderName>{t('leadersHub')}</HeaderName>
      </HeaderWrapper>

      <div>
        <Section>
          {addonsList.map((item, index) => (
            <LeaderCard key={index} data={item} />
          ))}
        </Section>
      </div>
    </div>
  );
};

const HoverWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Card = styled.div`
  background: white;
  min-width: 260px;

  border-radius: 5px;
  padding: 22px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 4px;

  margin-bottom: 35px;

  @media screen and (max-width: 840px) {
    width: 100% !important;
    margin-bottom: 15px;
  }
`;

const DoubleCardLayout = styled(Card)`
  width: 48.3%;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;

export default memo(LeadersHub);
