import React from 'react';
import styled from 'styled-components';

import { Table } from 'antd';
import Flag from 'react-world-flags';

import countries from 'i18n-iso-countries';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const MapMiniTable = ({ data, isRequired = false }) => {
  const columns = [
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      width: '25%',
      render: (text) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: 30,
              minWidth: 30,
              borderRadius: '50%',
              overflow: 'hidden',
              marginRight: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Flag style={{ width: 35, height: 30, objectFit: 'cover' }} code={countries.getAlpha2Code(text, 'en')} />
          </div>
          {text}
        </div>
      ),
    },
    {
      title: 'Total Logins',
      dataIndex: 'total_logins',
      key: 'total_logins',
      width: '20%',
    },
    {
      title: 'Male',
      dataIndex: 'male',
      key: 'male_logins',
      width: '10%',
    },
    {
      title: 'Female',
      dataIndex: 'female',
      key: 'female_logins',
      width: '12%',
    },
    {
      title: 'Others',
      dataIndex: 'other',
      key: 'others_logins',
      width: '12%',
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      width: '21%',
    },
  ];

  return (
    <Wrapper>
      <StyledTable dataSource={data} columns={columns} scroll={!isRequired && { y: 230, x: 480 }} pagination={false} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 54%;

  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  .ant-table {
    thead {
      tr {
        th {
          // padding: 5px 16px;
          white-space: nowrap;
          background: transparent;
          font-size: 12px;
          text-align: left;
          letter-spacing: 0;
          font-weight: 300;
          color: #6e748b;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 14px 16px;
          color: #575d7a;
          font-size: 14px;
        }
      }
    }
  }
`;

export default MapMiniTable;
