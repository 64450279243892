import React, { memo } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import Modal from '../../../Components/Modal';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const StatusChangePopup = ({ confirm, onCancel, onConfirm, value }) => {
  const { t } = useTranslation();

  if (!confirm) return null;
  return (
    <>
      <Modal open={true} closeModal={() => null} showCloseButton={false} contentStyle={{ width: '50%' }}>
        <div style={{ textAlign: 'center' }}>
          <Wrapper>
            <TherapyFeedbackWrapper>
              <SubmitFeedbackWrapper>
                <h2 style={{ color: '#575d7a' }}>{ t("areYouSureYouWantToSendThisSurvey")}</h2>
                <Formik onSubmit={onConfirm}>
                  {() => (
                    <Form onChange={() => {}}>
                      <ButtonWrapper>
                        <StyledButton variant="secondary" type="button" onClick={onCancel}>
                        {t("cancel")}
                        </StyledButton>
                        <StyledButton variant="secondary" type="button" onClick={onConfirm}>
                        {t("submit")}
                        </StyledButton>
                      </ButtonWrapper>
                    </Form>
                  )}
                </Formik>
              </SubmitFeedbackWrapper>
            </TherapyFeedbackWrapper>
          </Wrapper>
        </div>
      </Modal>
    </>
  );
};

const StyledButton = styled(Button)`
  width: 30%;
  border-radius: 5px;
  color: #575d7a;
`;

const Wrapper = styled.div`
  text-align: center;
  margin: auto;
  max-width: 750px;
  width: 100%;
`;

const TherapyFeedbackWrapper = styled.div`
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  form {
    text-align: left;
    width: 100%;
  }
`;

const SubmitFeedbackWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  label {
    font-weight: 400;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
  display: flex;
  justify-content: space-evenly;
`;

export default memo(StatusChangePopup);
