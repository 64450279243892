import { message } from 'antd';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import axios from 'axios';

const key = 'copyToClipboardLoading';
const successKey = 'copyToClipboardSuccess';
const copyToClipboard = async (newClass, setValue) => {
  message.destroy(key);
  message.destroy(successKey);
  const loadingTimer = setTimeout(() => {
    message.loading({ content: 'Copying to clipboard. Please wait', key });
  }, 350);
  const exportElement = document.getElementsByClassName(newClass);
  console.log(exportElement, 'exportElement');
  const exportElementArr = [];
  for (let elem of exportElement) {
    exportElementArr.push(html2canvas(elem, { scale: 2 }));
  }
  const response = await axios.all(exportElementArr);

  for (let elem of response) {
    let imgData = elem.toDataURL('image/png', 1.0);
    try {
      await copyImageToClipboard(imgData);
      message.destroy(key);
      clearTimeout(loadingTimer);
      setTimeout(() => {
        message.success({ content: 'Image Copied to clipboard', key: successKey });
        setValue()
      }, 350);
    } catch (e) {
      alert(e, 'error');
    }
  }
};

export default copyToClipboard;
