import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AuthLayout } from '../../Layout';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../Actions/auth.actions';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const { t } = useTranslation();
  localStorage.removeItem('auth');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
    localStorage.removeItem('auth');
  }, [dispatch]);
  return (
    <AuthLayout>
      <Wrapper>
        <h1>{t("youAreLoggedOut")}</h1>
        <Link to="/login">{t("returnToLogin")}</Link>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
export default Logout;
