import React from 'react';

import styled from 'styled-components';

import { ContentWrapper, FullWrapper, InfoHeader, InfoHeaderWrapper, Title } from './fullCard';
import Square from './square';
import { colorTagPalette } from '../Util/colorPalette';
import CustomTooltip from './customTooltip';

const PercentageBarWithDescription = ({ data }) => {
  const headerOptions = data && data?.questions?.length > 0 ? Object.keys(data?.questions[0]?.details) : [];

  return (
    <>
      <InfoHeaderWrapper>
        {headerOptions.map((item, index) => (
          <InfoHeader key={item}>
            <Square size={19} color={colorTagPalette[index]} />
            <Title>{item}</Title>
          </InfoHeader>
        ))}
      </InfoHeaderWrapper>
      <ContentWrapper>
        <FullWrapper>
          <PercentageTableWrapper>
            {data?.questions.map((q) => (
              <Row key={q.question_tag}>
                <QuestionWrapper>
                  <QuestionNumber>
                    <CustomTooltip title="Total response">{q.count}</CustomTooltip>
                  </QuestionNumber>
                  <QuestionText>{q.question_tag}</QuestionText>
                </QuestionWrapper>
                <PercentageRow isEmpty={false}>
                  {Object.keys(q.details).map((key, index) => (
                    <CustomTooltip title={q.details[key]}>
                      <Percent key={key} value={q.details[key]} color={colorTagPalette[index]}>
                        {Math.round(q.details[key])}%
                      </Percent>
                    </CustomTooltip>
                  ))}
                </PercentageRow>
              </Row>
            ))}
          </PercentageTableWrapper>
        </FullWrapper>
      </ContentWrapper>
    </>
  );
};

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  padding-right: 30px;

  @media screen and (max-width: 820px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
const QuestionNumber = styled.div`
  width: 52px;
  font-size: 30px;
  font-weight: bold;
  color: #575d7a;
  margin-right: 18px;
  text-align: right;

  @media screen and (max-width: 820px) {
    text-align: left;
  }
`;
const QuestionText = styled.div`
  font-size: 16px;
  color: #131523;
  width: calc(100% - 55px);
  padding-left: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
`;

const PercentageTableWrapper = styled.div`
  width: 100%;
`;

const PercentageRow = styled.div`
  width: calc(100% - 400px);
  display: flex;
  background: ${({ isEmpty }) => (isEmpty ? '#e5e5e5' : 'white')};
  height: 30px;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;

const Percent = styled.div`
  width: calc(${({ value }) => value}% - 2px);
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  overflow: hidden;
`;

export default PercentageBarWithDescription;
