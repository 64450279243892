export const customChartTooltip = (tooltipModel, ref, hide, setPositionAndData) => {
  // hide the tooltip
  let hit = ref.current?.chartInstance?.chart?.tooltip._active[0]?.inRange(
    ref.current?.chartInstance?.chart?.tooltip._eventPosition?.x,
    ref.current?.chartInstance?.chart?.tooltip._eventPosition?.y,
  );
  if (tooltipModel.opacity === 0 || hit === undefined) {
    hide();
    return;
  }

  const position = ref.current?.chartInstance?.chart?.canvas.getBoundingClientRect();

  if (position) {
    const { width } = position;
    const yAlign = tooltipModel.yAlign;
    const xAlign = tooltipModel.xAlign;

    // Carets
    const caretY = tooltipModel.caretY;
    const caretX = tooltipModel.caretX;

    // Final coordinates
    let top = position.top + caretY - 100;
    let left = position.left + caretX;
    let space = 65;

    if (yAlign === 'top') {
      top += 100;
    } else if (yAlign === 'center') {
      top = top + 10;
    } else if (yAlign === 'bottom') {
      top -= space;
    }

    if (xAlign === 'right') {
      if (width < 400) {
        left -= 190;
      } else left -= 190;
    } else if (xAlign === 'center') {
      if (width < 400) left -= 190;
    }

    setPositionAndData({
      top,
      left,
      data: {
        key: tooltipModel?.title?.[0],
        moreData: ref.current?.chartInstance?.config?.data?.datasets?.[0]?.moreData?.[tooltipModel?.title?.[0]],
      },
    });
  }
};
