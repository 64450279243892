import { Popconfirm } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createSingleManager, getManagerList, revokeManagerAccess } from '../../Actions/company.actions';
import { TableColumn } from '../../SharedStyles';
import ManagerView from './managerView';
import { useTranslation } from 'react-i18next';

const ManagerUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const revokeAccessAction = (id) => dispatch(revokeManagerAccess(String(id)));

  const columns = [
    {
      title: t("name"),
      dataIndex: 'first_name',
      key: 'first_name',
      width: '33.33%',
      render: (_, data) => data.first_name + ' ' + data.last_name,
      sorter: (a, b) => (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name),
    },
    {
      title: t("Email"),
      dataIndex: 'email',
      key: 'email',
      width: '33.33%',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title:  t("action"),
      dataIndex: 'department',
      key: 'department',
      width: '33.33%',

      render: (_, data) => (
        <Popconfirm
          placement="bottom"
          title={
            <div style={{ width: 210 }}>
              <span>{t("areYouSureToRemoveAccessFor")}</span>{' '}
              <span style={{ fontWeight: 600 }}>
                {data.first_name} {data.last_name}
              </span>
              <p style={{ marginTop: 10 }}>{t("thisCantBeUndone")}</p>
            </div>
          }
          onConfirm={() => revokeAccessAction(data.id)}
        >
          <TableColumn style={{ color: '#2874f0' }}>{t("removeAccess")}</TableColumn>
        </Popconfirm>
      ),
    },
  ];

  return (
    <ManagerView
      isManager
      getUserList={getManagerList}
      createSingleUser={createSingleManager}
      revokeAccess={revokeManagerAccess}
      createBulkUserEndpoint="/company/create_user"
      dataKey="managerList"
      columns={columns}
    />
  );
};

export default ManagerUpload;
