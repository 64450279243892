import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const PeriodFilter = ({ period, onClick }) => {
  const [state, setState] = useState();
  const { t } = useTranslation();
  const onClickAction = (p) => {
    setState(p);
    onClick(p);
  };

  useEffect(() => {
    setState(period);
  }, [period]);

  return (
    <PeriodFilterWrapper>
      <PeriodButton name="week" selected={state === 'week'} onClick={() => onClickAction('week')}>
      {t("week")}
      </PeriodButton>
      <PeriodButton name="month" selected={state === 'month'} onClick={() => onClickAction('month')}>
      {t("month")}
      </PeriodButton>
      <PeriodButton name="year" selected={state === 'year'} onClick={() => onClickAction('year')}>
      {t("year")}
      </PeriodButton>
    </PeriodFilterWrapper>
  );
};

const PeriodFilterWrapper = styled.div`
  display: flex;
`;
const PeriodButton = styled.div`
  padding: 9px 11px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  border: 1px solid #d7dbec;
  border-right: 0;
  background: white;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #d7dbec;
  }

  &:hover {
    background: ${({ theme }) => theme.greyVeryLight};
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.primary} !important;
      font-weight: 600;
      color: white;
    `}
`;

export default PeriodFilter;
