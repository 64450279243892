import { GET_SURVEY_QUESTIONS, SAVE_SURVEY, LOGOUT, GET_SURVEY_OVERVIEW, GET_SURVEYS } from '../Constants/actionTypes';

const initialState = {
  questions: null,
  surveys: null,
  overview: null,
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case SAVE_SURVEY:
      return {
        ...state,
        surveys: [...state?.surveys, action.payload],
      };
    case GET_SURVEY_OVERVIEW:
      return {
        ...state,
        overview: action.payload,
      };
    case GET_SURVEYS:
      return {
        ...state,
        surveys: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
