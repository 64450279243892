import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Empty, Button, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import CardHeader from './cardHeader';
import DoughnutChart from './doughnutChart';
import MoreInsights from './moreInsightsButton';
import colorPalette from '../Util/colorPalette';
import { RightCircleOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

const AccordianMediumCard = ({ data, width, height, name, url, customWidth, toolTipText }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const goToMoreInsights = () => (url ? history.push(url) : () => void 0);

  return (
    <MediumCardLayout style={{ height, width }}>
      <CardHeader name={name} noFilters customWidth={customWidth} toolTipText={toolTipText} />
      {!data?.length ? (
        <EmptyWrapper>
          <Empty />
        </EmptyWrapper>
      ) : (
        <>
          <GraphWrapper>
            <DoughnutChart data={data} width={140} height={140} />
          </GraphWrapper>
          <CardTable>
            <StyledCollapse>
              {data?.map((item, index) => (
                <Panel
                  key={item.title}
                  disabled={!item?.sub_issues?.length}
                  header={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ColorSquare color={colorPalette[index]} />
                        <ItemName>
                          {item.title}
                          {item?.sub_issues?.length ? (
                            <RightCircleOutlined
                              style={{
                                fontSize: 11,
                                marginLeft: 2,
                              }}
                            />
                          ) : null}
                        </ItemName>
                      </div>
                      <ItemPercentage>{item.percentage}%</ItemPercentage>
                    </div>
                  }
                >
                  {item?.sub_issues?.map((item) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ItemName>{item.title}</ItemName>
                      </div>
                      <ItemPercentage
                        style={{
                          color: '#575D7A',
                          fontSize: 12,
                        }}
                      >
                        {item.count}%
                      </ItemPercentage>
                    </div>
                  ))}
                </Panel>
              ))}
            </StyledCollapse>
            {/* {data?.map((item, index) => (
              <TableRow key={`table-row-${item.title}`}>
                <ColorSquare color={colorPalette[index]} />
                <ItemName>{item.title}</ItemName>
                <ItemPercentage>{item.percentage}%</ItemPercentage>
              </TableRow>
            ))} */}
          </CardTable>
          <CustomHr />
          <CardButtonActionWrapper>
            <MoreInsights onClick={goToMoreInsights} />
            {url === '/analytics/therapist-feedback' && (
              <a href={'https://calendly.com/panagiotis-2'} target="_blank" rel="noreferrer">
                <Button style={{ color: '#28BF8D', border: '1px solid #28BF8D' }}>{t("bookMeeting")}</Button>
              </a>
            )}
          </CardButtonActionWrapper>
        </>
      )}
    </MediumCardLayout>
  );
};

const EmptyWrapper = styled.div`
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.white};
  min-width: 260px;

  border-radius: 5px;
  padding: 22px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 4px;

  margin-bottom: 35px;

  @media screen and (max-width: 840px) {
    width: 100% !important;
    margin-bottom: 15px;
  }
`;

const MediumCardLayout = styled(Card)`
  position: relative;
  width: 100%;
`;

const GraphWrapper = styled.div`
  margin: 35px 0;
  display: flex;
  justify-content: center;
`;

const CardTable = styled.div`
  height: 140px;
  padding-right: 8px;
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
    background: ${({ theme }) => theme.greyLight};
  }
`;
const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const ColorSquare = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
`;
const ItemName = styled.div`
  width: calc(85% - 30px);
  padding-left: 20px;
  color: ${({ theme }) => theme.greyMedium};
  font-size: 14px;
  white-space: nowrap;
`;
const ItemPercentage = styled.div`
  text-align: left;
  padding-left: 4%;
  width: 22%;
  font-weight: 500;
  font-size: 14px;
`;

const CustomHr = styled.hr`
  width: 100%;
  background-color: ${({ theme }) => theme.greyLight};
  margin: 20px 0;
`;

const CardButtonActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default memo(AccordianMediumCard);

const StyledCollapse = styled(Collapse)`
  border: none;
  background-color: transparent;
  margin: 10px 0;
  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    padding: 0 !important;
    width: 100% !important;

    .ant-collapse-arrow {
      display: none !important;
    }
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse-content-box {
    padding: 0 0 8px 16px !important;
  }
`;
