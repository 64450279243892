import React from 'react';
import styled from 'styled-components';

const Square = ({ size = 9, color = 'grey' }) => {
  return <SquareStyled size={size} color={color} />;
};

const SquareStyled = styled.div`
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  background: ${({ color }) => color} 0 0 no-repeat padding-box;
  border-radius: 2px;
`;

export default Square;
