import React, { memo } from 'react';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import TherapistPieWithDescription from './TherapistPieWithDescription';
import { useTranslation } from 'react-i18next';

const TherapistFeedbackCard = ({ period, setCompareprevious, toolTipText}) => {
  const data = useSelector((store) => store.analytics?.concerningIssues?.data?.current);
  const previous = useSelector((store) => store.analytics?.concerningIssues?.data?.previous);
  const { t } = useTranslation();
  const getPrev = (filters) => {
    setCompareprevious(filters.compare_previous);
  };

  return (
    <FullCard>
      <CardHeader
        name={t("clinicalInsights")}
        noFilters
        noCompare={false}
        onSelect={getPrev}
        customWidth="400px"
        toolTipText={t("therapistFeedbackTooltip")}
      />
      <TherapistPieWithDescription data={data} />
      {previous && (
        <>
          <Divider />
          <CardHeader
            name={`${t("last")} ${period} ${t("clinicalInsights")}`}
            noFilters
            customWidth="400px"
            toolTipText={t("therapistFeedbackTooltip")}
          />
          <TherapistPieWithDescription data={previous} />
        </>
      )}
    </FullCard>
  );
};

export default memo(TherapistFeedbackCard);
