import React, { memo, useEffect, useState } from 'react';
import KeyStatisticsCard from './keyStatisticsCard';
import LocationPath from '../../../Components/locationPath';
import api from '../../../Util/api';
import { useParams } from 'react-router';
import WorkLoadTagCard from './WorkLoadTagCard';
import Loading from '../../../Components/loading';
import MoreInsightsCard from './moreInsightsCard';
import styled from 'styled-components';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import HeartbeatFilters from '../../../Components/heartbeatFilters';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import copyToClipboard from '../../../Util/clipboard';
import { useSelector } from 'react-redux';
export const RATINGS = (t) => [t('great'), t('good'), t('average'), t('bad'), t('veryPoor')];

// Data for this page does not need to go to redux, because it's detail level data
const SurveyPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [moreInsights, setMoreinsights] = useState(null);
  const [count, setCount] = useState(null);
  const [surveyName, setSurveyName] = useState('');
  const [loading, setLoading] = useState(0);
  const [sortByMoreInsights, setSortByMoreInsights] = useState('age');
  const [responseType, setResponseType] = useState('all');
  const [location, setLocation] = useState(0);
  const locationOptions = useSelector((store) => store.company.locations);

  const [value, setValue] = useState();
  const [isCustomQuestions, setIsCustomQuestions] = useState(false);
  const { id } = useParams();
  const customPath = [{ pathName: t('heartbeat'), pathLink: '/survey/overview' }];
  const onSelectResponseChange = (id) => {
    setResponseType(id);
  };

  const onLocationChange = (id) => {
    setLocation(id);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        const response = await api.survey.getSurvey(id, sortByMoreInsights, responseType, location);
        console.log(
          '🚀 ~ file: insightsPage.js ~ line 40 ~ getData ~ response?.data?.data?.moreInsights',
          response?.data?.data?.moreInsights,
        );
        setData(response?.data?.data?.categories);
        setMoreinsights(response?.data?.data?.moreInsights);
        setCount(response?.data?.data?.more_insights_counts);
        setSurveyName(response?.data?.data?.survey_name);
      } finally {
        setLoading((prevState) => prevState - 1);
      }
    };
    const getSurveyResponseData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        const response = await api.survey.getResponseData(id, location);
        setIsCustomQuestions(!!response?.data?.data?.questions?.length);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prevState) => prevState - 1);
      }
    };
    getData();

    getSurveyResponseData();
  }, [id, sortByMoreInsights, responseType, location]);

  const { Option } = Select;

  const handleChange = (value, setValue) => {
    copyToClipboard(value, setValue);
  };

  return (
    <>
      {!!loading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      <BreadCrumbContainer>
        <LocationPath
          isInsights
          multi
          customPath={customPath}
          pathName={t('heartbeat')}
          pathLink="/survey/overview"
          surveyName={surveyName}
        />
        <StyledDiv>
          <NewStyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder={t('copyToClipboard')}
            bordered={false}
            onChange={(val) => {
              handleChange(val, setValue);
              setValue(val);
            }}
            value={value}
          >
            <Option value="copyFullImage">{t('copyEntirePage')}</Option>
            <Option value="copyChartElement">{t('copyChartElement')}</Option>
            <Option value="copyChartDesciption">{t('copyChartDescription')}</Option>
            <Option value="copyDemographicInsights">{t('copyDemographicChart')}</Option>
          </NewStyledSelect>

          {/* <NewStyledSelect
            getPopupContainer={(trigger) => trigger.parentElement}
            defaultValue="0"
            bordered={false}
            onChange={onLocationChange}
          >
            <Option value="0">{t('allLocations')}</Option>
            {locationOptions?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </NewStyledSelect> */}

          {/* <HeartbeatFilters onSelectResponseChange={onSelectResponseChange} /> */}
        </StyledDiv>
      </BreadCrumbContainer>

      <div id="print" className="copyFullImage">
        <div className="exportPDF copyChartElement">
          <KeyStatisticsCard
            isCustomQuestions={isCustomQuestions}
            surveyName={surveyName}
            data={Object.keys(data || {})?.map((key) => ({ ...data[key], title: key }))}
            setLoading={setLoading}
            location={location}
          />
        </div>

        <div className="copyChartDesciption">
          {data &&
            Object.keys(data)?.map((key) => (
              <div className="exportPDF">
                <WorkLoadTagCard key={key} name={key} data={data[key]} />
              </div>
            ))}
        </div>

        <div className="exportPDF copyDemographicInsights">
          <MoreInsightsCard setLoading={setLoading} count={count || {}} toolTipText={t('insightsPageTooltip')} location={location} />
        </div>
      </div>
    </>
  );
};

export default memo(SurveyPage);

const StyledDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const NewStyledSelect = styled(Select)`
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;

  .ant-select-borderless {
    border: none !important;
  }

  .ant-select-selector {
    width: 150px !important;
    height: 38px !important;
    padding: 0 !important;
    padding-left: 10px !important;
    border: 1px solid #cdcdcd !important;
    color: #575d7a;
  }
  .ant-select-arrow {
    top: 33% !important;
  }

  .ant-select-selection-item {
    // padding-right: 24px !important;
  }
`;

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
