import React, { useMemo } from 'react';

import styled from 'styled-components';
import { Empty, Tooltip } from 'antd';

import { useSelector } from 'react-redux';
import InfoContainer from '../../../Components/InfoContainer';
import { ContentWrapper, LeftWrapper, RightWrapper } from '../../../Components/fullCard';
import DoughnutChart from '../../../Components/doughnutChart';
import colorPalette from '../../../Util/colorPalette';
import Dot from '../../../Components/dot';

const TherapistPieWithDescription = ({ data }) => {
  console.log('🚀 ~ file: TherapistPieWithDescription.js ~ line 14 ~ TherapistPieWithDescription ~ data', data);
  const isMobileView = useSelector((store) => store.app.isMobileView);
  const doughnutParsedData = useMemo(() => {
    return data ? data.map((item) => ({ title: item.title, percentage: item.percentage })) : [];
  }, [data]);

  if (doughnutParsedData.length === 0) return <Empty />;
  return (
    <ContentWrapper>
      <LeftWrapper>
        <DoughnutChart data={doughnutParsedData} width={300} height={300} />
      </LeftWrapper>
      <RightWrapper>
        {data?.map((item, index) =>
          data.length > 1 ? (
            <CustomTooltip>
              {/* <Tooltip
                // placement="bottom"
                getPopupContainer={(triggerNode) => triggerNode}
                overlayInnerStyle={{
                  width: '180px',
                  position: 'fixed',
                  color: 'white',
                  background: '#ffffff 0 0 no-repeat padding-box',
                  boxShadow: '0 5px 15px #44444f1a',
                  borderRadius: '10px',
                }}
                title={
                  <span>
                    <p
                      style={{
                        background: ` #28BF8D`,
                        textAlign: 'center',
                        fontWeight: 500,
                        marginBottom: 3,
                      }}
                    >
                      {item.title} details
                    </p>
                    <StyledTooltipContainer>
                      {item?.sub_issues?.length ? (
                        item?.sub_issues?.map((el) => (
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontSize: 12,
                              }}
                            >
                              <div>
                                <Dot color="grey" />
                                <span style={{ marginLeft: '10px' }}>{el.title}</span>
                              </div>
                              <span style={{ marginLeft: '10px' }}>{el.count}</span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Details Found</p>
                      )}
                    </StyledTooltipContainer>
                  </span>
                }
              > */}
              <div>
                <InfoContainer item={item} id={index} index={index} isClinicalInsight />
              </div>
              {/* </Tooltip> */}
            </CustomTooltip>
          ) : (
            <InfoWrapper>
              <InfoContainer item={item} id={index} index={index} isClinicalInsight />
            </InfoWrapper>
          ),
        )}
      </RightWrapper>
    </ContentWrapper>
  );
};

const InfoWrapper = styled.div`
  // margin-top: 100px;
  @media screen and (max-width: 820px) {
    margin-top: 0px;
  }
`;

const CustomTooltip = styled.div`
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
  }
  .ant-tooltip-inner {
    padding: 0;
  }
`;

export default TherapistPieWithDescription;
