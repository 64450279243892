import { Popconfirm } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSingleEmployee,
  getEmployeeList,
  revokeEmployeeAccess,
  getInvitedToHeartbeat,
  revokeHeartbeatEmployeeAccess,
  revokeHearBeatAccess,
} from '../../Actions/company.actions';
import { TableColumn } from '../../SharedStyles';
import EmployeeView from './employeeView';
import HearBeatUploadView from './heartbeatUploadView';
import { useTranslation } from 'react-i18next';

const EmployeeUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const heartbeat = useSelector(
    (store) => store.company?.profile?.only_heartbeat && !store.company?.profile?.is_analytics,
  );

  const revokeAccessAction = (id) => dispatch(revokeEmployeeAccess(String(id)));
  const revokeHeartbeatAction = (id) => dispatch(revokeHearBeatAccess(String(id)));
  const revokeHearBeatAccessAction = (id) => dispatch(revokeHeartbeatEmployeeAccess(String(id)));

  const heartbeatColumns = [
    {
      title: t("name"),
      dataIndex: 'name',
      key: 'name',
      width: '33.33%',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t("Email"),
      dataIndex: 'email',
      key: 'email',
      width: '33.33%',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("department"),
      dataIndex: 'department',
      key: 'department',
      width: '33.33%',
      render: (_, data) => data.department || '-',
      sorter: (a, b) => (a.department || '').localeCompare(b.department || ''),
    },
    {
      title: t("actions"),
      dataIndex: 'Actions',
      key: 'Actions',
      width: '33.33%',
      render: (_, data) => (
        <div>
          <Popconfirm
            placement="bottom"
            title={
              <div style={{ width: 210 }}>
                <span>{t("areYouSureToRevokeAccess")}:</span> <span style={{ fontWeight: 600 }}>{data.name}</span>
              </div>
            }
            onConfirm={() => revokeHeartbeatAction(data.email)}
          >
            <TableColumn style={{ color: '#2874f0' }}>{t("revokeAccess")}</TableColumn>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const columns = [
    {
      title: t("name"),
      dataIndex: 'first_name',
      key: 'first_name',
      width: '33.33%',
      sorter: (a, b) => (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name),

      render: (_, data) => (data.first_name ? data.first_name + ' ' + (data.last_name || '') : '-'),
    },
    {
      title: t("Email"),
      dataIndex: 'email',
      key: 'email',
      width: '33.33%',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t("actions"),
      dataIndex: 'Actions',
      key: 'Actions',
      width: '33.33%',
      render: (_, data) => (
        <Popconfirm
          placement="bottom"
          title={
            <div style={{ width: 210 }}>
              <span>{t("areYouSureToRevokeAccess")}:</span>{' '}
              <span style={{ fontWeight: 600 }}>
                {data.first_name} {data.last_name}
              </span>
            </div>
          }
          onConfirm={() => revokeAccessAction(data.email)}
        >
          <TableColumn style={{ color: '#2874f0' }}>{t("revokeAccess")}</TableColumn>
        </Popconfirm>
      ),
    },
  ];

  if (heartbeat) {
    return (
      <HearBeatUploadView
        getUserList={getInvitedToHeartbeat}
        createSingleUser={createSingleEmployee}
        revokeAccess={revokeHearBeatAccessAction}
        createBulkUserEndpoint="/company/invite_user"
        dataKey="employeeList"
        columns={heartbeatColumns}
      />
    );
  }

  return (
    <EmployeeView
      getUserList={getEmployeeList}
      createSingleUser={createSingleEmployee}
      revokeAccess={revokeEmployeeAccess}
      createBulkUserEndpoint="/company/invite_user"
      dataKey="employeeList"
      columns={columns}
    />
  );
};

export default EmployeeUpload;
