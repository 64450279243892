import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './Assets/Locales/en/translation.json';
import translationSR from './Assets/Locales/sr/translation.json';
// import translationDE from './Assets/Locales/de/translation.json';
import translationAR from './Assets/Locales/ar/translation.json';
import translationEL from './Assets/Locales/el/translation.json';
// import { setlanguageCode } from './Util/axiosConfig';

const availableLanguages = ['en', 'sr', 'el', 'ar', 'sh'];

const fallbackLng = ['en'];

export const languageMapping = {
  en: ['English', 'Serbian', 'Greek', 'Arabic'],
  sr: ['English', 'srpski', 'grčki', 'arapski'],
  sh: ['English', 'srpski', 'grčki', 'arapski'],
  el: ['English', 'Σέρβος', 'Ελληνικά', 'αραβικός'],
  ar: ['English', 'الصربية', 'اليونانية', 'عربي'],
};

export const resources = {
  en: { translation: translationEN },
  sr: { translation: translationSR },
  el: { translation: translationEL },
  ar: { translation: translationAR },
//   sh: { translation: translationSR },
  // de: { translation: translationDE },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    lng: 'en',
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
