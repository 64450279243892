import React, { memo } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { Button, Popconfirm, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Modal from '../../Components/Modal';

const UploadUserConfirmation = ({
  confirm,
  onCancel = () => {},
  onConfirm = () => {},
  uploadButtonRef = {},
  isManager,
  selectedLang,
}) => {
  const { t } = useTranslation();
  if (!confirm) return null;
  const onClick = () => {
    onConfirm();
    uploadButtonRef?.current?.click();
  };

  const languageOnChange = (lang) => {
    selectedLang(lang);
  };

  return (
    <>
      <Modal open={true} closeModal={() => null} showCloseButton={false}>
        <div style={{ textAlign: 'center' }}>
          <Wrapper>
            <TherapyFeedbackWrapper>
              <SubmitFeedbackWrapper>
                <h2 style={{ color: '#575d7a' }}>{` ${t('areYouSureYouOnboard')} ${
                  isManager ? t('managers') : t('yourEmployees')
                } ?`}</h2>
                <Formik onSubmit={onConfirm}>
                  {() => (
                    <Form onChange={() => {}}>
                      <ButtonWrapper>
                        <StyledButton variant="secondary" type="button" onClick={onCancel}>
                          {t('cancel')}
                        </StyledButton>
                        <Popconfirm
                          placement="bottom"
                          title={
                            <div style={{ width: 210 }}>
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <div style={{ marginBottom: '5px' }}>{t('pleaseSelectLanguage')}</div>
                                <Select
                                  getPopupContainer={(trigger) => trigger.parentElement}
                                  bordered={true}
                                  defaultValue="english"
                                  onChange={(e) => languageOnChange(e)}
                                  placeholder={t('pleaseSelectLanguage')}
                                  options={[
                                    {
                                      label: t('English'),
                                      key: '1',
                                      value: 'english',
                                    },
                                    {
                                      label: t('Serbian'),
                                      key: '2',
                                      value: 'serbian',
                                    },
                                    {
                                      label: t('Greek'),
                                      key: '3',
                                      value: 'greek',
                                    },
                                    {
                                      label: t('Arabic'),
                                      key: '4',
                                      value: 'arabic',
                                    },
                                  ]}
                                ></Select>
                              </span>
                            </div>
                          }
                          onConfirm={onClick}
                        >
                          <StyledButton variant="secondary" type="button">
                            {t('Yes')}
                          </StyledButton>
                        </Popconfirm>
                      </ButtonWrapper>
                    </Form>
                  )}
                </Formik>
              </SubmitFeedbackWrapper>
            </TherapyFeedbackWrapper>
          </Wrapper>
        </div>
      </Modal>
    </>
  );
};

const StyledButton = styled(Button)`
  width: 200px;
  border-radius: 5px;
`;

const Wrapper = styled.div`
  text-align: center;
  margin: auto;
  max-width: 700px;
  width: 100%;
`;

const TherapyFeedbackWrapper = styled.div`
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  form {
    text-align: left;
    width: 100%;
  }
`;

const SubmitFeedbackWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0;

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  label {
    font-weight: 400;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingMd};
  display: flex;
  justify-content: space-evenly;
`;

export default memo(UploadUserConfirmation);
