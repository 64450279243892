import React, { useEffect, useState } from 'react';
import SurveyPreview from './SurveyPreview';
import styled from 'styled-components';
import { getQuestionnaire } from '../../../Actions/survey.actions';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as LeftIcon } from '../../../Assets/Icons/Survey/leftGraph.svg';
import { Spin } from 'antd';

// This component is to show survey when clicked on preview button
const SuveyPreviewDashboard = () => {
  const [questionnaire, setQuestionnaire] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getQuestionnaire(id)()
      .then((res) => setQuestionnaire(res.data))
      .catch(() => history.push('/'));
  }, [id, history]);

  if (questionnaire === null)
    return (
      <QuestionnaireWrapper>
        <StepsWrapper>
          <LeftSide>
            <Icon as={LeftIcon} />
          </LeftSide>
          <RightSide>
            <Welcome>
              <Spin size="large" />
            </Welcome>
          </RightSide>
        </StepsWrapper>
      </QuestionnaireWrapper>
    );

  return <SurveyPreview questionnaire={questionnaire} isDashboardPreview />;
};

export default SuveyPreviewDashboard;

const Icon = styled.svg``;

const QuestionnaireWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 1000000;
`;

const Welcome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftSide = styled.div`
  background: ${({ theme }) => theme.primary};
  width: 30%;
  padding: 30px;

  display: flex;
  align-items: center;
`;

const RightSide = styled.div`
  background: white;
  width: 70%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepsWrapper = styled.div`
  display: flex;
  height: 100%;

  @media screen and (max-width: 880px) {
    ${LeftSide} {
      display: none;
    }
    ${RightSide} {
      width: 100%;
    }
  }
`;
