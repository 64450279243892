import { combineReducers } from 'redux';

import auth from './auth.reducer';
import app from './app.reducer';
import company from './company.reducer';
import survey from './survey.reducer';
import analytics from './analytics.reducer';
import dashboard from './dashboard.reducer';
import loading from './loading.reducer';

export default combineReducers({ auth, app, company, survey, analytics, dashboard, loading });
