// GLOBAL
export const ERRORS = 'ERRORS';

// APP
export const SIDE_BAR_TOGGLE = 'SIDE_BAR_TOGGLE';
export const SET_MOBILE_VIEW_STATE = 'SET_MOBILE_VIEW_STATE';
export const SET_TABLET_VIEW_STATE = 'SET_TABLET_VIEW_STATE';

// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const MAP_STORAGE_TO_REDUX = 'MAP_STORAGE_TO_REDUX';

// COMPANY
export const GET_PROFILE = 'GET_PROFILE';
export const GET_ALL_DASHBOARD = 'GET_ALL_DASHBOARD';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_MANAGER_LIST = 'GET_MANAGER_LIST';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_INVITED_EMPLOYEE_LIST = 'GET_INVITED_EMPLOYEE_LIST';
export const GET_INVITATION_DETAILS = 'GET_INVITATION_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const CREATE_COMPANY_MASTER_USER = 'CREATE_COMPANY_MASTER_USER';

// DASHBOARD
export const FILTER_LEADING_ISSUE = 'FILTER_LEADING_ISSUE';
export const FILTER_ACTIVITY_LEVEL = 'FILTER_ACTIVITY_LEVEL';
export const FILTER_EMPLOYEE_COUNT = 'FILTER_EMPLOYEE_COUNT';

// SURVEY
export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS';
export const SAVE_SURVEY = 'SAVE_SURVEY';
export const GET_SURVEY_OVERVIEW = 'GET_SURVEY_OVERVIEW';
export const GET_SURVEYS = 'GET_SURVEYS';

// ANALYTICS
export const GET_TEXT_ANALYTICS = 'GET_TEXT_ANALYTICS';
export const GET_TEXT_ANALYTICS_INSIGHTS = 'GET_TEXT_ANALYTICS_INSIGHTS';

export const GET_COMPANY_MOOD = 'GET_COMPANY_MOOD';
export const GET_COMPANY_MOOD_INSIGHTS = 'GET_COMPANY_MOOD_INSIGHTS';

export const GET_LEADING_ISSUES = 'GET_LEADING_ISSUES';
export const GET_LEADING_ISSUES_INSIGHTS = 'GET_LEADING_ISSUES_INSIGHTS';

export const GET_PRIORITY_ISSUES = 'GET_PRIORITY_ISSUES';
export const GET_PRIORITY_ISSUES_INSIGHTS = 'GET_PRIORITY_ISSUES_INSIGHTS';

export const GET_CONCERNING_ISSUES = 'GET_CONCERNING_ISSUES';
export const GET_ACTIVITY_LEVEL = 'GET_ACTIVITY_LEVEL';

// DASHBOARD
export const GET_DASHBOARD = 'GET_DASHBOARD';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
