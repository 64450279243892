import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import CustomTooltip from '../../../Components/customTooltip';
import Loading from '../../../Components/loading';
import LocationPath from '../../../Components/locationPath';
import { BreadCrumbContainer } from '../../../SharedStyles/BreadCrumbContainer';
import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';
import ResponseAnswers from './answers';
import CloudList from './cloudList';

const sentimentColor = { negative: '#790707', positive: '#1a5549', neutral: 'grey' };

const FreeTextAnalytics = () => {
  const [list, setList] = useState([]);
  const { t } = useTranslation();

  const { id, country } = useParams();

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const customPath = [
    { pathName: 'Heartbeat', pathLink: '/survey/overview' },
    { pathName: `Heartbeat Insights - ${location.state || id}`, pathLink: `/survey/insights/${id}` },
  ];

  useEffect(() => {
    const getSurveyResponseData = async () => {
      try {
        setLoading(true);
        const response = await api.survey.getResponseData(id, country);
        setList(response?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getSurveyResponseData();
  }, []);

  // const dummyanswer = [
  //   { id: 1, answer: 'depressed, anxious, upset' },
  //   { id: 2, answer: 'depressed, anxious, upset' },
  //   { id: 3, answer: 'depressed, anxious, upset' },
  //   { id: 4, answer: 'depressed, anxious, upset' },
  //   { id: 5, answer: 'depressed, anxious, upset' },
  //   { id: 6, answer: 'depressed, anxious, upset' },
  //   { id: 7, answer: 'depressed, anxious, upset' },
  //   { id: 8, answer: 'depressed, anxious, upset' },
  //   { id: 9, answer: 'depressed, anxious, upset' },
  //   { id: 10, answer: 'depressed, anxious, upset' },
  //   { id: 11, answer: 'depressed, anxious, upset' },
  // ];

  // list.data.questions[0].answers = dummyanswer;

  return (
    <>
      {loading && (
        <HoverWrapper>
          <Loading />
        </HoverWrapper>
      )}
      <BreadCrumbContainer>
        <LocationPath
          isFreeTextAnalytics
          multi
          pathName={t("heartbeat")}
          pathLink="/survey/overview"
          surveyName={location.state}
          customPath={customPath}
        />
      </BreadCrumbContainer>
      {list?.data?.questions?.map((q, index) => {
        return (
          <MainContainer>
            <HeadingWrap>
              <NewTitle style={{ fontWeight: 600 }}>{q.question}</NewTitle>
              <div>
              <CustomTooltip customWidth= "200px" title={t("avgSentimentOfResponses")}>
                <SentimentOuterWrapper style={{ backgroundColor: sentimentColor[q.sentiment_survey] || 'blue' }}>
                  <SentimentWrapper>{q.sentiment_survey}</SentimentWrapper>
                </SentimentOuterWrapper>
                </CustomTooltip>
              </div>
            </HeadingWrap>
            <MainWrapper>
              <ResponseAnswers question={q} />
              <CloudList question={q} />
            </MainWrapper>
          </MainContainer>
        );
      })}
    </>
  );
};

export default FreeTextAnalytics;

const HoverWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: #ffffffc7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const MainContainer = styled.div`
  background: white;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #d8dddc;
  margin-bottom: 40px;
`;

const HeadingWrap = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
`;

const MainWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const NewTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #1a5549;
  margin-bottom: 10px;

  @media screen and (max-width: 879px) {
    text-align: center;
  }
`;

const SentimentWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: white;
  text-transform: capitalize;
`;

const SentimentOuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #790707;
  width: 135px;
  cursor: pointer;
  border-radius: 30px;
`;
