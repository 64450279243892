import React, { memo, useMemo } from 'react';

import styled from 'styled-components';

import { Divider } from 'antd';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import WordCloudChart from '../../../Components/wordCloudChart';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PriorityIssueCard = ({ period, setCompareprevious }) => {
  const { t } = useTranslation();
  const data = useSelector((store) => store.analytics?.text?.data?.keywords_current);
  const previous = useSelector((store) => store.analytics?.text?.data?.keywords_previous);

  const parsedData = useMemo(() => {
    if (!data) return [];
    return Object.keys(data).map((key) => ({ text: key, value: data[key].count }));
  }, [data]);

  const parsedDataPrev = useMemo(() => {
    if (!previous) return [];
    return Object.keys(previous).map((key) => ({ text: key, value: previous[key].count }));
  }, [previous]);

  const getPrev = (filters) => {
    setCompareprevious(filters.compare_previous);
  };

  return (
    <FullCard>
      <CardHeader
        name={t("conversationalAnalytics")}
        noFilters
        noCompare={false}
        onSelect={getPrev}
        customWidth="400px"
        toolTipText={t("conversationalAnalyticsTooltip")}
      />
      <Wrapper>
        <WordWrapper>
          <WordCloudChart data={parsedData} hideMoreInsightsLink={true} />
        </WordWrapper>
        <div id="text-analytics-keywords">
          <TopKeywordsWrapper>
            <Title>{t("topKeywords")}</Title>
            <WordList>
              {parsedData
                ?.sort((a, b) => b.value - a.value)
                ?.map(({ text, value }) => (
                  <KeywordItem key={text}>
                    <KeyWord>{text}</KeyWord>
                    <KeyValue>{value}</KeyValue>
                  </KeywordItem>
                ))}
            </WordList>
          </TopKeywordsWrapper>
        </div>
        <div id="exportTextAnalyticsCard" style={{ display: 'none' }}>
          <ExportTopKeywordsWrapper>
            <ExportTitle>{t("topKeywords")}</ExportTitle>
            <ExportWordList>
              {parsedData
                ?.sort((a, b) => b.value - a.value)
                ?.map(({ text, value }) => (
                  <ExportKeywordItem key={text}>
                    <ExportKeyWord>{text}</ExportKeyWord>
                    <ExportKeyValue>{value}</ExportKeyValue>
                  </ExportKeywordItem>
                ))}
            </ExportWordList>
          </ExportTopKeywordsWrapper>
        </div>
      </Wrapper>
      {previous && (
        <>
          <Divider />
          <CardHeader
            name={`${t("last")} ${period} ${t("conversationalAnalytics")}`}
            noFilters
            customWidth="400px"
            toolTipText={t("conversationalAnalyticsTooltip")}
          />
          <Wrapper>
            <WordWrapper>
              <WordCloudChart data={parsedDataPrev} hideMoreInsightsLink={true} />
            </WordWrapper>
            <div id="previous-text-analytics-keywords">
            <TopKeywordsWrapper>
              <Title>{t("topKeywords")}</Title>
              <WordList>
                {parsedDataPrev
                  ?.sort((a, b) => b.value - a.value)
                  ?.map(({ text, value }) => (
                    <KeywordItem key={text}>
                      <KeyWord>{text}</KeyWord>
                      <KeyValue>{value}</KeyValue>
                    </KeywordItem>
                  ))}
              </WordList>
            </TopKeywordsWrapper>
            </div>
            <div id="previousExportTextAnalyticsCard" style={{ display: 'none' }}>
            <ExportTopKeywordsWrapper>
              <ExportTitle>{t("topKeywords")}</ExportTitle>
              <ExportWordList>
                {parsedDataPrev
                  ?.sort((a, b) => b.value - a.value)
                  ?.map(({ text, value }) => (
                    <ExportKeywordItem key={text}>
                      <ExportKeyWord>{text}</ExportKeyWord>
                      <ExportKeyValue>{value}</ExportKeyValue>
                    </ExportKeywordItem>
                  ))}
              </ExportWordList>
            </ExportTopKeywordsWrapper>
          </div>
          </Wrapper>
        </>
      )}
    </FullCard>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const WordWrapper = styled.div`
  width: 75%;

  @media screen and (max-width: 879px) {
    width: 100%;
  }
`;

const TopKeywordsWrapper = styled.div`
  width: 25%;
  min-width: 190px;

  @media screen and (max-width: 879px) {
    width: 100%;
  }
`;

const KeywordItem = styled.div`
  display: flex;
  width: 170px;
  justify-content: space-between;
  margin-bottom: 8px;

  @media screen and (max-width: 879px) {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const WordList = styled.div`
  max-height: 200px;
  overflow: auto;
  width: fit-content;
  padding-right: 7px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: ${({ theme }) => theme.greyLight};
  }

  @media screen and (max-width: 879px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Title = styled.div`
  font-size: 14px;
  color: #7e84a3;
  margin-bottom: 24px;

  @media screen and (max-width: 879px) {
    text-align: center;
  }
`;

const KeyWord = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;

  @media screen and (max-width: 879px) {
    margin-right: 10px;
  }
`;

const KeyValue = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;
`;

const ExportTopKeywordsWrapper = styled.div`
  // width: 25%;
  // min-width: 190px;
  margin-bottom: 50px;

  @media screen and (max-width: 879px) {
    width: 100%;
  }
`;

const ExportKeywordItem = styled.div`
  display: flex;
  width: 170px;
  gap: 10px;
  // justify-content: space-between;
  margin-bottom: 8px;

  @media screen and (max-width: 879px) {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const ExportWordList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 200px;
  // overflow: auto;
  //width: fit-content;
  //padding-right: 7px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: ${({ theme }) => theme.greyLight};
  }

  @media screen and (max-width: 879px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ExportTitle = styled.div`
  font-size: 14px;
  color: #7e84a3;
  margin-bottom: 24px;

  @media screen and (max-width: 879px) {
    text-align: center;
  }
`;

const ExportKeyWord = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;

  @media screen and (max-width: 879px) {
    margin-right: 10px;
  }
`;

const ExportKeyValue = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;
`;

export default memo(PriorityIssueCard);
