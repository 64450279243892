import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { Upload, Button, message } from 'antd';
import PhoneInput from 'react-phone-input-2';

import Input from '../../Components/input';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserDetails } from '../../Actions/company.actions';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const profileSchema = (t) => Yup.object().shape({
  firstName: Yup.string().min(2, t("tooShort")).max(50, t("tooLong")).required(t("required")),
  lastName: Yup.string().min(2, t("tooShort")).max(50, t("tooLong")).required(t("required")),
  email: Yup.string().email( t("invalidEmail")).required(t("required")),
  mobile: Yup.string().matches(phoneRegExp, t("phoneNumberIsNotValid")).required(t("required")),

  password: Yup.string().matches(
    /^.*(?=.{7,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    t("matchPassword"),
  ),
  repeat_password: Yup.string().when('password', {
    is: (password) => password && password.length > 0,
    then: Yup.string()
      .required( t("pleaseConfirmPassword"))
      .oneOf([Yup.ref('password')],  t("passwordDoesnotMatch")),
  }),
});

const ProfileSettings = () => {
  const userDetails = useSelector((store) => store.company?.userSettings?.userDetails);
  const companyDetails = useSelector((store) => store.company?.profile);
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(userDetails?.mobile);
  const [file, setFile] = useState();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({ password: '', repeatPassword: '' });

  const dispatch = useDispatch();

  const uploadProps = useMemo(
    () => ({
      onChange: (info) => {
        if (info.file.status !== 'removed') {
          setFile(info?.file?.originFileObj);
          setFileList(info.fileList);
        }
      },
    }),
    [],
  );

  const dummyRequest = ({ onSuccess }) => setTimeout(() => onSuccess('ok'), 0);

  const onSubmit = (values) => {
    const { email, firstName, lastName, password } = values;
    setLoading(true);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('mobile', mobile || '');
    if (password) formData.append('password', password);
    if (file) formData.append('profile_image', file);

    dispatch(updateUserDetails(formData))
      .then(() => {
        if (file) setFileList([]);
        if (password) setPassword({ password: '', repeatPassword: '' });
        dispatch(getUserDetails())
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
      })
      .catch((err) => {
        const { errors } = err.response?.data;
        for (let elem in errors) {
          message.error(errors[elem]);
        }

        setLoading(false);
      });
  };

  const mobileOnChange = (phone, setFieldValue, form) => {
    setMobile(phone);
    setFieldValue('mobile', phone);
    form.setFieldTouched('mobile', false, false);
  };

  const removeFile = () => {
    setFileList([]);
    setFile(null);
  };

  const passwordOnChange = (e, setFieldValue) => {
    e.persist();
    setFieldValue('password', e.target.value);
    setPassword((state) => ({ ...state, password: e.target.value }));
  };
  const repeatPasswordOnChange = (e, setFieldValue) => {
    e.persist();
    setFieldValue('repeat_password', e.target.value);
    setPassword((state) => ({ ...state, repeatPassword: e.target.value }));
  };

  useEffect(() => {
    if (userDetails) setMobile(userDetails.mobile);
  }, [userDetails]);

  return (
    <FormWrapper>
      {userDetails && (
        <Formik
          initialValues={{
            firstName: userDetails?.first_name,
            lastName: userDetails?.last_name,
            email: userDetails?.company_master_account ? companyDetails?.email : userDetails?.email,
            mobile: userDetails?.mobile,
            profile: userDetails?.profile_image,
            password: '',
            repeat_password: '',
          }}
          validationSchema={profileSchema(t)}
          onSubmit={onSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <FormRow>
                <StyledLabel htmlFor="firstName">{t("firstName")}</StyledLabel>
                <FieldWrapper>
                  <Field id="firstName" name="firstName">
                    {({ field }) => (
                      <Input id="firstName" name="firstName" placeholder={t("firstName")} type="text" {...field} />
                    )}
                  </Field>
                  {errors.firstName && touched.firstName && <StyledErrorMessage name="firstName" component="div" />}
                </FieldWrapper>
              </FormRow>
              <FormRow>
                <StyledLabel htmlFor="lastName">{t("lastName")}</StyledLabel>
                <FieldWrapper>
                  <Field id="lastName" name="lastName">
                    {({ field }) => (
                      <Input id="lastName" name="lastName" placeholder={t("lastName")} type="text" {...field} />
                    )}
                  </Field>
                  {errors.lastName && touched.lastName && <StyledErrorMessage name="lastName" component="div" />}
                </FieldWrapper>
              </FormRow>
              <FormRow>
                <StyledLabel htmlFor="email">{t("emailAddress")}</StyledLabel>
                <FieldWrapper>
                  <Field id="email" name="email">
                    {({ field }) => (
                      <Input disabled id="email" name="email" placeholder={t("emailAddress")} type="email" {...field} />
                    )}
                  </Field>
                  {errors.email && touched.email && <StyledErrorMessage name="email" component="div" />}
                </FieldWrapper>
              </FormRow>
              <FormRow>
                <StyledLabel htmlFor="firstName">{t("contactNumber")}</StyledLabel>
                <FieldWrapper>
                  <Field id="mobile" name="mobile">
                    {({ field, form }) => (
                      <>
                        <PhoneInput
                          enableSearch
                          placeholder={t("selectCountryAndNumber")}
                          autoFormat
                          // country={'us'}
                          value={mobile}
                          onChange={(phone) => mobileOnChange(phone, setFieldValue, form)}
                          // onChange={(phone) => this.setState({ phone })}
                        />
                      </>
                    )}
                  </Field>
                  {errors.mobile && touched.mobile && <StyledErrorMessage name="mobile" component="div" />}
                </FieldWrapper>
              </FormRow>
              <FormRow>
                <StyledLabel htmlFor="firstName">{t("resetPassword")}</StyledLabel>
                <FieldWrapper>
                  <Field id="password" name="password">
                    {({ field }) => (
                      <Input
                        id="password"
                        name="password"
                        placeholder={t("resetPassword")}
                        type="password"
                        {...field}
                        value={password.password}
                        onChange={(e) => passwordOnChange(e, setFieldValue)}
                      />
                    )}
                  </Field>
                  {errors.password && touched.password && <StyledErrorMessage name="password" component="div" />}
                </FieldWrapper>
              </FormRow>
              {password.password && (
                <FormRow>
                  <StyledLabel htmlFor="firstName">{t("repeatPassword")}</StyledLabel>
                  <FieldWrapper>
                    <Field id="repeat_password" name="repeat_password">
                      {({ field }) => (
                        <Input
                          id="repeat_password"
                          name="repeat_password"
                          placeholder={t("repeatPassword")}
                          type="password"
                          {...field}
                          value={password.repeatPassword}
                          onChange={(e) => repeatPasswordOnChange(e, setFieldValue)}
                        />
                      )}
                    </Field>
                    {errors.repeat_password && touched.repeat_password && (
                      <StyledErrorMessage name="repeat_password" component="div" />
                    )}
                  </FieldWrapper>
                </FormRow>
              )}
              <FormRow>
                <StyledLabel htmlFor="firstName">{t("profileImage")}</StyledLabel>
                <div>
                  {userDetails.profile_image ? (
                    <Picture src={userDetails.profile_image} />
                  ) : (
                    <div style={{ padding: '10px 0 15px 0' }}>{t("noImage")}</div>
                  )}
                  <StyledUpload {...uploadProps} customRequest={dummyRequest} onRemove={removeFile} fileList={fileList}>
                    <Button disabled={fileList.length} type="default">
                    {t("reUpload")}
                    </Button>
                  </StyledUpload>
                </div>
              </FormRow>
              <FormRow style={{ marginTop: 50 }}>
                <StyledLabel htmlFor="update" />
                <Button type="primary" htmlType="submit" loading={loading}>
                {t("updateProfile")}
                </Button>
              </FormRow>
            </Form>
          )}
        </Formik>
      )}
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  max-width: 500px;
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: black;
  font-weight: 600;
  margin-top: 7px;
`;

export const FieldWrapper = styled.div`
  width: 70%;

  input {
    width: 100% !important;
  }

  .react-tel-input {
    .search {
      display: flex;
      width: 90%;

      input {
        color: black;

        &::placeholder {
          color: #bfbfbf;
        }
      }
    }
    .form-control {
      height: 40px;
      border-radius: 2px !important;
      color: black;

      &::placeholder {
        color: #bfbfbf;
      }
    }
  }
`;

export const FormRow = styled.div`
  position: relative;
  display: flex;
  //align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  label {
    width: 30%;
  }

  @media screen and (max-width: 350px) {
    label {
      width: 100%;
    }
    ${FieldWrapper} {
      width: 100%;
    }
  }
`;

const Picture = styled.img`
  width: 150px;
  margin-bottom: 10px;
`;

const StyledUpload = styled(Upload)`
  display: block;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  //position: absolute;
  color: red;
  //left: 150px;
  //bottom: -18px;
`;

export default ProfileSettings;
