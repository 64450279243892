import React, { memo } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';

const ModalComponent = ({
  open,
  children,
  closeModal,
  showCloseButton = true,
  closeOnDocumentClick = false,
  contentStyle = {},
}) => {
  return (
    <Popup
      modal
      open={open}
      onClose={closeModal}
      closeOnDocumentClick={closeOnDocumentClick}
      contentStyle={contentStyle}
    >
      {(close) => (
        <>
          {showCloseButton && <CloseButton onClick={close}>&times;</CloseButton>}
          {children}
        </>
      )}
    </Popup>
  );
};

const CloseButton = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  box-shadow: 0 2px 7px 1px #8c8c8c;
  cursor: pointer;

  &:hover {
    background: #e7e7e7;
  }
`;

export default memo(ModalComponent);
