import axios from 'axios';
import store from '../store';
import { unauthorizedLogout } from '../Actions/auth.actions';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

if (localStorage.getItem('auth')) {
  const token = JSON.parse(localStorage.getItem('auth')).access_token;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
} else {
  axios.defaults.headers.common.Authorization = 'Bearer ';
  axios.defaults.headers.common.Accept = 'application/json';
}

axios.interceptors.request.use(
  (config) => config,
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response: { status, data } = { status: 0 } } = error;

    if (status === 401 && data?.message === 'Unauthenticated.') {
      store.dispatch(unauthorizedLogout());
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);
