import React, { useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { StyledSelect } from './select';
import { useTranslation } from 'react-i18next';

const NewStyledSelect = ({ isNeeded = false, isRequired = false, onChange }) => {
  const [value, setValue] = useState();
  const { Option } = Select;
  const { t } = useTranslation();

  return (
    <NewSelect
      getPopupContainer={(trigger) => trigger.parentElement}
      placeholder={t("copyToClipboard")}
      bordered={false}
      onChange={(val) => {
        onChange(val, setValue);
        setValue(val);
      }}
      value={value}
    >
      <Option value="copyFullImage">{t("copyEntirePage")}</Option>
      {!isNeeded && <Option value="copyChartElement">{t("copyChartElement")}</Option>}
      {!isRequired && !isNeeded && <Option value="copyChartDesciption">{t("copyChartDescription")}</Option>}
      {!isNeeded && <Option value="copyDemographicInsights">{t("copyDemographicInsights")}</Option>}
    </NewSelect>
  );
};

export default NewStyledSelect;

const NewSelect = styled(StyledSelect)`
  margin-right: 10px;
  .ant-select-selector {
    width: 150px !important;
  }
`;
