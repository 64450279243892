import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { heartBeatEndpoint } from '../Util/liteRoutes';

export default function RouteWrapper({ component: Component, path, isPublic, ...rest }) {
  const LoginInfo = localStorage.getItem('auth');
  const LoggedIn = !!LoginInfo;
  const loggedIn = useSelector((store) => store.auth.loggedIn);
  const company = useSelector((store) => store.company);
  const { pathname } = useLocation();

  if (!LoggedIn && !isPublic) {
    return <Redirect to="/login" />;
  }

  if (!company?.profile?.survey_limit && loggedIn && pathname === '/survey/create') {
    return <Redirect to="/survey/overview" />;
  }

  //TO DO

  // if (
  //   company?.profile?.only_heartbeat === 1 &&
  //   loggedIn &&
  //   !heartBeatEndpoint.includes(pathname) &&
  //   !pathname.includes('/survey/insights') &&
  //   !pathname.includes('/preview')
  // ) {
  //   return <Redirect to="/survey/overview" />;
  // }

  if ((path === '/login' || path === '/forgotten-password' || path === '/verify-email') && loggedIn)
    return <Redirect to="/dashboard" />;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

RouteWrapper.propTypes = {
  isPublic: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = {
  isPublic: false,
};
