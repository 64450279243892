import styled from 'styled-components';

export const BreadCrumbContainer = styled.div`
  position: sticky;
  display: flex;
  justify-content: space-between;
  top: -32px;
  background-color: #f4f7fc;
  padding: 20px 0;
  margin-top: 0px;
  z-index: 101;
  @media screen and (max-width: 715px) {
    //top: 60px;
    left: 25px;
  }
`;
