import React, { useState } from 'react';

import styled from 'styled-components';

import { Button, Input, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { debounce } from 'lodash';

import { ReactComponent as QuestionIcon } from '../../../Assets/Icons/Survey/question.svg';
import { ReactComponent as TimeIcon } from '../../../Assets/Icons/Survey/time.svg';
import { ReactComponent as LeftIcon } from '../../../Assets/Icons/Survey/leftGraph.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const SurveyPreview = ({ questionnaire, isDashboardPreview = false }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = useState(-3);
  const [answers, setAnswers] = useState([]);
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAge, setSelectedAge] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const nextStep = () => {
    setStep((prevState) => prevState + 1);
    setButtonDisabled(true);
  };
  const prevStep = () => setStep((prevState) => prevState - 1);

  const registerAnswer = (id) => {
    const newAnswers = [...answers];
    newAnswers[step - 1] = id;
    setAnswers(newAnswers);

    setTimeout(() => {
      if (questionnaire?.questions?.length !== step) {
        nextStep();
      } else submitQuestionnaireAction(newAnswers);
    }, 300);
  };

  const checkDisable = (e) => {
    setButtonDisabled(!e?.target?.value);
  };

  const debouncedRegisterAnswer = debounce(registerAnswer, 500);

  const registerEthnicity = (val) => {
    setSelectedEthnicity(val);

    setTimeout(nextStep, 300);
  };

  const debouncedRegisterEthnicity = debounce(registerEthnicity, 500);

  const registerGender = (val) => {
    setSelectedGender(val);

    setTimeout(nextStep, 300);
  };

  const registerAge = (val) => {
    setSelectedAge(val);
    if (!questions?.length) {
      let getMessage = () => message.warn({ content:  t("youHaveNotSelectedAnyQuestionsYet"), key: 'preview' });
      return setTimeout(getMessage, 300);
    }
    setTimeout(nextStep, 300);
  };

  const debouncedRegisterGender = debounce(registerGender, 500);

  const submitQuestionnaireAction = (newAnswers) => {
    setIsFinished(true);
  };

  const { name, description, questions, logo_url, ethnicity, gender, age } = questionnaire;

  if (step === -3) {
    return (
      <QuestionnaireWrapper isDashboardPreview={isDashboardPreview}>
        {isDashboardPreview && <Preview>{t("surveyPreview")}</Preview>}
        <StepsWrapper>
          <LeftSide>
            <Icon as={LeftIcon} />
          </LeftSide>
          <Welcome>
            <WelcomeWrapper>
              <div style={{ marginBottom: '20px' }}>
                <img
                  src={logo_url}
                  style={{
                    maxHeight: 100,
                    borderRadius: 50,
                  }}
                  alt="logo"
                />
              </div>
              <h2 style={{ color: '#57BF9A', fontSize: '38px', fontWeight: 'normal' }}>{name}</h2>
              <p>{description}</p>
              <div></div>
              <QuestionnaireInfo>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon as={QuestionIcon} style={{ marginRight: '5px' }} />
                  {questions.length + 3} {t("questions")}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon as={TimeIcon} style={{ marginRight: '5px' }} />{t("2minutes")}
                </div>
              </QuestionnaireInfo>
              <Button type="primary" onClick={nextStep}>
              {t("startSurvey")}
              </Button>
            </WelcomeWrapper>
          </Welcome>
        </StepsWrapper>
      </QuestionnaireWrapper>
    );
  }

  if (isFinished) {
    return (
      <QuestionnaireWrapper isDashboardPreview={isDashboardPreview}>
        {isDashboardPreview && <Preview>{t("surveyPreview")}</Preview>}
        <Welcome>
          <WelcomeWrapper>
            <div style={{ marginBottom: '20px' }}>
              <img
                src={logo_url}
                style={{
                  maxHeight: 100,
                  borderRadius: 50,
                }}
                alt="logo"
              />
            </div>
            <h2 style={{ color: '#57BF9A', fontSize: '38px', fontWeight: 'normal' }}>
            {t("thankYouForYourParticipation")}
            </h2>

            {isDashboardPreview && (
              <span
                onClick={history.goBack}
                style={{
                  color: '#57BF9A',
                  fontSize: '25px',
                  fontWeight: 'normal',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {t("goBack")}
              </span>
            )}
          </WelcomeWrapper>
        </Welcome>
      </QuestionnaireWrapper>
    );
  }

  return (
    <QuestionnaireWrapper isDashboardPreview={isDashboardPreview}>
      {isDashboardPreview && <Preview>{t("surveyPreview")}</Preview>}
      <StepsWrapper>
        <LeftSide>
          <Icon as={LeftIcon} />
        </LeftSide>
        <RightSide>
          <QuestionWrapper>
            {step > -3 && (
              <ArrowLeftOutlined
                style={{
                  color: '#75D9B6',
                  fontSize: '28px',
                  marginRight: 10,
                  cursor: 'pointer',
                  marginBottom: 20,
                  position: 'absolute',
                  top: -50,
                }}
                onClick={prevStep}
              />
            )}

            {step === -2 && (
              <>
                <Question>
                  <QuestionText>{t("whatIsYourGender")} </QuestionText>
                </Question>
                <AnswersWrapper>
                  {gender.map((elem) => (
                    <Answer selected={elem === selectedGender} onClick={() => debouncedRegisterGender(elem)}>
                      {elem}
                    </Answer>
                  ))}
                </AnswersWrapper>
              </>
            )}
            {step === -1 && (
              <>
                <Question>
                  <QuestionText>{t("whatIsYourAge")}</QuestionText>
                </Question>
                <AnswersWrapper>
                  {age.map((elem) => (
                    <Answer selected={elem === selectedAge} onClick={() => registerAge(elem)}>
                      {elem}
                    </Answer>
                  ))}
                </AnswersWrapper>
              </>
            )}
            {step === 0 && (
              <>
                <Question>
                  <QuestionText>{t("whatIsYourEthnicity")}</QuestionText>
                </Question>
                <AnswersWrapper>
                  {ethnicity.map((elem) => (
                    <Answer selected={elem === selectedEthnicity} onClick={() => debouncedRegisterEthnicity(elem)}>
                      {elem}
                    </Answer>
                  ))}
                </AnswersWrapper>
              </>
            )}
            {questions.map(({ question, type, ...elem }, index) => {
              return step === index + 1 ? (
                <>
                  {type === 'regular' ? (
                    <>
                      <Question>
                        {/* <QuestionNumber>{index + 1}</QuestionNumber> */}
                        <QuestionText>{question} </QuestionText>
                      </Question>
                      <AnswersWrapper>
                        <Answer selected={answers[index] === 5} onClick={() => debouncedRegisterAnswer(5)}>
                        {t("great")}
                        </Answer>
                        <Answer selected={answers[index] === 4} onClick={() => debouncedRegisterAnswer(4)}>
                        {t("good")}
                        </Answer>
                        <Answer selected={answers[index] === 3} onClick={() => debouncedRegisterAnswer(3)}>
                        {t("average")}
                        </Answer>
                        <Answer selected={answers[index] === 2} onClick={() => debouncedRegisterAnswer(2)}>
                        {t("bad")}
                        </Answer>
                        <Answer selected={answers[index] === 1} onClick={() => debouncedRegisterAnswer(1)}>
                        {t("veryPoor")}
                        </Answer>
                      </AnswersWrapper>
                    </>
                  ) : (
                    <>
                      <Question>
                        <QuestionText>{question}</QuestionText>
                      </Question>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <TextArea
                          style={{ marginTop: 30, width: '600px' }}
                          placeholder={t("typeYourAnswersHere")}
                          onChange={(e) => checkDisable(e)}
                          size="large"
                          autoSize={{
                            minRows: 2,
                            maxRows: 4,
                          }}
                        />

                        {step === questionnaire?.questions?.length ? (
                          <Button
                            style={{ width: '20%' }}
                            type="primary"
                            disabled={buttonDisabled}
                            onClick={() => submitQuestionnaireAction(answers)}
                          >
                            {t("submit")}
                          </Button>
                        ) : (
                          <Button style={{ width: '20%' }} type="primary" onClick={nextStep} disabled={buttonDisabled}>
                          {t("next")}
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : null;
            })}
            {/* {freetext_questions?.map(({ question, ...elem }, index) => {
              return step === index + questions.length + 1 ? (
                <>
                  <Question>
                    <QuestionText>{question}</QuestionText>
                  </Question>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <TextArea
                      style={{ marginTop: 30, width: '600px' }}
                      placeholder={t("typeYourAnswersHere")}
                      onChange={(e) => checkDisable(e)}
                      size="large"
                      autoSize={{
                        minRows: 2,
                        maxRows: 4
                      }}
                    />

                    {step === questionnaire?.questions?.length + questionnaire?.freetext_questions.length ? (
                      <Button
                        style={{ width: '20%' }}
                        type="primary"
                        disabled={buttonDisabled}
                        onClick={() => submitQuestionnaireAction(answers)}
                      >
                        {t("submit")}
                      </Button>
                    ) : (
                      <Button style={{ width: '20%' }} type="primary" onClick={nextStep} disabled={buttonDisabled}>
                      {t("next")}
                      </Button>
                    )}
                  </div>
                </>
              ) : null;
            })} */}
          </QuestionWrapper>
        </RightSide>
      </StepsWrapper>
      <div style={{ position: 'absolute', right: 25, bottom: 63 }}>
        <img
          src={logo_url}
          style={{
            maxHeight: 65,
            borderRadius: 50,
          }}
          alt="logo"
        />
      </div>
    </QuestionnaireWrapper>
  );
};

const Icon = styled.svg``;

const Preview = styled.span`
  position: absolute;
  right: 30px;
  top: 30px;
  color: rgb(87, 191, 154);
  font-size: 21px;
  font-weight: 500;
`;
const QuestionnaireWrapper = styled.div`
  top: 0;
  left: 0;
  position: ${(props) => (props.isDashboardPreview ? 'fixed' : 'inherit')};
  width: ${(props) => (props.isDashboardPreview ? '100vw' : 'inherit')};
  height: ${(props) => (props.isDashboardPreview ? '100vh' : '100%')};
  background: white;
`;

// position: fixed;
//   width: 100vw;
//   height: 100vh;

const Welcome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WelcomeWrapper = styled.div`
  max-width: 800px;
  text-align: center;
`;

const QuestionnaireInfo = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  div {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const LeftSide = styled.div`
  background: ${({ theme }) => theme.primary};
  width: 30%;
  padding: 30px;

  display: flex;
  align-items: center;
`;

const RightSide = styled.div`
  background: white;
  width: 70%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepsWrapper = styled.div`
  display: flex;
  height: 100%;

  @media screen and (max-width: 880px) {
    ${LeftSide} {
      display: none;
    }
    ${RightSide} {
      width: 100%;
    }
  }
`;

const QuestionWrapper = styled.div`
  position: relative;
  //width: 100%;
`;

const Question = styled.div`
  display: flex;
  align-items: center;
`;

// const QuestionNumber = styled.div`
//   background: ${({ theme }) => theme.primary};
//   border-radius: 50%;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 30px;
//   min-width: 30px;
//   height: 30px;
//   margin-right: 20px;
//   font-weight: bold;
//   font-size: 18px;
// `;

const QuestionText = styled.div`
  color: #28bf8d;
  font-size: 20px;
  max-width: 500px;
  font-weight: 500;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

const AnswersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 45px;
`;

const Answer = styled.div`
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #d9d9d9;
  background: ${({ theme, selected }) => (selected ? theme.primary : 'white')};
  color: ${({ selected }) => (selected ? 'white' : '#575D7A')};
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 10px;

  &:hover {
    background: ${({ theme, selected }) => (selected ? theme.primary : '#d9d9d9')};
  }
`;

export default SurveyPreview;
