import React from 'react';

import styled from 'styled-components';

const HeaderName = ({ children }) => {
  return <HeaderNameStyled>{children}</HeaderNameStyled>;
};

const HeaderNameStyled = styled.h2`
  font-size: 30px;
  color: #575d7a;

  @media screen and (max-width: 385px) {
    font-size: 25px;
  }

  @media screen and (max-width: 345px) {
    font-size: 20px;
  }
`;

export default HeaderName;
