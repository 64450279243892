const colorPalette = [
  '#28BF8D',
  '#26BDF0',
  '#F0D76C',
  '#F6B86E',
  '#BA82BA',
  '#D5D7E3',
  '#EB7D86',
  '#CAF4F4',
  '#fff2cc',
  '#fde0d9',
  '#fde0d9',
  '#caefd1',
  '#c3cde6',
  '#e1c19e',
  '#40372e',
  '#ac4108',
];
export const colorTagPalette = ['#28BF8D', '#BA82BA', '#26BDF0', '#F0D76C', '#EB7D86'];
export default colorPalette;
