import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import { ReactComponent as OpenIcon } from '../Assets/Icons/Sidebar/minimise.svg';
import { ReactComponent as CloseIcon } from '../Assets/Icons/Sidebar/expand.svg';

import Sidebar from './sidebar';

const DashboardNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuStateChange = ({ isOpen }) => setIsOpen(isOpen);

  return (
    <Menu
      isOpen={isOpen}
      onStateChange={handleMenuStateChange}
      customBurgerIcon={<Icon as={CloseIcon} />}
      customCrossIcon={<Icon open as={OpenIcon} />}
      styles={NavStyles}
      pageWrapId={'mainContent'}
      outerContainerId={'siteWrapper'}
      disableAutoFocus
    >
      <Sidebar isMobile />
    </Menu>
  );
};

const Icon = styled.svg`
  width: 20px !important;
  height: 20px !important;
  path {
    fill: #00c8a0;
  }
  ${({ open }) =>
    open &&
    css`
      path {
        fill: white;
      }
    `}
`;

const NavStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '18px',
    height: '20px',
    top: '0',
  },
  bmCrossButton: {
    width: '18px',
    top: '26px',
    right: '16px',
  },

  bmMenuWrap: {
    width: '264px',
    position: 'fixed',
    height: '100%',
    top: 0,
    left: 0,
  },
  bmMenu: {
    background: '#1A5549',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#ffffff',
    padding: '0px',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

const StyledLink = styled(NavLink)`
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  text-decoration: none;
`;
StyledLink.displayName = 'StyledLink';

const StyledNavLink = styled(StyledLink)`
  padding: ${({ theme }) => theme.spacing};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing};

  &.active {
    background-color: #96fbe7;
    color: ${({ theme }) => theme.primary};
  }
`;
StyledNavLink.displayName = 'StyledNavLink';

const StyledToggle = styled.div`
  padding: ${({ theme }) => theme.spacing};
  margin-bottom: ${({ theme }) => theme.spacing};
  border-radius: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryLight};
  font-weight: ${({ theme }) => theme.fontMedium};
  cursor: pointer;
  position: relative;

  &.opened {
    &::after {
      right: 15px;
      transform: rotate(-135deg) translate(45%);
    }
  }

  &.active {
    background-color: #96fbe7;
    color: ${({ theme }) => theme.primary};

    &::after {
      border-color: ${({ theme }) => theme.primary};
    }
  }

  &::after {
    width: 12px;
    height: 12px;
    right: 10px;
    top: 50%;
    display: block;
    content: '';
    position: absolute;
    border-style: solid;
    border-color: ${({ theme }) => theme.primaryLight};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-100%);
  }
`;
StyledToggle.displayName = 'StyledToggle';

const SubNav = styled.ul`
  margin-left: ${({ theme }) => theme.spacing};
  padding-left: ${({ theme }) => theme.spacingSm};
`;
SubNav.displayName = 'SubNav';

const SubNavItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing};
`;
SubNavItem.displayName = 'SubNavItem';

const SubNavLink = styled(StyledLink)`
  &.active {
    color: ${({ theme }) => theme.primaryMedium};
  }
`;
SubNavLink.displayName = 'SubNavLink';

export default DashboardNav;
