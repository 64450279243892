import React from 'react';
import styled from 'styled-components';

import { AuthLayout } from '../../Layout';

import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';

const Login = (props) => {
  const { t } = useTranslation();
  return (
    <AuthLayout heading={t("loginToYourAdminAccount")}>
      <Wrapper>
        <LoginForm userType="company" redirect="/dashboard" {...props} />
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
export default Login;
