import React, { memo } from 'react';

import styled from 'styled-components';

import SmallLineChart from './smallLineChart';

import PercentageStatus from './percentageStatus';
import CustomTooltip from './customTooltip';

const HeartbeatMiniCard = ({
  data = { name: 'Active Employees', data: { amount: 60, comparison: 3, graphData: [2, 4, 6, 3, 6, 2, 3] } },
  width,
  height,
  themeColor,
  headingStyles = {},
}) => {
  return (
    <MiniCardLayout style={{ height, width, ...headingStyles }}>
      <NameWrapper>
        {/* <GraphNameDot /> */}
        <CustomTooltip title={data?.data?.amount}>
          <MiniCardHeadline>{data?.name}</MiniCardHeadline>
        </CustomTooltip>
        {/* <Amount>{data?.data?.amount}</Amount> */}
      </NameWrapper>
      <DataWrapper>
        {/* <Amount>{data?.data?.amount}</Amount> */}
        {data?.data?.comparison !== undefined ? (
          <PercentageStatus percentage={data?.data?.comparison} isIncrease={data?.data?.comparison >= 0} />
        ) : null}
      </DataWrapper>
      <ChartWrapper>
        <SmallLineChart data={data?.data?.graphData} themeColor={themeColor} />
      </ChartWrapper>
    </MiniCardLayout>
  );
};

const Card = styled.div`
  background: ${({ theme }) => theme.white};
  min-width: 260px;

  border-radius: 5px;
  padding: 22px;
  box-shadow: rgba(0, 0, 0, 0.13) 0 1px 4px;

  margin-bottom: 35px;

  @media screen and (max-width: 840px) {
    width: 100% !important;
    margin-bottom: 15px;
  }
`;

const MiniCardLayout = styled(Card)`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MiniCardHeadline = styled.span`
  font-size: 17px;
  font-weight: 600;
`;

const GraphNameDot = styled.div`
  width: 15px;
  height: 15px;
  border: 3px solid ${({ theme }) => theme.greyLight};
  border-radius: 50%;
  margin-right: 10px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #131523;
`;

const DataWrapper = styled.div`
  z-index: 100;
`;

const Amount = styled.div`
  color: ${({ theme }) => theme.greyMedium};
  font-size: 14px;
  font-weight: 600;
  margin-left: 6px;
`;

const ChartWrapper = styled.div`
  position: absolute;
  bottom: 22px;
  right: 22px;
`;

export default memo(HeartbeatMiniCard);
