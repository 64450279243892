import { Button, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Uploader = ({ uploadUsers, uploadButtonRef }) => {
  const { t } = useTranslation();
  return (
    <Upload customRequest={uploadUsers}>
      <Button style={{ display: 'none' }} ref={uploadButtonRef}>
      {t("uploadUsersHidden")}
      </Button>
    </Upload>
  );
};

export default Uploader;
