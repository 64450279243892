import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../Routes/Route';

import OverviewPage from './OverviewPage/overviewPage';
import CreateSurvey from './CreateSurveyPage/createSurveyPage';

export const paths = (t) => ({
  overview: t("overview"),
  create: t('createHeartbeat'),
  edit: t('editHeartbeat'),
});

const Survey = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/survey/overview" component={OverviewPage} />
        <Route exact path="/survey/create">
          <CreateSurvey key="create" />
        </Route>
        <Route exact path="/survey/edit">
          <CreateSurvey key="edit" />
        </Route>
      </Switch>
    </div>
  );
};

export default Survey;
