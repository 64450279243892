import { LOGIN, LOGOUT, MAP_STORAGE_TO_REDUX } from '../Constants/actionTypes';

const initialState = {
  loggedIn: false,
  access_token: '',
  expires_at: '',
  role: '',
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case MAP_STORAGE_TO_REDUX:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
