import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import WordCloudChart from '../../../Components/wordCloudChart';

const CloudList = (props) => {
  const { t } = useTranslation();

  const [toggleButton, setToggleButton] = useState(true);

  const parsedData = (keywordsCurrent) => {
    if (!keywordsCurrent) return [];
    return Object.keys(keywordsCurrent).map((key) => ({
      text: key,
      value: keywordsCurrent[key].count,
    }));
  };

  return (
    <>
      <ContainerWrapper>
        {!toggleButton && Object.keys(props.question.keywords_current).length === 0 && (
          <NewTitle>{t("keywordList")}</NewTitle>
        )}
        {!toggleButton && (
          <Container>
            {!toggleButton && Object.keys(props.question.keywords_current).length !== 0 && (
              <NewTitle>{t("keywordList")}</NewTitle>
            )}
            {Object.keys(props.question.keywords_current).length !== 0 ? (
              <div key={'top-keywords'} header={t("topKeywords")}>
                <WordList>
                  {parsedData(props.question.keywords_current)
                    ?.sort((a, b) => b.value - a.value)
                    ?.map(({ text, value }) => (
                      <KeywordItem key={text} style={{ marginBottom: '3px' }}>
                        <KeywordBulletPoint />

                        <KeywordItem>
                          <KeyWord>{text}</KeyWord>
                          <KeyValue>({value})</KeyValue>
                        </KeywordItem>
                      </KeywordItem>
                    ))}
                </WordList>
              </div>
            ) : (
              <CloudWrapper>{t("noKeywordsToDisplay")}</CloudWrapper>
            )}
          </Container>
        )}

        {toggleButton && <NewTitle>{t("keywordCloud")}</NewTitle>}

        {toggleButton && (
          <CloudContainer>
            {Object.keys(props.question.keywords_current).length !== 0 ? (
              <WordWrapper>
                <WordCloudChart
                  data={parsedData(props.question.keywords_current)}
                  hideMoreInsightsLink={true}
                  fontSizes={[10, 35]}
                />
              </WordWrapper>
            ) : (
              <CloudWrapper>{t("notEnoughKeywordsToCreateWordCloud")}</CloudWrapper>
            )}
          </CloudContainer>
        )}
        <SentimentOuterWrapper style={{ background: '#1a5549' }}>
          <SentimentWrapper
            onClick={() => {
              setToggleButton(!toggleButton);
            }}
          >
            {t("keyword")} {toggleButton ?  t("list") :  t("cloud")}
          </SentimentWrapper>
        </SentimentOuterWrapper>
      </ContainerWrapper>
    </>
  );
};

export default CloudList;

const ContainerWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloudContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WordList = styled.div`
  max-height: 200px;
  overflow: auto;
  width: fit-content;
  padding-right: 7px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: ${({ theme }) => theme.greyLight};
  }

  @media screen and (max-width: 879px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const KeywordItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 300;

  @media screen and (max-width: 879px) {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const KeyWord = styled.div`
  font-size: 15px;
  color: #1a5549;

  @media screen and (max-width: 879px) {
    margin-right: 10px;
  }
`;

const KeyValue = styled.div`
  font-size: 15px;
  color: #1a5549;
`;

const WordWrapper = styled.div`
  width: 75%;

  @media screen and (max-width: 879px) {
    width: 100%;
  }
`;

const NewTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #1a5549;
  margin-bottom: 10px;

  @media screen and (max-width: 879px) {
    text-align: center;
  }
`;

const SentimentWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: white;
  text-transform: capitalize;
`;

const CloudWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #1a5549;
  font-weight: 300;
  justify-content: center;
`;

const KeywordBulletPoint = styled.span`
  margin-right: 6px;
  height: 3px;
  width: 3px;
  background: #1a5549;
  border-radius: 8px;
`;

const SentimentOuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #790707;
  width: 135px;
  cursor: pointer;
  border-radius: 30px;
`;
