import React from 'react';

import CollapseComponent from '../../Components/collapse';
import Button from '../../Components/button';
import HeaderName from '../../Components/headerName';
import { useTranslation } from 'react-i18next';

const HelpSupport = () => {
  const { t } = useTranslation();
  return (
    <div>
      <HeaderName>{t("helpSupport")}</HeaderName>
      <CollapseComponent
        data = {[
          {
            header:  t("helpQ1"),
            text:  t("helpA1"),
          },
          {
            header:  t("helpQ2"),
            text:  t("helpA2"),
          },{
            header:  t("helpQ3"),
            text:  t("helpA3"),
          },{
            header:  t("helpQ4"),
            text:  t("helpA4"),
          },{
            header:  t("helpQ5"),
            text:  t("helpA5"),
          },{
            header:  t("helpQ6"),
            text:  t("helpA6"),
          },{
            header:  t("helpQ7"),
            text:  t("helpA7"),
          },{
            header:  t("helpQ8"),
            text:  t("helpA8"),
          },{
            header:  t("helpQ9"),
            text:  t("helpA9"),
          },{
            header:  t("helpQ10"),
            text:  t("helpA10"),
          }
        ]}
      />
      <div style={{ marginTop: 60 }}>
        <p>{t("enquiry")}</p>
        <Button type="primary">{t("800SUPPORT")}</Button>
      </div>
    </div>
  );
};

export default HelpSupport;
