import React, { memo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Drawer, Skeleton, Empty } from 'antd';

import { filterEmployeeCount } from '../../Actions/company.actions';

import EmployeeCountChart from './Charts/employeeCountChart';
import moment from 'moment';
import { ReactComponent as FilterIcon } from '../../Assets/Icons/filter.svg';
import CustomTooltip from '../../Components/customTooltip';
import { ReactComponent as InfoIcon } from '../../Assets/Icons/info.svg';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

const EmployeeCountComponent = ({ singleView }) => {
  const [filter, setFilter] = useState('day');
  const [filters, setFilters] = useState({
    rangeFilterValue: null,
  });
  const [drawerState, setDrawerState] = useState(false);
  const { t } = useTranslation();
  const dateFormat = 'YYYY-MM-DD';

  const data = useSelector((store) => store.company?.dashboard?.dashboard_stats?.employees_by_department);
  const isMobileView = useSelector((store) => store.app.isMobileView);
  const isTabletView = useSelector((store) => store.app.isTabletView);
  const dispatch = useDispatch();

  const onFilterChange = (e) => {
    const id = e.target?.id;

    const ranges = [moment().subtract(1, id === 'day' ? 'weeks' : `${id}s`), moment()];

    if (id) {
      dispatch(filterEmployeeCount(ranges[0].format(dateFormat), ranges[1].format(dateFormat)));
      setFilter(id);
      setFilters(null);
    }
  };

  const rangeFilterOnChange = useCallback(
    async (ranges) => {
      setFilters((state) => ({ ...state, rangeFilterValue: ranges }));

      if (ranges?.length === 2) {
        let start = ranges[0].format(dateFormat);
        let end = ranges[1].format(dateFormat);

        await dispatch(filterEmployeeCount(start, end));
        setFilters((state) => ({ ...state, start, end }));
        setFilter(null);
      }
    },
    [dispatch],
  );

  const toggleDrawerState = useCallback(() => setDrawerState((state) => !state), []);

  return (
    <ActivityLevelWrapper>
      <HeaderWrapper>
        <HeaderName>
        {t("employeeCountByDepartment")}
          <CustomTooltip
            placement="right"
            title={<span>{t("employeeCountTooltip")}</span>}
          >
            <Icon
              as={InfoIcon}
              style={
                isMobileView || isTabletView
                  ? { position: 'relative', top: '-2px', right: '-5px' }
                  : { position: 'absolute', top: '-8px', right: '-15px' }
              }
            />
          </CustomTooltip>
        </HeaderName>
        <FiltersWrapper>
          {(isMobileView || isTabletView) && singleView ? (
            <>
              <Filter onClick={toggleDrawerState}>
                <Icon as={FilterIcon} />
              </Filter>
              <Drawer
                width={320}
                title={t("filterData")}
                placement="right"
                closable={true}
                onClose={toggleDrawerState}
                visible={drawerState}
              >
                <RangePicker
                  value={filters?.rangeFilterValue}
                  onChange={rangeFilterOnChange}
                  style={{ width: 220, marginRight: 24 }}
                  loading={true}
                />
              </Drawer>
            </>
          ) : !isTabletView && !isMobileView && singleView ? (
            <RangePicker
              value={filters?.rangeFilterValue}
              onChange={rangeFilterOnChange}
              style={{ width: 220, marginRight: 24 }}
              loading={true}
            />
          ) : null}
          <FilterButton id="day" selected={filter === 'day'} onClick={onFilterChange}>
          {t("week")}
          </FilterButton>
          <FilterButton id="month" selected={filter === 'month'} onClick={onFilterChange}>
          {t("month")}
          </FilterButton>
          <FilterButton id="year" selected={filter === 'year'} onClick={onFilterChange}>
          {t("year")}
          </FilterButton>
        </FiltersWrapper>
      </HeaderWrapper>
      {data !== undefined && data !== null ? (
        Object.values(data).length ? (
          <div style={{ width: '100%', height: '100%' }}>
            <EmployeeCountChart data={data} />
          </div>
        ) : (
          <Empty
            style={{ margin: '90px auto 0 auto' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("noDataAvailable")}
          />
        )
      ) : (
        <Skeleton active size="small" paragraph={{ rows: 5 }} />
      )}
    </ActivityLevelWrapper>
  );
};

const Filter = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;

  background: #00c8a0;
  border-radius: 10px 0 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  top: -22px;
  right: -25px;

  &:hover {
    background: #63cac3;
  }
`;

const Icon = styled.svg``;

const ActivityLevelWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

const HeaderName = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterButton = styled.div`
  background: #ebebeb;
  border-radius: 5px;
  height: 32px;
  width: 63px;

  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      background: #68d4cd;

      &:hover {
        background: #5dbeb8 !important;
      }
    `}
  &:hover {
    background: #dddddd;
  }
`;

export default memo(EmployeeCountComponent);
