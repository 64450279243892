import React from 'react';
import styled from 'styled-components';

const FullCard = ({ children }) => {
  return <Card>{children}</Card>;
};

const Card = styled.div`
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 1px 4px #15223214;
  padding: 35px;
  border-radius: 5px;
  margin-bottom: 40px;

  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const LeftWrapper = styled.div`
  //padding-right: 18px;
  width: 400px;
  height: 400px;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (max-width: 1399px) {
    justify-content: center;
    //margin-top: 80px;
  }

  @media screen and (max-width: 820px) {
    justify-content: center;
  }
`;

export const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: calc(100% - 400px);
  min-width: 300px;
  //justify-items: center;

  @media screen and (max-width: 1399px) {
    grid-template-columns: 1fr;
   // justify-content: center;
    //padding-left: 50px;
    //margin-left: 20px;
  }
  @media screen and (max-width: 820px) {
    //width: 100%;
   //justify-content: center;

  }
`;

export const FullWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h3`
  color: #575d7a;
  font-weight: 300;
  font-size: 24px;
  margin: 0 0 0 13px;
`;

export const InfoHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  ${InfoHeader} {
    margin-right: 60px;
  }
`;

export default FullCard;