import image1 from '../../Assets/Analysis/image1.png';
import image2 from '../../Assets/Analysis/image2.png';
import image3 from '../../Assets/Analysis/image3.png';
import image4 from '../../Assets/Analysis/image4.png';
import image5 from '../../Assets/Analysis/image5.png';
import image6 from '../../Assets/Analysis/image6.png';

export const LeaderData = [
  {
    name: 'Leader Analysis',
    text:
      'Wellness support that truly understands women. NIXY improves the health & wellness of women in the workplace through tailored and professional online support.',
    status: 'Active',
    list: [
      'Let Women in your team flourish in their roles.',
      'Enable them to not just ask for the help, but get it.',
      'Join the leaders empowering Women today.',
    ],
    image: image1,
  },
  {
    key: 'nixy',
    name: 'NIXY',
    text:
      'Wellness support that truly understands women. NIXY improves the health & wellness of women in the workplace through tailored and professional online support.',
    status: 'Upgrade now',
    list: [
      'Let Women in your team flourish in their roles.',
      'Enable them to not just ask for the help, but get it.',
      'Join the leaders empowering Women today.',
    ],
    image: image2,
  },
  {
    key: 'only_heartbeat',
    name: 'Heartbeat',
    text:
      'Clear results come from asking the right questions to the right people. With Heartbeat, create personalised surveys to improve employee engagement and performance within your organization.',
    status: 'Upgrade now',
    list: ['Set up & Create', 'Personalization & Various Pillars', 'Immediate insights'],
    image: image3,
  },
  {
    key: 'supportfit',
    name: 'SupportFit',
    text: 'Workplace physical wellness, that improves sleep, nutrition & movement.',
    status: 'Upgrade now',
    list: [
      'Connect wearable and mobile devices',
      'Monitors nutrition, movement and sleep',
      'Data driven insights',
      'Monitors improvements in physical wellness',
      'Weekly physical health targets',
    ],
    image: image4,
  },
  {
    key: 'community',
    name: 'Community',
    text:
      'Wellness support that truly understands women. NIXY improves the health & wellness of women in the workplace through tailored and professional online support.',
    status: 'Upgrade now',
    list: [
      'Let Women in your team flourish in their roles.',
      'Enable them to not just ask for the help, but get it.',
      'Join the leaders empowering Women today.',
    ],
    image: image5,
  },
  {
    key:"get_a_quate",
    name: 'Speak to your AM',
    text: `Have any questions or need extra support? \n\n Contact your dedicated account manager now who will assist you every step of the way!`,
    status: 'Get a Quote',
    image: image6,
  },
];


export const updateStatus = (profileData) => {
  return LeaderData.map((item) => {
    if (item?.key && profileData.hasOwnProperty(item?.key) && profileData[item?.key]) {
      return { ...item, status: 'Active' };
    }
    return item;
  });
};
