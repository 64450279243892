import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import api from '../../Util/api';

import Input from '../../Components/input';
import Button from '../../Components/button';
import FormGroup from '../../Components/formGroup';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../../Layout';

import email from '../../Assets/Icons/Login/email.svg';

const ForgottenPassword = () => {
  const [showResponse, setShowResponse] = useState(false);
  const { t } = useTranslation();
  const handleOnSubmit = async (values, { resetForm, setSubmitting, setFieldError }) => {
    try {
      const payload = { ...values, role: 'enterprise' };

      await api.auth.forgotPassword(payload);
      resetForm();
      setShowResponse(true);
    } catch (err) {
      setFieldError('email', err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email( t("emailIsInvalid")).required( t("emailIsRequired")),
  });

  return (
    <AuthLayout heading={t("forgotPassword")}>
      <Wrapper>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormGroup>
                <Field
                  id="email"
                  name="email"
                  render={({ field }) => (
                    <Input
                      id="email"
                      name="email"
                      placeholder={t("enterEmailAddress")}
                      type="email"
                      className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                      prefix={<img src={email} alt="email" />}
                      {...field}
                    />
                  )}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </FormGroup>
              {showResponse && (
                <div style={{ marginBottom: 20 }}>{t("sentEmail")}</div>
              )}
              <Button loading={false} type="primary" htmlType="submit">
              {t("resetPassword")}
              </Button>
            </Form>
          )}
        </Formik>
        <div style={{ marginTop: 40 }}>
          <div>{t("knowYourPassword")}</div>
          <Link to="/">
            <b style={{ color: '#00c8a0' }}>{t("loginHere")}</b>
          </Link>
        </div>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledForm = styled(Form)`
  display: grid;
  column-gap: 4px;
  row-gap: ${({ theme }) => theme.spacing};

  > * {
    margin: 0;
  }

  ${({ theme }) => theme.md`
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    
    button {
      height: 44px;
    }
  `}
`;
StyledForm.displayName = 'StyledForm';

export default ForgottenPassword;
