import React, { memo, useEffect, useState } from 'react';

import FullCard from '../../../Components/fullCard';
import CardHeader from '../../../Components/cardHeader';
import SingleViewPieWithDescription from './singleViewPieWithDescription';
import { useParams } from 'react-router-dom';
import api from '../../../Util/api';
import { useTranslation } from 'react-i18next';

const noDataMapping = (t) => ({
  true: t("noPositiveResponses"),
  false : t("noNegativeResponses"),
  all: t("noData")
})

const KeyStatisticsCard = ({ data, surveyName, isCustomQuestions, location, setLoading }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [response, setResponse] = useState([]);
  const [responseType, setResponseType] = useState('false');
  const [loader, setLoader] = useState(0);

  const onSelectResponseChange = (id) => {
    setResponseType(id);
  };

  useEffect(() => {
    const getSurveyResponseData = async () => {
      try {
        setLoading((prevState) => prevState + 1);
        setLoader((prevState) => prevState + 1);
        const response = await api.survey.getSurvey(id, 'gender', responseType, location);
        const data = response?.data?.data?.categories;
        const formattedData = Object.keys(data || {})?.map((key) => ({ ...data[key], title: key }));
        setResponse(formattedData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading((prevState) => prevState - 1);
        setLoader((prevState) => prevState - 1);
      }
    };
    getSurveyResponseData();
  }, [responseType, location]);

  return (
    <FullCard>
      <div>
        <CardHeader
          name={t("keyStatistics")}
          toolTipText={t("keyStatistics")}
          customWidth="auto"
          showResponseType
          onSelectResponseChange={onSelectResponseChange}
          location={location}
          isCustomQuestions={isCustomQuestions}
          surveyName={surveyName}
          surveyId={id}
        />
      </div>
      <SingleViewPieWithDescription data={response} loader = {loader} noDataMessage={noDataMapping(t)[responseType]} />
    </FullCard>
  );
};

export default memo(KeyStatisticsCard);
