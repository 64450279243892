import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '../../Routes/Route';

// import UserSettings from './userSettings';
import ProfileSettings from './profileSettings';

import LocationPath from '../../Components/locationPath';
import ManagerUpload from './managerUpload';
import EmployeeUpload from './employeeUpload';

const paths = (t) =>( {
  profile: t('profileSettings'),
  // user: 'User Settings',
  employee: t('employeeUpload'),
  manager: t('ManagerUpload'),
});

const Settings = () => {
  return (
    <div style={{ maxWidth: '1100px' }}>
      <LocationPath paths={paths} multi pathName="Settings" />
      <Switch>
        <Route exact path="/settings/profile" component={ProfileSettings} />
        {/* <Route exact path="/settings/user" component={UserSettings} /> */}
        <Route exact path="/settings/manager" component={ManagerUpload} />

        <Route exact path="/settings/employee" component={EmployeeUpload} />
      </Switch>
    </div>
  );
};

export default Settings;
