import api from '../Util/api';
import { GET_DASHBOARD } from '../Constants/actionTypes';

export const getDashboard = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.dashboard
      .getDashboard(data)
      .then((res) => {
        dispatch({ type: GET_DASHBOARD, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );
