import React, { memo } from 'react';

import CardHeader from '../../../Components/cardHeader';
import FullCard from '../../../Components/fullCard';
import PercentageBarWithDescription from './percentageBarWithDescription';
import { useTranslation } from 'react-i18next';
const WorkLoadTagCard = ({ name, data }) => {
  const { t } = useTranslation();
  return (
    <FullCard>
      <CardHeader
        name={`${name}`}
        customWidth="300px"
        toolTipText={t("workLoadTagCardTooltip")}
      />
      <PercentageBarWithDescription data={data} />
    </FullCard>
  );
};

export default memo(WorkLoadTagCard);
