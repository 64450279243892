import React, { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import api from '../../Util/api';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomTooltip from '../../Components/customTooltip';

const BenefitsPreview = ({ list, ...props }) => {
  const deleteCard = async (id) => {
    const delete_response = await api.survey.deleteBenefits({ benefit_id: id });
    console.log('delete_response: ', delete_response);
    props.getData();
    message.success(t('benefitDeletedSuccessfully'));
  };
  const { t } = useTranslation();

  return (
    <PreviewWrapper>
      <Title>{t('previewBenefits')}</Title>
      <StyledContainer>
        {list?.length
          ? list.map((item) => (
              <StyledCard>
                <CustomTooltip title={item.benefit_title}>
                  <Title style={{ position: 'absolute', top: '10px' }}> {item.benefit_title}</Title>
                </CustomTooltip>
                <CardContent>
                  <CustomTooltip title={item.company_name}>
                    {item.company_name === '' ? null : (
                      <Title style={{ fontSize: '15px', marginTop: '10px' }}> {item.company_name}</Title>
                    )}
                  </CustomTooltip>

                  {item.benefit_logo === '' ? null : (
                    <div style={{ height: '30%' }}>
                      <img
                        src={item.benefit_logo}
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain',
                          borderRadius: '50',
                        }}
                        alt=""
                      />
                    </div>
                  )}
                  <CustomTooltip title={item.benefit_description}>
                    {item.benefit_description === '' ? null : <Description> {item.benefit_description}</Description>}
                  </CustomTooltip>
                </CardContent>
                <ButtonWrapper>
                  <a href={item.benefit_link} target="_blank" rel="noreferrer">
                    <CustomButton type="primary">{t('redeemReward')}</CustomButton>
                  </a>
                  <IconWrapper>
                    <EditOutlined
                      style={{ fontSize: '25px', color: 'grey', cursor: 'pointer' }}
                      onClick={() => props.updateCard(item)}
                    />
                    <DeleteOutlined
                      style={{ fontSize: '25px', color: 'grey', cursor: 'pointer' }}
                      onClick={() => deleteCard(item.benefit_id)}
                    />
                  </IconWrapper>
                </ButtonWrapper>
              </StyledCard>
            ))
          : null}
      </StyledContainer>
    </PreviewWrapper>
  );
};

export default BenefitsPreview;

const Title = styled.div`
  font-size: 17px;
  color: #575d7a;
  font-weight: 500;
  padding: 5px 0 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 212px;
  overflow: hidden;
  height: 50px;
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 212px;
  overflow: hidden;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex: 0.9;
  flex-direction: column;
  ${'' /* width: 60%; */}
  ${'' /* height: 100vh; */}
  ${'' /* overflow-y: scroll; */}
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

const StyledCard = styled.div`
  height: 300px;
  width: 245px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.primaryLight};
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-self: center;
  justify-content: center;
  text-align: left;
  position: relative;
`;

const CardContent = styled.div`
  height: 185px;
  width: 215px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-evenly;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: absolute;
  bottom: 15px;
`;

const CustomButton = styled.button`
  display: inline;
  background-color: #00c8a0;
  color: white;
  text-decoration: none;
  border: 1px solid #00c8a0;
  padding: 4px 15px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: #00b48f;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
