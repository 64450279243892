import React, { memo, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';

import colorPalette from '../Util/colorPalette';

const options = {
  responsive: false,
  cutoutPercentage: 85,
  aspectRatio: 1,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  legend: {
    display: false,
  },
};

const DoughnutChart = ({ data, width, height }) => {
  const isDataZero = (data) => {
    return !data.datasets[0].data.some((value) => value > 0);
  };

  const parsedData = useMemo(() => {
    const newData = { labels: [], datasets: [{ label: 'Dataset', data: [], backgroundColor: [] }] };

    if (!data) return newData;
    data.forEach((item, index) => {
      newData.labels.push(item.title);
      newData.datasets[0].data.push(item.percentage);
      newData.datasets[0].backgroundColor.push(colorPalette[index]);
    });

    if (isDataZero(newData))
      return { labels: ['Empty'], datasets: [{ label: 'Empty', data: [100], backgroundColor: ['#dce0e6'] }] };

    return newData;
  }, [data]);

  return <Doughnut data={parsedData} options={options} width={width} height={height} />;
};

export default memo(DoughnutChart);
